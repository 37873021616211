import { Button, H3, typography, size, colors } from '@everlywell/leaves';
import styled from 'styled-components';

import { BaseInput } from '../styles';

const TitleWrapper = styled.div`
  padding-bottom: ${size.lg}px;
`;
const SubtitleWrapper = styled.div`
  padding-bottom: ${size.xl2}px;
`;
const Title = styled(H3)`
  font-weight: ${typography.weight.book};
  margin: 0;
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall};
  line-height: 1.75;
  font-weight: ${typography.weight.book};
  display: inline;
`;
const ModalButton = styled.a`
  font-size: ${size.md}px;
  font-weight: ${typography.weight.bold};
  line-height: 1.5;
  color: ${colors.green5};
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

const KitIDInput = styled(BaseInput)`
  height: ${size.xl6}px;
  margin-bottom: ${size.xl2}px;
  label {
    padding-bottom: ${size.xs3}px;
  }
  svg {
    fill: none;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.green5};
`;

const Disclaimer = styled.p`
  padding: ${size.sm}px 0 ${size.xl2}px;
  ${typography.captionText};
`;

const Submit = styled(Button)`
  width: 100%;
`;

export {
  TitleWrapper,
  Title,
  SubtitleWrapper,
  Subtitle,
  ModalButton,
  KitIDInput,
  CheckboxContainer,
  Disclaimer,
  Submit,
};
