/* istanbul ignore file */
/* eslint-disable react/no-this-in-sfc */
import { colors, typography, breakpoints } from '@everlywell/leaves';
import Chart from 'chart.js';
import useMediaQuery from 'common/hooks/useMediaQuery';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { hexToRGB } from 'common/utils/helpers';
import React, { RefObject, useEffect, useState, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import 'chartjs-plugin-style';
import * as S from './styles';

type Props = {
  graphId: string;
  datasets: Chart.ChartDataSets[];
  labels: (string | null | string[])[];
  yAxes: Chart.ChartYAxe[];
  xAxes: Chart.ChartXAxe[];
  arrayOfTooltipValues: string[];
  arrayOfTooltipTitles: string[];
  arrayOfSeverityColors: string[];
  activeDataPointIndex: number;
  indexOfLineDataset: number;
  indexOfBarDataset: number;
  yAxisLabels: string[];
  maxBoundaryLength: number;
};

const activateDataPoint = (
  oChart: Chart,
  datasetIndex: number,
  pointIndex: number,
) => {
  if (!oChart || !oChart.canvas) {
    return;
  }
  const meta = oChart.getDatasetMeta(datasetIndex);
  const rect = oChart.canvas.getBoundingClientRect();
  // @ts-ignore
  const point = meta.data[pointIndex].getCenterPoint();
  const evt = new MouseEvent('click', {
    clientX: rect.left + point.x,
    clientY: rect.top + point.y,
  });
  const node = oChart.canvas;

  node.dispatchEvent(evt);
};

const LineGraph = (props: Props) => {
  const {
    graphId,
    datasets,
    labels,
    yAxes,
    xAxes,
    arrayOfTooltipValues,
    arrayOfTooltipTitles,
    arrayOfSeverityColors,
    activeDataPointIndex,
    indexOfLineDataset,
    indexOfBarDataset,
    yAxisLabels,
    maxBoundaryLength,
  } = props;

  let labelShift = '-20px';

  if (maxBoundaryLength === 2) {
    labelShift = '-34px';
  } else if (maxBoundaryLength === 3) {
    labelShift = '-40px';
  }

  const graphRef: RefObject<HTMLCanvasElement> = React.createRef();
  const myLineGraph = useRef<Chart>();
  const [hasLoaded, setHasLoaded] = useState(false);

  const isMobile = useMediaQuery(
    `(max-width: ${breakpoints.forTabletVerticalUp}px)`,
  );

  // Send mixpanel event if chart was clicked
  const handleMixpanel = (event: any) => {
    if (hasLoaded) {
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      const element = this.getElementAtEvent(event);
      // eslint-disable-next-line no-underscore-dangle
      const selectedElementIndex = element[0]._index;
      // eslint-disable-next-line no-underscore-dangle
      const selectedDatasetIndex = element[0]._datasetIndex;
      if (selectedDatasetIndex === indexOfBarDataset) {
        setTimeout(() => {
          // @ts-ignore
          activateDataPoint(this, indexOfLineDataset, selectedElementIndex);
        }, 0);
        return;
      }

      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            selectedElementIndex === activeDataPointIndex
              ? ANALYTICS.LABELS.CURRENT_MARKER
              : ANALYTICS.LABELS.HISTORICAL_MARKER,
        },
      });
    }
  };

  useEffectOnce(() => {
    if (datasets) {
      const buildGraph = () => {
        if (graphRef.current) {
          const myGraphRef = graphRef.current.getContext('2d');

          if (myGraphRef) {
            // GRADIENT FOR FILL
            const gradient = myGraphRef.createLinearGradient(0, 0, 0, 329);
            gradient.addColorStop(0, 'rgba(238, 238, 238, 0.5)');
            gradient.addColorStop(1, 'rgba(216,216,216,0)');

            const datasetsWithFill = datasets.map(
              (dataset: Chart.ChartDataSets, i: number) => {
                if (i === indexOfLineDataset) {
                  const datasetWithGradient = {
                    ...dataset,
                    backgroundColor: gradient,
                  };
                  return datasetWithGradient;
                }
                return dataset;
              },
            );

            myLineGraph.current = new Chart(myGraphRef, {
              plugins: [],
              type: 'bar', // required to be a bar chart to allow the y-axis severity color scale
              // @ts-ignore
              data: {
                // @ts-ignore
                labels,
                datasets: datasetsWithFill,
              },
              options: {
                events: ['click'],
                onClick: handleMixpanel,
                animation: {
                  duration: 0,
                },
                hover: {},
                legend: {
                  display: false,
                },
                layout: {
                  padding: {
                    top: 16,
                    right: 16,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes,
                  xAxes,
                },
                tooltips: {
                  // @ts-ignore
                  shadowOffsetX: 3,
                  shadowOffsetY: 3,
                  shadowBlur: 10,
                  shadowColor: `rgba(${hexToRGB(colors.black)}, .1)`,
                  enabled: true,
                  filter(tooltipItem) {
                    if (tooltipItem && tooltipItem.datasetIndex) {
                      // this is to disable the tooltip on the severity color bars
                      // we only want tooltips to work for data points, so we filter out the
                      // "data" that is the bar charts.  hardcoded as 2 for now, but once we are mostly dev done with
                      // this feature, we should create a ResultsOverTimeGraph component, that creates this whole
                      // options configuration and pass that into a general Graph component
                      return tooltipItem.datasetIndex > yAxisLabels.length;
                    }
                    return false;
                  },
                  // @ts-ignore
                  backgroundColor: colors.white,
                  titleFontFamily: typography.type.dmSans,
                  titleFontWeight: typography.weight.regular,
                  titleFontColor: colors.gray4,
                  titleFontSize: 14,
                  titleSpacing: 8,
                  bodyFontFamily: typography.type.dmSans,
                  bodyFontWeight: typography.weight.light,
                  bodyFontColor: colors.gray4,
                  bodyFontSize: 16,
                  bodySpacing: 8,
                  displayColors: true,
                  caretPadding: 10,
                  xPadding: 10,
                  yPadding: 10,
                  corderRadius: 0,
                  borderColor: `#ddd`,
                  callbacks: {
                    title: (tooltipItems) => {
                      if (
                        tooltipItems &&
                        tooltipItems[0] &&
                        tooltipItems[0].index
                      ) {
                        return arrayOfTooltipTitles[tooltipItems[0].index];
                      }
                      return '';
                    },
                    label: (tooltipItem) => {
                      if (tooltipItem && tooltipItem.index) {
                        return arrayOfTooltipValues[tooltipItem.index];
                      }
                      return '';
                    },
                    // @ts-ignore
                    labelColor: (tooltipItem) => {
                      if (tooltipItem && tooltipItem.index) {
                        return {
                          backgroundColor:
                            arrayOfSeverityColors[tooltipItem.index],
                          borderColor: 'transparent',
                        };
                      }

                      return {
                        backgroundColor: colors.white,
                      };
                    },
                  },
                },
              },
            });
          }
        }
      };

      buildGraph();
      if (myLineGraph.current) {
        setTimeout(() => {
          // needs to happen after buildGraph
          activateDataPoint(
            myLineGraph.current as Chart,
            indexOfLineDataset,
            activeDataPointIndex,
          );
          setHasLoaded(true);
        }, 0);
      }
    }
    return () => {
      myLineGraph.current?.destroy();
    };
  });

  useEffect(() => {
    if (labels && myLineGraph.current) {
      myLineGraph.current?.update();
    }
  }, [labels]);

  return (
    <S.Wrapper>
      <S.YLabelWrapper labelShift={labelShift}>
        {yAxisLabels &&
          yAxisLabels
            .map((label: string) => (
              <S.YSeverityLabel>{label}</S.YSeverityLabel>
            ))
            .reverse()}
      </S.YLabelWrapper>
      <S.GraphWrapper>
        <S.Canvas
          id={graphId}
          ref={graphRef}
          height={isMobile ? '251px' : '329px'}
        />
      </S.GraphWrapper>
    </S.Wrapper>
  );
};

export default LineGraph;
