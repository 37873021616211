import { colors, H1, mediaQueries, Row, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Subtitle = styled.p`
  ${typography.bodyTextSmall};
  line-height: 1.75;
  font-weight: ${typography.weight.book};
  padding: 0 5%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  text-align: center;
  justify-content: center;
`;

const Title = styled(H1)`
  font-weight: ${typography.weight.book};
  margin: 0;
  margin-bottom: 10px;
`;

const Wrapper = styled(Row)`
  min-height: 620px;
  background: ${colors.green1};

  ${mediaQueries.forPhoneOnly} {
    min-height: 79vh;
  }
`;

export { Subtitle, Title, Wrapper };
