import {
  Button,
  H4,
  colors,
  typography,
  size,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  ${mediaQueries.forDesktopUp} {
    max-width: calc(100% - 24px);
    width: 700px;
  }
`;

const Title = styled(H4)`
  font-weight: ${typography.weight.regular};
  margin-bottom: ${size.md}px;
  width: 100%;
  word-wrap: break-word;
`;

const Description = styled.p`
  ${typography.bodyText};
  margin-bottom: ${size.lg}px;
  width: 100%;
  word-wrap: break-word;
`;

const Body = styled.div`
  ${typography.captionText};
  margin-bottom: 1.5rem;
  width: 100%;

  a {
    color: ${colors.green5};
    font-weight: ${typography.weight.regular};
    text-decoration: none;
  }
`;

const Footer = styled.div`
  width: 100%;
`;

const CTA = styled(Button)`
  width: 100%;
`;

const OptionTitle = styled.div`
  ${typography.buttonText};
  color: ${colors.green5};
`;

const UL = styled.ul`
  list-style: none;
  margin-bottom: ${size.lg}px;
`;

const LI = styled.li`
  ${typography.bodyTextXSmall};
  padding-left: ${size.md}px;
  position: relative;

  &:before {
    background-color: ${colors.teal4};
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 4px;
    left: 0;
    margin-right: ${size.xs1}px;
    position: absolute;
    top: ${size.xs1}px;
    width: 4px;
  }
`;

const RadioOptionHeading = styled.label`
  align-items: center;
  background-color: ${colors.green1};
  border-radius: 2px 2px 0 0;
  border: 1px solid ${colors.green2};
  color: ${colors.green3};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: ${size.sm}px ${size.sm}px ${size.sm}px ${size.lg}px;
`;

const RadioOption = styled.div`
  border-radius: 2px;
  border: 1px solid ${colors.green2};
  box-shadow: 0 7px 30px -10px #dce4e5;
  cursor: pointer;
  margin-bottom: ${size.lg}px;
  transition: all 0.15s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid ${colors.green3};

    ${RadioOptionHeading} {
      background-color: ${colors.green2};
      color: ${colors.green5};
    }
  }
`;

const RadioOptionContent = styled.div`
  cursor: pointer;
  padding: ${size.md}px ${size.lg}px;

  ul {
    margin-bottom: 0;

    /* stylelint-disable no-descending-specificity */
    ${mediaQueries.forPhoneOnly} {
      padding-left: ${size.sm}px;
    }
  }

  li {
    line-height: 22px;
    margin-bottom: ${size.sm}px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export {
  Body,
  Container,
  CTA,
  Description,
  Footer,
  LI,
  OptionTitle,
  RadioOption,
  RadioOptionContent,
  RadioOptionHeading,
  Title,
  UL,
};
