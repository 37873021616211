import {
  theme,
  Box,
  Heading,
  Grid,
  GridItem,
  Container,
  Icon,
  Text,
  Flex,
  Button,
} from '@everlywell/ui-kit';
import { ArrowLeft } from '@phosphor-icons/react';
import { Spinner } from '@phosphor-icons/react';
import useKitResultCarePlan from 'common/hooks/useKitResultCarePlan';
import Layout from 'components/Layout';
import CarePlanAccordion from 'components/telehealth/CarePlan/components/CarePlanAccordion/CarePlanAccordion';
import { CarePlanInfoCardProps } from 'components/telehealth/CarePlan/components/CarePlanInfoCard';
import ClinicianSummary from 'pages/KitResultCarePlanPage/ClinicianSummary';
import Recommendations from 'pages/KitResultCarePlanPage/Recommendations';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  formatKitResultSectionsAccordionItems,
  formatKitResultRecommendationsItems,
} from './helpers';
import KitResultCarePlanSkeleton from './KitResultCarePlan.skeleton';

export interface CarePlanProps {}

const KitResultCarePlan = (props: CarePlanProps) => {
  const { kitResultIdOrPublishHash } = useParams();
  const { space } = theme;

  const {
    summary,
    date,
    sections,
    recommendations,
    isLoading: isLoadingCarePlan,
    isError,
    isReady: isCarePlanReady,
  } = useKitResultCarePlan();

  const formattedOrdersAccordionItems = formatKitResultSectionsAccordionItems(
    sections,
    { isLoading: isLoadingCarePlan },
  );

  const formattedRecommendationsItems = formatKitResultRecommendationsItems(
    recommendations,
    { isLoading: isLoadingCarePlan },
  );

  const formattedAccordionItems: CarePlanInfoCardProps[] = [
    ...formattedOrdersAccordionItems,
  ];

  // TODO: Add loading state
  // This is a temporary place holder for loading state, design still in progress
  if (isLoadingCarePlan) {
    return <KitResultCarePlanSkeleton />;
  }

  // TODO: Refactor this once the BE is ready to handle errors
  if (!isCarePlanReady || isError) {
    return (
      <Layout>
        <Flex
          direction="column"
          align="center"
          justify="center"
          padding="4"
          gap="4"
          minHeight={'calc(100vh - 58px)'}
          backgroundColor="tints.cream"
        >
          <Box
            borderRadius="8"
            backgroundColor="viridian.wash"
            padding="2"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              as={Spinner}
              color="viridian.base"
              height="32px"
              width="32px"
              role="img"
              aria-label="Loading icon"
            />
          </Box>
          <Heading textAlign="center">
            Your care plan is being processed.
          </Heading>
          <Text textAlign="center">
            We’re currently processing your care plan. This usually takes 2-3
            days. Check back soon for updates!
          </Text>
          <Button
            as={Link}
            to={`/results/${kitResultIdOrPublishHash}`}
            colorScheme="whiteCS"
          >
            Back to results
          </Button>
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid gap="8" spacing={6} alignEdges={false} bgColor="tints.cream">
        <GridItem width={[1]} paddingBottom="0">
          <Link
            to={`/results/${kitResultIdOrPublishHash}`}
            aria-label="Go back to results"
          >
            <Icon
              as={ArrowLeft}
              size="sm"
              fill="tints.uiGrey"
              backgroundColor="tints.white"
              borderRadius="3xl"
              cursor="pointer"
              weight={'regular'}
              height={space[12]}
              padding={space[3]}
              width={space[12]}
              aria-label="Left arrow icon"
              aria-hidden="true"
            ></Icon>
          </Link>
        </GridItem>
        <GridItem width={[1]}>
          <Container
            size={['fluid', null, 'xl']}
            centerContent={true}
            marginLeft="auto"
            marginRight="auto"
            gap="10"
          >
            <Box>
              <Heading as="h2" size="xl" textAlign="center" color="tints.black">
                Your Care Plan
              </Heading>
            </Box>
            <Box
              display={{ base: '', tablet: 'flex' }}
              maxWidth="640px"
              marginLeft="auto"
              marginRight="auto"
            >
              <Grid gap="6">
                <GridItem width={[1, 3 / 3]}>
                  <Box paddingBottom={{ base: space[4], tablet: 0 }}>
                    <CarePlanAccordion
                      simplified
                      items={formattedAccordionItems}
                    />
                  </Box>
                </GridItem>
                <GridItem width={[1, 3 / 3]}>
                  <ClinicianSummary
                    date={date}
                    name="Clinician Summary"
                    summary={summary}
                  />
                </GridItem>
                <GridItem width={[1, 3 / 3]}>
                  <Recommendations data={formattedRecommendationsItems} />
                </GridItem>
              </Grid>
            </Box>
          </Container>
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default KitResultCarePlan;
