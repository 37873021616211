import { theme } from '@everlywell/ui-kit';
import { Spinner } from '@phosphor-icons/react';
import { motion } from 'framer-motion';
import React from 'react';

const SpinningIcon = () => (
  <motion.div
    animate={{ rotate: 360 }}
    transition={{
      repeat: Infinity,
      duration: 3.5,
      ease: 'linear',
      type: 'spring',
    }}
  >
    <Spinner
      color={theme.colors.tints.medGrey}
      size={40}
      aria-label="Loading spinner"
    />
  </motion.div>
);

export default SpinningIcon;
