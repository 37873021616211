import { colors, mediaQueries, typography } from '@everlywell/leaves';
import {
  CardContainer,
  bodyCopy,
  bodyCopyLarge,
} from 'common/styles/app-styles';
import Button from 'components/Button';
import styled from 'styled-components';

const Container = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    max-height: 291px;
    max-width: 903px;
    margin-left: auto;
    margin-right: auto;
  }

  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
    max-width: 1189px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 207px;
    flex-basis: 207px;
  }
  ${mediaQueries.forDesktopUp} {
    max-width: 369px;
    flex-basis: 369px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-start;
  padding: 24px 24px 16px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 31px 31px 31px 34px;
    flex-basis: 146px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  width: calc(100% - 48px);
  margin: 0;
  padding: 10px 0 32px;
  border-top: 1px solid ${colors.gray2};

  ${mediaQueries.forTabletHorizontalUp} {
    width: 100%;
    margin: 30px 0;
    padding: 0 32px;
    border-top: none;
    border-left: 1px solid ${colors.gray2};
    align-self: stretch;
    display: flex;
    justify-content: space-between;
  }
`;

const Icon = styled.img`
  align-self: stretch;
  max-height: 182px;
  flex: 1;
  max-width: 100%;
  object-fit: cover;

  @media screen and (min-width: 414px) {
    max-height: 240px;
  }

  ${mediaQueries.forTabletVerticalUp} {
    max-height: 220px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    max-height: 100%;
  }
`;

const Header = styled.h3`
  margin: 0 0 4px;
  ${bodyCopy};
  letter-spacing: normal;
  font-weight: ${typography.weight.book};
  color: ${colors.gray5};

  ${mediaQueries.forTabletHorizontalUp} {
    ${bodyCopyLarge};
    line-height: 24px;
    margin-bottom: 14px;
  }

  ${mediaQueries.forDesktopUp} {
    font-size: 20.2px;
  }
`;

const Description = styled.p`
  ${bodyCopy};
  line-height: 30px;
  font-weight: ${typography.weight.light};
  color: ${colors.gray5};

  ${mediaQueries.forTabletHorizontalUp} {
    ${bodyCopyLarge};
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  ${bodyCopy};
  line-height: 30px;
  list-style-type: none;
  text-align: left;
  font-weight: ${typography.weight.light};
  color: ${colors.gray5};
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  padding: 4px 0 0;

  &:first-child {
    padding-top: 0;
  }
`;

const ListIcon = styled.img`
  display: inline-block;
  margin-top: 4px;
  margin-right: 12px;
  width: 16px;
  height: 16px;
`;

const LearnMoreButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  max-width: 300px;
  align-self: center;

  ${mediaQueries.forTabletHorizontalUp} {
    align-self: flex-start;
    max-width: none;
  }
`;

export {
  LearnMoreButton,
  Container,
  IconWrapper,
  DescriptionWrapper,
  ActionWrapper,
  Icon,
  Header,
  Description,
  List,
  ListItem,
  ListIcon,
};
