import { Flex, FlexProps } from '@everlywell/ui-kit';
import Layout from 'components/Layout';
import React from 'react';

export type ContainerProps = FlexProps & {
  children: React.ReactNode;
};

const Container = ({ children, ...styleProps }: ContainerProps) => (
  <Layout>
    <Flex
      backgroundColor="tints.cream"
      direction="column"
      minHeight="calc(100vh - 58px)"
      paddingBottom="10"
      paddingTop="10"
      width="100%"
      {...styleProps}
    >
      {children}
    </Flex>
  </Layout>
);

export default Container;
