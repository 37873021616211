import { Checkbox, Row, Col, LocalizeIgnore } from '@everlywell/leaves';
import { useSearchParam } from 'common/polyfill/router';
import analytics from 'common/utils/analytics';
import { API_ERRORS } from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { LAB_VISIT_QP } from 'common/utils/constants/bloodDraw';
import { KIT_REGISTRATION_FORM as DATA_TEST } from 'common/utils/constants/dataTest';
import ROUTES from 'common/utils/constants/routes';
import { RUM_ACTIONS } from 'common/utils/constants/rumActions';
import { formatKitId, replaceQueryString } from 'common/utils/helpers';
import useTimeTracker from 'common/utils/useTimeTracker';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { StringParam } from 'serialize-query-params';
import { COLLECTION_HELP_EMAIL } from 'common/utils/constants';

import ConfirmationErrorModal from '../Confirmation/ConfirmationErrorModal';
import MissingReplacementVariantModal from '../../../pages/ReplacementForm/MissingReplacementVariantModal';
import KitIDModal from '../KitIDModal';
import {
  AT_HOME_REGISTRATION,
  IN_LAB_REGISTRATION,
  RegistrationContent,
} from './constants';
import * as S from './styles';
import * as MissingReplacementVariantModalStyles from '../../../pages/ReplacementForm/styles';

const textID = 'kitID';
const checkboxID = 'approvedTestTaker';
const CODE = 'code';

const trackQRCodeUsage = (kitId: string) => {
  analytics.track({
    event: ANALYTICS.EVENTS.QR_CODE_USED,
    data: {
      kitId,
    },
  });
};

const isLabVisit = (labVisitParam: string): boolean => labVisitParam === 'true';

const getDynamicRegistrationContent = (
  labVisitParam: string,
): RegistrationContent =>
  isLabVisit(labVisitParam) ? IN_LAB_REGISTRATION : AT_HOME_REGISTRATION;

type Props = {
  onSubmit: (kitID: string) => void;
  errors?: string;
  expired?: boolean;
  setErrors?: React.Dispatch<React.SetStateAction<string>>;
};

const KitRegistrationForm = (props: Props) => {
  const { onSubmit, errors, setErrors, expired } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [kitID, setKitID] = useState('');
  const [checked, setChecked] = useState(false);
  const [kitDiscountinued, setKitDiscountinued] = useState(false);
  const [kitExpired, setKitExpired] = useState(false);
  const [replacementKitAvailable, setReplacementKitAvailable] = useState(false);
  const fireAction = useTimeTracker(RUM_ACTIONS.SUBMIT_KIT_ID);
  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.REGISTRATION,
    'data-analytics-label': ANALYTICS.LABELS.REGISTRATION,
  };
  const [requestIdFromUrl, setRequestIdFromUrl] = useSearchParam(
    CODE,
    StringParam,
  );
  const [labVisitParam] = useSearchParam<string>(LAB_VISIT_QP);
  const isLabVisitRequest = isLabVisit(labVisitParam);
  const registrationContent = getDynamicRegistrationContent(labVisitParam);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors && setErrors) {
      setErrors('');
    }
    if (e.target.value.length <= 14) {
      setKitID(formatKitId(e.target.value));
    }
  };

  const missingReplacementVariantModalBody = () => `
    The kit you are trying to register is expired. Unfortunately we were not able to process your replacement order request. We truly apologize for the inconvenience as we know how important these results are to you.

    We are dedicated to helping you get your results and care about your health.

    Please email our Customer Experience team so they can assist you with next steps and to answer any questions.
  `;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const label = `${registrationContent.title} Submitted`;
    const trackingData = {
      label,
      category: ANALYTICS.CATEGORIES.REGISTRATION,
    };
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: trackingData,
    });
    fireAction();
    onSubmit(kitID);
  };

  const navigateToReplacement = (barcodeSerialNumber: string) => {
    navigate(`${ROUTES.replacement.root}?barcode_serial_number=${barcodeSerialNumber}`);
  };

  const handleModalClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'Open Registration Help Modal',
        category: ANALYTICS.CATEGORIES.REGISTRATION,
      },
    });
    setOpenModal(!openModal);
  };

  useEffect(() => {
    setKitDiscountinued((prevValue) =>
      Boolean(
        errors &&
          !prevValue &&
          errors === API_ERRORS.kitRegistration.DISCONTINUED_KIT,
      ),
    );

    setKitExpired((prevValue) =>
      Boolean(
      errors &&
        !prevValue &&
        errors.includes(API_ERRORS.kitRegistration.EXPIRED_KIT),
      ),
    );

    setReplacementKitAvailable((prevValue) =>
      Boolean(
      errors &&
        !prevValue &&
        errors.includes(API_ERRORS.kitRegistration.CONTACT_CUSTOMER_CARE),
      ),
    );
  }, [errors]);

  useEffectOnce(() => {
    if (search.includes('complete=')) {
      const urlSearch = replaceQueryString(search, 'complete');
      navigate({ search: urlSearch });
    }
  });

  // Pre-fill input if code in URL via QR Code
  useEffectOnce(() => {
    if (requestIdFromUrl) {
      setKitID(requestIdFromUrl);
      setRequestIdFromUrl(undefined);

      if (!isLabVisitRequest) {
        trackQRCodeUsage(requestIdFromUrl);
      }
    }
  });

  const setConfirmationModalState = (isOpen: boolean) => {
    setKitDiscountinued(isOpen);
  };

  return (
    <>
      <Row start="xs" middle="xs">
        <Col>
          <S.TitleWrapper>
            <S.Title>{registrationContent.title}</S.Title>
          </S.TitleWrapper>
          <S.SubtitleWrapper>
            {registrationContent.subtitle}
            {!isLabVisitRequest && (
              <S.ModalButton
                onClick={handleModalClick}
                data-test="registration-help-modal-trigger"
              >
                Can’t find your Unique <LocalizeIgnore>ID</LocalizeIgnore>?
              </S.ModalButton>
            )}
          </S.SubtitleWrapper>
        </Col>
        <Col>
          <form onSubmit={handleSubmit} data-test={DATA_TEST.FORM}>
            <S.KitIDInput
              inputClassName="inspectletIgnore"
              label={registrationContent.inputLabel}
              id={textID}
              value={kitID}
              name={textID}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              data-test={DATA_TEST.INPUT}
              error={errors}
            />
            <S.CheckboxContainer>
              <Checkbox
                name={checkboxID}
                label="I am the test taker"
                onChange={() => setChecked(!checked)}
                checked={checked}
                data-test={DATA_TEST.CHECKBOX}
                border
              />
            </S.CheckboxContainer>
            <S.Disclaimer>{registrationContent.disclaimer}</S.Disclaimer>
            <S.Submit
              isDisabled={!checked || kitID.length === 0}
              appearance="primary"
              data-test={DATA_TEST.SUBMIT}
              {...analyticsAttributes}
            >
              {registrationContent.registerLabel}
            </S.Submit>
          </form>
        </Col>
        <KitIDModal openModal={openModal} setOpenModal={setOpenModal} />
      </Row>
      {kitDiscountinued && (
        <ConfirmationErrorModal
          openModal={kitDiscountinued}
          setOpenModal={navigateToReplacement}
          onClose={() => setConfirmationModalState(false)}
          title="Unable to Register"
          message="The kit you are trying to register is no longer able to be processed by our partner lab. We are happy to send you a replacement kit so that you can receive results."
          cta="Replace Kit"
          barcodeSerialNumber={kitID}
        />
      )}

      {kitExpired && replacementKitAvailable && (
        <ConfirmationErrorModal
          openModal={kitExpired}
          setOpenModal={navigateToReplacement}
          onClose={() => setConfirmationModalState(false)}
          title="Unable to Register"
          message="The kit you are trying to register is expired. We can send you a replacement kit if you would like or you can contact customer care for a refund."
          cta="Replace Kit"
          barcodeSerialNumber={kitID}
        />
      )}

      {kitExpired && !replacementKitAvailable && (
        <MissingReplacementVariantModal
          openModal={kitExpired && !replacementKitAvailable}
          setOpenModal={() => kitExpired && !replacementKitAvailable}
          title="There seems to be an issue."
          message={missingReplacementVariantModalBody()}
          cta={
            <MissingReplacementVariantModalStyles.ModalCta
              onClick={() =>
                window.open(`mailto:${COLLECTION_HELP_EMAIL}`, '_blank')
              }
            >
              Email Us
            </MissingReplacementVariantModalStyles.ModalCta>
          }
        />
      )}
    </>
  );
};

export default KitRegistrationForm;
