export type OrderConfirmationModalContent = {
  title: string;
  copy: string;
  ctaText: string;
};

export const DEFAULT_CONTENT: OrderConfirmationModalContent = {
  title: 'Thanks for your purchase! We are processing your order.',
  copy: 'We will notify you once your order has been reviewed by an independent board-certified physician within your state, and include next steps for scheduling your in-person visit',
  ctaText: 'Got it',
};

export const EW_360_CONTENT: OrderConfirmationModalContent = {
  title: DEFAULT_CONTENT.title,
  copy: 'We will notify you once an independent board-certified physician has reviewed your order, and include next steps for scheduling your in-person visit. You will also receive an email receipt of your purchase, as well as tracking updates for any at-home lab kits purchased.',
  ctaText: DEFAULT_CONTENT.ctaText,
};
