import { Box } from '@everlywell/ui-kit';
import React from 'react';

import InnerCircle from '../InnerCircle';
import OuterCircle from '../OuterCircle';

export interface WellnessCircleProps {
  wellnessScore?: number | null;
  categories: {
    name: string;
    status: 'normal' | 'abnormal';
    markers: {
      id: number;
      name: string;
      status: 'normal' | 'abnormal' | 'dnr';
    }[];
  }[];
  isLoading?: boolean;
}

const INNER_CIRCLE_DIAMETER = 240;
const INNER_CIRCLE_RADIUS = INNER_CIRCLE_DIAMETER / 2;

const POINTER_RADIUS = INNER_CIRCLE_RADIUS * 0.75;

/**
 * Renders the WellnessCircle component.
 * The WellnessCircle is composed of an outer circle with markers,
 * an inner circle with gradients, and pointers for each category.
 */
const WellnessCircle: React.FC<WellnessCircleProps> = ({
  wellnessScore,
  categories,
  isLoading,
}) => {
  const totalCategories = categories.length;

  const categoryAngles = categories.map(
    (_, index) => (360 / totalCategories) * index,
  );

  const categoryStatuses = categories.map((category) => category.status);

  const pointers = categories.map((category, index) => ({
    angle: categoryAngles[index],
    radius: -POINTER_RADIUS,
    label: category.name,
    status: category.status,
  }));

  // Show placeholder markers while loading
  const DEFAULT_MARKER_COUNT = 88;
  const placeholderMarkers = Array.from(
    { length: DEFAULT_MARKER_COUNT },
    () => ({
      name: '',
      status: 'normal' as const,
    }),
  );

  const markers = isLoading
    ? placeholderMarkers
    : categories.flatMap((category) =>
        category.markers.map((marker) => ({
          name: marker.name,
          status: marker.status,
        })),
      );

  return (
    <Box position="relative" width="320px" height="320px">
      <OuterCircle
        markers={markers}
        markersCount={markers.length}
        isLoading={isLoading}
      />

      <InnerCircle
        wellnessScore={wellnessScore}
        categoryStatuses={categoryStatuses}
        pointers={pointers}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default WellnessCircle;
