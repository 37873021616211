import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import useUser from 'common/hooks/useUser/useUser';
import { telehealthUrl } from 'common/utils/helpers';
import React from 'react';

export function useTelehealthAccount(): {
  isLoading: boolean;
  hasTelehealthAccount: boolean;
  parsedUrl: string;
} {
  const { user, isLoading: isLoadingUser } = useUser();
  const url = telehealthUrl({ redirectToProvider: 'true' });

  const parsedUrl = React.useMemo(() => `${url.pathname}${url.search}`, [url]);

  const isEnrolled = (user?.enrolled_programs ?? []).length > 0;

  const { data: response, isLoading: isLoadingAppointments } =
    useTelehealthAppointments({ period: 'all' }, { enabled: isEnrolled });

  const hasTelehealthAccount = Boolean(response?.data?.appointments?.length);

  const isLoading = React.useMemo(
    () => isLoadingAppointments || isLoadingUser,
    [isLoadingAppointments, isLoadingUser],
  );

  return { hasTelehealthAccount, isLoading, parsedUrl };
}
