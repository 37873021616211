import {
  typography,
  size,
  colors,
  mediaQueries,
  Tooltip,
  Modal as LeavesModal,
  H3,
  Button as LeavesButton,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Modal = styled(LeavesModal)`
  top: ${size.xl4}px;
  padding-bottom: ${size.xl5}px;

  > div {
    justify-content: center;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: 0;
  }
`;

export const ModalTitle = styled(H3)`
  margin: 0 0 ${size.xs1}px 0;
`;

export const PaymentForm = styled.form`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    min-width: 470px;
  }
`;

export const AcceptedCards = styled.div`
  align-self: center;
  display: flex;
  justify-content: start;
  gap: ${size.lg}px;
  padding: ${size.xs2}px 0 ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    align-self: center;
    display: flex;
    justify-content: start;
    gap: ${size.lg}px;
  }
`;

export const CreditCard = styled.img`
  width: 55px;
  height: 37px;
  border-radius: 2.4px;
  margin: ${size.sm}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    height: 35px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
`;

export const StripeInputWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: ${size.xs1}px;
`;

export const StripeLabel = styled.label`
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.dmSans};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.5;
  letter-spacing: normal;
  padding-bottom: ${size.xs2}px;
`;

export const StyledInputContainer = styled.div`
  position: relative;
  margin-bottom: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.sm}px ${size.xs1};
  }
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  left: 13%;
  bottom: 78%;
  color: ${colors.green4};

  ${mediaQueries.forTabletVerticalUp} {
    left: 10%;
    bottom: 79%;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    left: 19%;
    top: 8%;
  }
`;

export const CvcTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 290px;
    height: 150px;
    padding: ${size.xs1}px ${size.sm}px;

    ${mediaQueries.forTabletVerticalUp} {
      width: 295px;
      padding: ${size.xs1}px ${size.sm}px;
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  gap: ${size.sm}px;
  margin: ${size.xl1}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    flex-direction: row;
    gap: ${size.lg}px;
    margin-top: ${size.xl2}px;
  }
`;

export const SaveButton = styled(LeavesButton)`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    width: 50%;
  }
`;

export const CancelButton = styled(LeavesButton)`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    width: 50%;
  }
`;

export const Error = styled.span`
  color: ${colors.red3};
  display: block;
  height: ${size.lg}px;
`;

export const ToastWrapper = styled.div`
  & > div {
    max-width: 600px;
    width: calc(100% - ${size.xl1}px);

    ${mediaQueries.forTabletVerticalUp} {
      width: calc(100% - ${size.xl1}px);
      margin: 0 auto;
      max-width: 100%;
      top: ${size.md}px;
    }
  }
`;
