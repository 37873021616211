import { UpdateAddress } from 'common/apis/settingsApis';
import { CreateTelehealthAppointmentRequestData } from 'common/apis/telehealthSchedulingApis';
import factCollectionQuery from 'common/fixtures/factCollectionQuery';
import { IntakeIntroScreenCollectionQuery } from 'common/fixtures/IntakeIntroScreenCollectionQuery';
import { buildAllergies } from 'common/testUtils/builders/allergies';
import { buildCarePlan as buildWellnessCarePlan } from 'common/testUtils/builders/kitResultCarePlan';
import {
  buildNewOnDemandAppointment,
  buildNewSelfScheduledAppointment,
  buildTelehealthAppointmentCancellation,
} from 'common/testUtils/builders/telehealthAppointments';
import {
  buildTelehealthAppointmentSlot,
  buildTelehealthAppointmentType,
  buildTimes,
} from 'common/testUtils/builders/telehealthAppointmentsSlots';
import { buildCarePlan } from 'common/testUtils/builders/telehealthCarePlan';
import {
  buildTelehealthAnswerGroup,
  buildTelehealthForm,
  buildTelehealthInsurancePlan,
} from 'common/testUtils/builders/telehealthForm';
import { buildInsuranceDetails } from 'common/testUtils/builders/telehealthInsuranceDetails';
import { buildOrder } from 'common/testUtils/builders/telehealthOrder';
import { buildProvider } from 'common/testUtils/builders/telehealthProviders';
import { buildTelehealthSession } from 'common/testUtils/builders/telehealthSession';
import { buildWellnessScore } from 'common/testUtils/builders/wellnessScore';
import { eachDayOfInterval, endOfMonth, isWeekend, sub } from 'date-fns';
import { isUndefined, random } from 'lodash';
import { graphql, rest } from 'msw';

import { buildKitResult } from '../../builders/kitResult';
import { buildMemberAddress } from '../../builders/memberAddress';
import { buildTelehealthByAppointment } from '../../builders/telehealthByAppointmentId';
import {
  buildTelehealthMedication,
  buildTelehealthMedicationV2,
} from '../../builders/telehealthMedications';
import { buildUser } from '../../builders/user';
import { appointmentsResolver } from './helpers/telehealth';

export const handlers = [
  rest.get(
    '*/aapi/v3/telehealth/:program/appointment_slots',
    async (req, res, ctx) => {
      const startDate = req.url.searchParams.get('start_date');
      const endDate = req.url.searchParams.get('end_date');

      const availableDates = eachDayOfInterval({
        start: startDate ? new Date(startDate) : new Date(),
        end: endDate ? new Date(endDate) : endOfMonth(new Date()),
      }).filter((date) => !isWeekend(date));

      const slots = availableDates
        .map((date) => buildTimes(date))
        .flat()
        .map((datetime) => buildTelehealthAppointmentSlot({ datetime }));

      return res(
        ctx.delay(random(500, 7000)), // simulate network latency that we're seeing in prod
        ctx.json({
          meta: {
            user: {
              id: 123,
              time_zone: 'America/New_York',
              state_abbrev: 'TX',
            },
            appointment_type: buildTelehealthAppointmentType(),
          },
          slots,
        }),
      );
    },
  ),

  rest.get('*/aapi/v3/telehealth/appointments', appointmentsResolver()),
  rest.post('*/aapi/v3/telehealth/appointments', async (req, res, ctx) => {
    const body = await req.json<CreateTelehealthAppointmentRequestData>();
    if (body.booking_type === 'on_demand') {
      if (!isUndefined(body.program_slug)) {
        return res(ctx.status(200), ctx.json(buildNewOnDemandAppointment()));
      } else {
        return res(ctx.status(400));
      }
    } else if (body.booking_type === 'self_scheduled') {
      if (
        !isUndefined(body.program_slug) &&
        !isUndefined(body.datetime) &&
        !isUndefined(body.provider_id)
      ) {
        return res(
          ctx.status(200),
          ctx.json(buildNewSelfScheduledAppointment()),
        );
      } else {
        return res(ctx.status(400));
      }
    }

    return res(ctx.status(400));
  }),
  rest.get(
    '*/health-profile/api/v2/questionnaires/health-profile/progress',
    (req, res, ctx) =>
      res(
        ctx.json({
          questionnaire: {
            last_interacted_at: sub(new Date(), { weeks: 3 }).toISOString(),
            progress: 0,
            unanswered_questions: 10,
          },
        }),
      ),
  ),
  graphql.query('FactCollectionQuery', (req, res, ctx) =>
    res(ctx.data(factCollectionQuery)),
  ),
  rest.get('*/aapi/v2/personalizations', (req, res, ctx) =>
    res(
      ctx.json({
        health_profile: {
          food_sensitivity: [],
          pcp: null,
          fact_cards: [
            'Digestive Disorder',
            'Headaches/Migraines',
            'Thyroid Disorder',
          ],
        },
      }),
    ),
  ),
  rest.get('*/aapi/v1/users/:id', (req, res, ctx) => {
    const id = req.params.id;
    if (typeof id === 'string') {
      return res(ctx.json(buildUser({ id: 1 })));
    } else {
      return res(ctx.status(404));
    }
  }),
  rest.put('*/aapi/v1/users/:id', async (req, res, ctx) => {
    const id = req.params.id;
    const user = await req.json();
    if (typeof id === 'string' && user) {
      return res(ctx.json(buildUser({ id: 1, ...user })));
    } else {
      return res(ctx.status(404));
    }
  }),
  rest.get('*/aapi/v2/results_dashboard', (req, res, ctx) =>
    res(ctx.json([buildKitResult()])),
  ),
  rest.get('*/aapi/v1/settings/shipping_address', (req, res, ctx) =>
    res(ctx.json(buildMemberAddress())),
  ),
  rest.post('*/aapi/v1/settings/shipping_address', async (req, res, ctx) => {
    const body: { shipping_address: UpdateAddress } = await req.json();
    const updatedAddress = buildMemberAddress({
      first_name: body.shipping_address.first_name,
      last_name: body.shipping_address.last_name,
      street1: body.shipping_address.address1,
      street2: body.shipping_address.address2,
      city: body.shipping_address.city,
      state: body.shipping_address.state,
      zipcode: body.shipping_address.zipcode,
    });

    return res(ctx.json(updatedAddress));
  }),

  rest.post('https://api-js.mixpanel.com/track/', (req, res, ctx) =>
    res(ctx.status(200)),
  ),
  rest.get('*/aapi/v2/account_hub', (req, res, ctx) => res(ctx.json([]))),
  rest.get('*/aapi/v2/telehealth/appointments/:id', (req, res, ctx) =>
    res(ctx.json([buildTelehealthByAppointment()])),
  ),
  rest.get('*/aapi/v2/telehealth/medications', (req, res, ctx) =>
    res(ctx.json([buildTelehealthMedication()])),
  ),
  rest.get('*/aapi/v3/telehealth/care_plans/:id', (req, res, ctx) =>
    res(ctx.json([buildTelehealthByAppointment()])),
  ),
  rest.get('*/aapi/v2/telehealth/forms/:id', (req, res, ctx) => {
    const id = req.params.id;
    if (typeof id === 'string') {
      return res(
        ctx.json(
          buildTelehealthForm({
            id: id,
          }),
        ),
      );
    } else {
      return res(ctx.status(404));
    }
  }),
  rest.post('*/aapi/v2/telehealth/forms/:id/answer_groups', (req, res, ctx) =>
    res(ctx.status(201), ctx.json({})),
  ),
  rest.get(
    '*/aapi/v2/telehealth/forms/:form_id/answer_groups',
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json([buildTelehealthAnswerGroup()])),
  ),

  rest.get('*/aapi/v2/telehealth/sessions/new', (req, res, ctx) =>
    res(ctx.status(200), ctx.json(buildTelehealthSession())),
  ),

  rest.post('*/aapi/v2/telehealth/sessions', async (req, res, ctx) => {
    const { user, program } = await req.json();
    const { state_abbrev, time_zone, results_sharing_consent } = user;

    if (
      state_abbrev &&
      time_zone &&
      program &&
      !isUndefined(results_sharing_consent)
    ) {
      return res(ctx.status(201), ctx.json(null));
    } else {
      return res(ctx.status(400));
    }
  }),

  rest.get('*/aapi/v2/telehealth/insurance_plans', (req, res, ctx) => {
    const keywords = req.url.searchParams.get('keywords');
    let insurancePlans = [buildTelehealthInsurancePlan()];

    if (keywords) {
      insurancePlans = insurancePlans.filter(({ payer_name }) => {
        const words = (keywords as string).split(/\s+/);

        return words.some((word) => payer_name.toLowerCase().includes(word));
      });
    }

    return res(ctx.json(insurancePlans));
  }),

  rest.post('*/aapi/v2/telehealth/insurance_plans', async (req, res, ctx) =>
    res(ctx.status(201), ctx.json({})),
  ),

  rest.post('*/aapi/v2/telehealth/payments', async (req, res, ctx) => {
    const { token, card_label_type, wants_marketing } = await req.json();

    if (
      !isUndefined(token) &&
      !isUndefined(card_label_type) &&
      !isUndefined(wants_marketing)
    ) {
      return res(ctx.status(200), ctx.json({}));
    } else {
      return res(ctx.status(400), ctx.json({}));
    }
  }),

  rest.post('*/aapi/v3/memberships/:id/redemptions', async (_, res, ctx) =>
    res(ctx.status(200), ctx.json({ id: 123, type: 'Order', user_id: 1 })),
  ),
  rest.post(
    '*/aapi/v3/telehealth/appointment_cancellations',
    async (_req, res, ctx) =>
      res(ctx.status(201), ctx.json(buildTelehealthAppointmentCancellation())),
  ),

  rest.get('*/aapi/v3/telehealth/providers', (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        buildProvider({
          name: 'Dr. Elena Garcia',
        }),
        buildProvider({
          name: 'Pedro Pascal',
        }),
        buildProvider({
          name: 'Karen Wheeler',
        }),
        buildProvider({
          name: 'Martin Brenner',
        }),
      ]),
    ),
  ),

  rest.get('*/aapi/v3/telehealth/insurance_details', (req, res, ctx) =>
    res(ctx.status(200), ctx.json([buildInsuranceDetails()])),
  ),
  rest.get(
    '*/aapi/v3/telehealth/enterprise/insurance_details',
    (req, res, ctx) => res(ctx.status(200), ctx.json({ data: {} })),
  ),

  rest.post('*/aapi/v3/telehealth/documents', async (_req, res, ctx) =>
    res(ctx.status(201), ctx.json(null)),
  ),

  rest.get('*/aapi/v3/telehealth/medications', (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        buildTelehealthMedicationV2(),
        buildTelehealthMedicationV2({
          id: 2,
          name: 'asparaginase (Erwinia chrysanthemi) 10,000 unit solution for injection',
        }),
        buildTelehealthMedicationV2({
          id: 3,
          name: 'asparaginase Erwinia chrysanthemi-rywn 10 mg/0.5 mL IM solution',
        }),
      ]),
    ),
  ),

  rest.get('*/aapi/v3/telehealth/allergies', (_req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json([
        buildAllergies(),
        buildAllergies({
          id: 2,
          name: 'lactic acid',
        }),
        buildAllergies({
          id: 3,
          name: 'Lactinex',
        }),
      ]),
    ),
  ),

  rest.get('*/aapi/v3/telehealth/appointments/:id/care_plan', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ care_plan: buildCarePlan() })),
  ),

  rest.get('*/aapi/v3/telehealth/orders/:type/:id', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ order: buildOrder() })),
  ),
  graphql.query('GetAthenaCarePlans', (req, res, ctx) =>
    res(
      ctx.data({
        sectionModel: {
          __typename: 'SectionModel',
          eyebrow: 'Care Plans Test Contentful',
          body: {
            json: JSON.stringify({
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Contact your care team team at',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      data: {
                        uri: 'tel:7087874438',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: ' (708)-787-4438',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ', Monday through Friday, 7am-7pm CST with any questions related to your care.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        '\nIf you are having serious symptoms, please call 911 or seek in-person care.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            }),
          },
        },
      }),
    ),
  ),
  graphql.query('GetIntakeIntroScreen', (req, res, ctx) =>
    res(ctx.data(IntakeIntroScreenCollectionQuery)),
  ),
  rest.get('*/aapi/v3/telehealth/wellness/care_plans', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ care_plans: [buildWellnessCarePlan()] })),
  ),
  rest.get(
    '*/aapi/v3/telehealth/wellness/scores/:serial_number',
    (req, res, ctx) => res(ctx.status(200), ctx.json(buildWellnessScore())),
  ),
];
