import { colors, size, Icons, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

const commonStyle = `
  align-items: center;
  flex-direction: row;
  font-size: ${size.md}px;
`;

type getBorderBarProps = {
  borderBar: boolean;
};

const showBorderBar = `
  border-top: 1px solid;
  border-top-color: ${colors.gray1};
`;

export const KitMenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const KitMenu = styled.div`
  width: 224px;
  right: 35px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  padding: ${size.sm}px;
  border: solid 1px;
  border-radius: ${size.xs2}px;
  background-color: ${colors.white};
  border-color: ${colors.green2};
  z-index: 1;
  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.md}px;
    line-height: ${size.lg}px;
  }
`;

export const TrackPackage = styled.a`
  display: block;
  text-decoration: none !important;
  padding: ${size.sm}px;
  border-radius: ${size.xs2}px;
  color: ${colors.green5};
  background-color: ${colors.green1};
  margin: 0 0 ${size.sm}px;
  ${commonStyle};
`;

export const BuyAgain = styled.a`
  display: block;
  text-decoration: none !important;
  color: ${colors.green5};
  padding: ${size.sm}px;
  margin: 0 ${size.sm}px ${size.xs1}px;
  &:hover {
    background-color: ${colors.green1};
    color: ${colors.green5};
    border-radius: ${size.xs2}px;
  }

  ${commonStyle};
`;

export const KitId = styled.div<getBorderBarProps>`
  padding-top: ${size.md}px;
  padding-left: ${size.sm}px;
  color: ${colors.gray4};
  margin: 0 0 ${size.xs2}px 0;
  ${commonStyle};
  ${(props) => (props.borderBar ? showBorderBar : null)};
`;

export const KebobButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const KebobIcon = styled(Icons.Kebob)`
  color: ${colors.teal4};
`;
