import {
  KitResultCarePlanRecommendationTypes,
  KitResultCarePlanSectionTypes,
} from 'common/apis/telehealthApis';
import { CarePlanInfoCardProps } from 'components/telehealth/CarePlan/components/CarePlanInfoCard';

import { RecommendationSection } from './Recommendations';

// TODO: Check naming for this const
export const CARE_PLAN_SECTION_TYPE_MAPPING: Record<
  KitResultCarePlanSectionTypes,
  { iconName: CarePlanInfoCardProps['iconName']; text: string }
> = {
  heart: {
    iconName: 'Heart',
    text: 'Heart and Metabolic',
  },
  nutrition: {
    iconName: 'Shapes',
    text: 'Nutritional',
  },
  hormone: {
    iconName: 'CirclesThree',
    text: 'Hormone and Sexual',
  },
  immune: {
    iconName: 'CircleHalf',
    text: 'Immune and Blood Cell',
  },
  inflammation: {
    iconName: 'ShieldPlus',
    text: 'Inflammation and Autoimmunity',
  },
  liver: {
    iconName: 'DropHalfBottom',
    text: 'Liver, Kidney and Bladder',
  },
};

// TODO: Check naming for this const
export const CARE_PLAN_RECOMMENDATION_SECTION_TYPE_MAPPING: Record<
  KitResultCarePlanRecommendationTypes,
  {
    iconName: RecommendationSection['iconName'];
    color: string;
    bgColor: string;
    text: string;
  }
> = {
  food: {
    iconName: 'ForkKnife',
    color: 'eggplant.lighter',
    bgColor: 'eggplant.wash',
    text: 'Food and Nutrition',
  },
  supplement: {
    iconName: 'CirclesThreePlus',
    color: 'viridian.light',
    bgColor: 'viridian.wash',
    text: 'Supplement Support',
  },
  exercise: {
    iconName: 'PersonSimpleWalk',
    color: 'moonlight.lighter',
    bgColor: 'moonlight.wash',
    text: 'Exercise and Activity',
  },
  lifestyle: {
    iconName: 'Sun',
    color: 'sunshine.base',
    bgColor: 'sunshine.wash',
    text: 'Lifestyle and Daily Health',
  },
};

export const CarePlanSectionTypes = [
  'heart',
  'nutrition',
  'hormone',
  'immune',
  'inflammation',
  'liver',
] as const;
export const CarePlanRecommendationTypes = [
  'food',
  'supplement',
  'exercise',
  'lifestyle',
] as const;
export const CarePlanSectionDataResponse = ['insights', 'actions'] as const;
