import { getWellnessScore } from 'common/apis/telehealthApis';
import { useQuery } from 'react-query';

const useWellnessScore = (serialNumber: string) => {
  const {
    data: response,
    isLoading,
    isFetching,
    error,
    isError: hasError,
  } = useQuery(
    ['telehealth-wellness-care-plan', serialNumber],
    () => getWellnessScore(serialNumber),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    hasError,
    error,
    wellnessScore: response?.data?.wellness_score ?? null,
    categories: response?.data?.categories ?? [],
    isLoading: isLoading || isFetching,
  };
};

export default useWellnessScore;
