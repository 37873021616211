import { Marker, MarkerResult } from 'common/utils/types';
import React from 'react';

import * as S from './styles';

export type Props = {
  excludedMarkerValues?: string[];
  marker?: Marker;
  markerResult: MarkerResult;
  rangeColors?: any;
};

const MIN_DISTANCE_FROM_EDGE = 7;
const MAX_MARKER_INDICATOR_PERCENTAGE = 97.0;

export class MarkerChart extends React.PureComponent<Props> {
  render() {
    const {
      excludedMarkerValues = [],
      marker,
      markerResult,
      rangeColors,
    } = this.props;

    const {
      boundaries,
      severity_index: severityIndex,
      value: markerValueToDisplay,
    } = markerResult;

    let { value: markerValue } = markerResult;

    // excludedMarkerValues array contains unusual marker values such as '<dl', 'N/A'
    // this will check if current marker value is one of these values so we can conditionally render the marker value badge
    const showMarkerValueBadge = !excludedMarkerValues.includes(
      markerValue.toString().toLowerCase(),
    );

    if (typeof markerValue === 'string') {
      markerValue = Number(markerValue.replace(/[^\d.-]/g, ''));
    }

    const ranges: JSX.Element[] = [];
    let last = 0;
    const boundaryRange = 100 / (boundaries.length + 1);
    let end = boundaryRange;

    let markerResultPosition = 0;
    let alignItems = 'center';

    const alignMarkerBadge = (position: number) => {
      if (position <= MIN_DISTANCE_FROM_EDGE) {
        alignItems = 'flex-start';
      } else if (position >= 100 - MIN_DISTANCE_FROM_EDGE) {
        alignItems = 'flex-end';
      }
    };

    return (
      <S.MarkerChartCard data-test="MarkerChart">
        <S.MarkerChartWrapper>
          {marker &&
            marker.descriptors.map((descriptor, index) => {
              const lowerBoundary = boundaries[index - 1] || 0;
              const markerMaxValue = marker.max_value;
              const upperBoundary =
                boundaries[index] || markerMaxValue || Infinity;
              let rangeLabelPosition = '';

              if (
                !marker.descriptors[index - 1] &&
                marker.descriptors[index + 1]
              ) {
                // place range label on the left
                rangeLabelPosition = 'left';
              } else if (
                marker.descriptors[index - 1] &&
                !marker.descriptors[index + 1]
              ) {
                // place range label on the right
                rangeLabelPosition = 'right';
              }

              if (
                lowerBoundary <= (markerValue as number) &&
                (markerValue as number) <= upperBoundary
              ) {
                // marker value is within the current range
                const min = lowerBoundary;

                // make maximum upper boundary range consistent with minimum
                const max = Number.isFinite(upperBoundary)
                  ? upperBoundary
                  : min + boundaries[0];

                // math
                // don't let position go outside chart
                markerResultPosition = Math.min(
                  MAX_MARKER_INDICATOR_PERCENTAGE,
                  last +
                    (((markerValue as number) - min) /
                      (max - min) /
                      (boundaries.length + 1)) *
                      100,
                );

                alignMarkerBadge(markerResultPosition);
              }

              // marker value is over the marker's maximum value, simply set it to the
              // farthest right
              if (markerMaxValue && (markerValue as number) > markerMaxValue) {
                markerResultPosition = MAX_MARKER_INDICATOR_PERCENTAGE;

                alignMarkerBadge(markerResultPosition);
              }

              ranges.push(
                <div key={`range-wrapper-${index.toString()}`}>
                  <S.Range
                    className={rangeLabelPosition}
                    color={
                      rangeColors?.[index]
                        ? rangeColors[index].toString()
                        : undefined
                    }
                    key={`range_${index.toString()}`}
                    start={last}
                    width={boundaryRange}
                  >
                    <S.RangeLabel className={rangeLabelPosition}>
                      {descriptor}
                    </S.RangeLabel>
                  </S.Range>
                </div>,
              );

              // save the last position
              last = end;
              end += boundaryRange;
              if (marker.descriptors[index + 1]) {
                return (
                  <S.Boundary
                    key={`range_${index.toString()}`}
                    percentage={last}
                  >
                    <S.BoundaryBadge>{upperBoundary}</S.BoundaryBadge>
                  </S.Boundary>
                );
              }
              return null;
            })}
          {showMarkerValueBadge && (
            <S.ResultMarkerWrapper
              data-test="marker-value-badge"
              percentage={markerResultPosition}
            >
              <S.ResultMarker alignItems={alignItems}>
                <S.ResultMarkerBadge color={rangeColors[severityIndex]}>
                  {markerValueToDisplay.toString().replace(/ +/g, '')}
                </S.ResultMarkerBadge>
              </S.ResultMarker>
            </S.ResultMarkerWrapper>
          )}
          <S.MarkerChartBase>{ranges.map((range) => range)}</S.MarkerChartBase>
        </S.MarkerChartWrapper>
      </S.MarkerChartCard>
    );
  }
}

export default MarkerChart;
