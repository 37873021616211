import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
  Skeleton,
} from '@everlywell/ui-kit';
import { CaretDown } from '@phosphor-icons/react';
import React from 'react';

import { CarePlanInfoCardProps } from '../CarePlanInfoCard';
import CarePlanInfoCardText from '../CarePlanInfoCardText';

export type CardPlanInfoCardBodyProps = {
  allowMultipleExpanded?: boolean
  isLoading?: boolean;
  items: CarePlanInfoCardProps['items'];
  simplified?: boolean;
  wrapper: CarePlanInfoCardProps['bodyWrapper'];
};

const CarePlanInfoCardBody = ({
  allowMultipleExpanded = true,
  isLoading,
  items,
  simplified = false,
  wrapper,
}: CardPlanInfoCardBodyProps) => {
  const hasSingleItem = (items?.length ?? 0) === 1;
  const hasLessThenThreeItems = (items?.length ?? 0) <= 3;

  const defaultIndex = hasLessThenThreeItems ? [0] : [];

  const hasWrapper = !!wrapper;
  const WrapperComponent = hasWrapper ? wrapper?.component : React.Fragment;
  const wrapperProps = hasWrapper ? wrapper?.props : {};

  return (
    <WrapperComponent {...wrapperProps}>
      <Accordion
        allowMultiple={allowMultipleExpanded}
        allowToggle={!hasSingleItem}
        defaultIndex={defaultIndex}
      >
        <Flex
          direction="column"
          gap="2"
          paddingLeft="4"
          paddingRight="4"
          paddingBottom="4"
        >
          {isLoading ? (
            <Box
              display="flex"
              flexDirection="column"
              gap="3"
              padding="3"
              backgroundColor="tints.lightCream"
              borderRadius="lg"
              role="progressbar"
            >
              <Skeleton width="80%" padding="3" />
              <Skeleton width="100%" padding="3" />
            </Box>
          ) : items?.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              gap="3"
              padding="3"
              backgroundColor="tints.lightCream"
              borderRadius="lg"
              textAlign="center"
            >
              <CarePlanInfoCardText content="There is no content to be shown at the moment" />
            </Box>
          ) : (
            items?.map((item, index) => (
              <Box
                as="article"
                backgroundColor="tints.lightCream"
                borderRadius="lg"
                padding="3"
                key={`item-${index}`}
              >
                <AccordionItem
                  border={0}
                  display="flex"
                  flexDirection="column"
                  gap="1"
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        padding={0}
                        pointerEvents={!hasSingleItem ? 'auto' : 'none'}
                      >
                        <CarePlanInfoCardText
                          content={item.description}
                          color="tints.black"
                        />
                        {!hasSingleItem ? (
                          <Icon
                            as={CaretDown}
                            size="sm"
                            fill="tints.uiGrey"
                            weight="regular"
                            transform={isExpanded ? 'rotate(180deg)' : ''}
                            transition={`transform 0.3s`}
                            transformOrigin="center center"
                            aria-hidden="true"
                          />
                        ) : null}
                      </AccordionButton>
                      <AccordionPanel
                        padding={0}
                        display="flex"
                        flexDirection="column"
                        gap="3"
                      >
                        <CarePlanInfoCardText
                          simplified={simplified}
                          content={item.note ?? ''}
                        />
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Box>
            ))
          )}
        </Flex>
      </Accordion>
    </WrapperComponent>
  );
};

export default CarePlanInfoCardBody;
