import DOMPurify from 'dompurify';

/**
 * equivalent of $.remove
 * removes specified HTML element from DOM
 * @param { HTML Element } element HTML to remove
 */
export function remove(element: Element) {
  if (element && element.parentNode) {
    element.parentNode.removeChild(element);
  }
}

/**
 * creates DOM node from string html
 * @param { String } htmlString string of HTML element to create
 */
export function createElementFromHTML(htmlString: string) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
}

/**
 * equivalent of $.after
 * inserts first element after the 2nd element
 * @param { HTML ELement } el element you want to insert
 * @param { HTML Element } sibling element you want to insert the desired element after
 */
export function insertAfter(el: Element, sibling: Element) {
  if (sibling && el && sibling.parentNode) {
    sibling.parentNode.insertBefore(el, sibling.nextElementSibling);
  }
}

// show Flash Message
export function showFlash(classes: string, text: string) {
  const alertSection = document.querySelector('#alert-flash');
  const ewHeader = document.querySelector('#app') as HTMLDivElement;

  if (alertSection) {
    remove(alertSection);
  }

  const flashHTML = createElementFromHTML(
    `<div id='alert-flash' class='${classes}'>${text}</div>`,
  ) as HTMLDivElement;
  insertAfter(flashHTML, ewHeader);
}

type SanitizeOptions = {
  ALLOWED_TAGS?: string[]; // set tag whitelist
  ADD_TAGS?: string[]; // add tags to tag whitelist
  ALLOWED_ATTR?: string[]; // set attribute whitelist
};

export const defaultSanitizeOptions: SanitizeOptions = {
  ALLOWED_TAGS: ['p', 'b', 'i', 'em', 'strong', 'ol', 'ul', 'li', 'a', 'br'],
  // don't allow attributes by default
  ALLOWED_ATTR: ['href', 'target'],
};

/**
 * sanitize an untrusted HTML string so it only contains allowed HTML
 *
 * returns a sanitized HTML string
 * @param dirty a string containing untrusted HTML
 * @param options an object containing the allowed tags and attributes
 * (refer to https://github.com/cure53/DOMPurify/ for full options)
 */
export function sanitize(dirty: string, options?: SanitizeOptions) {
  return DOMPurify.sanitize(dirty, { ...defaultSanitizeOptions, ...options });
}
