import { keyframes } from '@emotion/react';
import { Box, Tag, Icon } from '@everlywell/ui-kit';
import { CheckCircle, WarningCircle } from '@phosphor-icons/react';
import React, { useState } from 'react';

export interface CategoryPointer {
  angle: number;
  radius?: number;
  label: string;
  status: string;
}

export interface CategoriesPointersProps {
  pointers: CategoryPointer[];
  onPointerClick?: (label: string | null) => void;
}

const rippleKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
`;

const getPointerIcon = (status: string) => {
  const commonProps = {
    weight: 'fill',
    width: '16px',
    height: '16px',
  };

  const iconConfig =
    status === 'normal'
      ? { component: CheckCircle, color: 'viridian.light' }
      : { component: WarningCircle, color: 'terracotta.base' };

  return (
    <Icon as={iconConfig.component} color={iconConfig.color} {...commonProps} />
  );
};

const CategoriesPointers: React.FC<CategoriesPointersProps> = ({
  pointers,
  onPointerClick,
}) => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const handlePointerClick = (label: string) => {
    const newActiveCategory = activeCategory === label ? null : label;
    setActiveCategory(newActiveCategory);
    onPointerClick?.(newActiveCategory);
  };

  return (
    <>
      {pointers.map((pointer, index) => {
        const isActive = activeCategory === pointer.label;
        const icon = getPointerIcon(pointer.status);

        const hoverStyles = isActive
          ? {
              transform: `rotate(${pointer.angle}deg) translateY(${pointer.radius}px) scale(1.2)`,
            }
          : {
              transform: `rotate(${pointer.angle}deg) translateY(${pointer.radius}px) scale(1.2)`,
              animation: `${rippleKeyframes} 0.8s ease-out`,
            };

        return (
          <Box
            key={index}
            position="absolute"
            transformOrigin="center center"
            transform={`rotate(${pointer.angle}deg) translateY(${pointer.radius}px)`}
            width={isActive ? '32px' : '8px'}
            height={isActive ? '32px' : '8px'}
            borderRadius="50%"
            backgroundColor="tints.white"
            cursor="pointer"
            boxShadow={
              isActive ? undefined : '0 0 0 8px rgba(255, 255, 255, 0.2)'
            }
            display="flex"
            alignItems="center"
            justifyContent="center"
            transition="all 0.4s ease-in-out"
            _hover={hoverStyles}
            role="button"
            aria-label={`${pointer.label} pointer`}
            opacity={isActive || activeCategory === null ? 1 : 0.6}
            onClick={() => handlePointerClick(pointer.label)}
          >
            {isActive && (
              <Box display="inherit" transform={`rotate(${-pointer.angle}deg)`}>
                {icon}
              </Box>
            )}
          </Box>
        );
      })}

      {activeCategory && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          whiteSpace="nowrap"
        >
          <Tag
            backgroundColor="tints.white"
            color="viridian.dark"
            borderRadius="full"
            fontSize="md"
            textAlign="center"
            data-test="category-pointer-label"
          >
            {activeCategory}
          </Tag>
        </Box>
      )}
    </>
  );
};

export default CategoriesPointers;
