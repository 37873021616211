import { Box, Text } from '@everlywell/ui-kit';
import SpinningIcon from 'components/WellnessCircle/InnerCircle/SpinningIcon';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import CategoriesPointers, {
  CategoriesPointersProps,
} from './CategoriesPointers';
import WellnessScore from './WellnessScore';

export interface InnerCircleProps {
  wellnessScore?: number | null;
  categoryStatuses: string[];
  pointers: CategoriesPointersProps['pointers'];
  isLoading?: boolean;
}

const getGradientColor = (status: string) =>
  status === 'normal' ? 'rgba(0, 247, 132, 0.5)' : 'rgba(223, 43, 23, 0.5)'; // Green for normal, Red for abnormal

const defaultGradients = [
  { shape: 'circle farthest-corner', position: 'top right', size: '50%' },
  { shape: 'circle farthest-corner', position: 'top left', size: '50%' },
  { shape: 'circle farthest-corner', position: 'right center', size: '50%' },
  { shape: 'circle farthest-corner', position: 'bottom right', size: '50%' },
  { shape: 'circle farthest-corner', position: 'bottom left', size: '50%' },
  { shape: 'circle farthest-corner', position: 'left center', size: '50%' },
  {
    shape: 'ellipse',
    position: 'center',
    size: '80%',
    startColor: 'rgba(255,255,255,0.8)',
    endColor: 'rgba(255,255,255,0.4)',
  },
];

const InnerCircle: React.FC<InnerCircleProps> = ({
  wellnessScore,
  categoryStatuses,
  pointers,
  isLoading,
}) => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const handlePointerClick = (category: string | null) => {
    setActiveCategory(category);
  };

  const radialGradients = defaultGradients
    .map((gradient, index) => {
      const startColor =
        gradient.startColor || getGradientColor(categoryStatuses[index]);
      const endColor =
        gradient.endColor || startColor.replace(', 0.5)', ', 0)');
      const shape = gradient.shape ?? 'circle';
      const position = gradient.position ? ` at ${gradient.position}` : '';

      return `radial-gradient(${shape}${position}, ${startColor} 0%, ${endColor} ${gradient.size})`;
    })
    .join(',');

  const background = isLoading ? 'tints.darkCream' : radialGradients;

  return (
    <Box
      position="absolute"
      top="57%"
      left="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      transform="translate(-50%, -50%)"
      borderRadius="50%"
      width="240px"
      height="240px"
      zIndex="1"
    >
      <Box
        position="absolute"
        width="inherit"
        height="inherit"
        background={background}
        borderRadius="50%"
        filter="blur(5px)"
        zIndex="-2"
        transition="transform 0.4s ease-in-out, background 0.4s ease-in-out"
      />

      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              position: 'absolute',
            }}
          >
            <SpinningIcon />
            <Text fontSize="lg" color="tints.darkGrey">
              WellScore
            </Text>
          </motion.div>
        ) : (
          <motion.div
            key="loaded"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              position: 'absolute',
            }}
          >
            <CategoriesPointers
              pointers={pointers}
              onPointerClick={handlePointerClick}
            />
            {!activeCategory && <WellnessScore wellnessScore={wellnessScore} />}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default InnerCircle;
