import {
  Button as BaseButton,
  colors,
  H3,
  RadioButton,
  typography,
  size,
  mediaQueries,
} from '@everlywell/leaves';
import { BaseRadioButton } from 'components/KitRegistration/styles';
import styled from 'styled-components';

const Title = styled(H3)`
  font-weight: ${typography.weight.book};
  margin: ${size.xs1}px 0;
`;

const Subtitle = styled.p`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.book};
  color: ${colors.gray4};
  white-space: pre-line;
  margin-bottom: ${size.xl1}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${mediaQueries.forTabletVerticalDown} {
    flex-direction: column-reverse;
  }
`;

const Button = styled(BaseButton)`
  margin: 0 ${size.xs1}px;

  ${mediaQueries.forTabletVerticalDown} {
    margin: ${size.xs1}px 0;
  }
`;

const Address = styled.div`
  padding: ${size.xs1}px;
`;

const Selection = styled(RadioButton)``;

const AddressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${size.xs1}px;
  padding: ${size.md}px;
`;

const SuggestionsWrapper = styled.div`
  display: flex;
  margin-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletVerticalDown} {
    flex-direction: column;
  }
`;

const OptionWrapper = styled.div`
  width: 50%;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }
`;

const OptionTitle = styled.div`
  text-align: left;
  color: ${colors.teal4};
`;

interface AddressOptionProps {
  checked: boolean;
}

const AddressOption = styled(BaseRadioButton)<AddressOptionProps>`
  border: 1px solid;
  border-color: ${(props) => (props.checked ? colors.green4 : colors.green2)};
  border-radius: ${size.xs2}px;
  background-color: ${(props) => (props.checked ? '#f5faf7' : colors.white)};
  box-shadow: ${(props) =>
    props.checked
      ? `0 ${size.xs1}px ${size.xl1}px -${size.sm}px #dce4e5`
      : null};
  width: 95%;
  height: auto;
  margin: ${size.sm}px 0;

  ${mediaQueries.forTabletVerticalDown} {
    width: 100%;
  }

  label {
    height: auto;
    padding: ${size.sm}px ${size.xs1}px ${size.sm}px ${size.sm}px;
    &:after {
      margin-left: ${size.sm}px;
    }
  }
`;

export {
  Title,
  Subtitle,
  ButtonWrapper,
  Button,
  Address,
  Selection,
  AddressWrapper,
  OptionWrapper,
  SuggestionsWrapper,
  OptionTitle,
  AddressOption,
};
