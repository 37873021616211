// Query prams
export const LAB_VISIT_QP = 'lab_visit';

// External links
export const QUEST_SCHEDULE_LINK =
  'https://appointment.questdiagnostics.com/schedule-appointment/as-reason-for-visit?reason=PQD';

export const QUEST_FAQ_LINK =
  'https://support.everlywell.com/article/1865-in-person-lab-testing-with-quest';

export const QUEST_360_FAQ_LINK =
  'https://support.everlywell.com/article/1870-everlywell-360-membership';

export const EW360_CONCIERGE_PHONE_NUMBER =
  '8339985465'
