import {
  Row,
  Button,
  colors,
  H3,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

interface HeaderProps {
  hasCollectionMethods: boolean;
}

const Header = styled(H3)<HeaderProps>`
  color: ${colors.teal4};
  font-weight: ${typography.weight.book};
  font-size: 28px;
  margin-bottom: ${(props) =>
    props.hasCollectionMethods ? `${size.xs1}px` : `${size.xl2}px`};
  text-align: left;
  ${mediaQueries.forPhoneOnly} {
    font-size: 22px;
  }
`;
const Subtitle = styled.p`
  ${typography.bodyText}
  font-size: ${size.md}px;
  text-align: left;
  margin-bottom: 36px;
  ${mediaQueries.forPhoneOnly} {
    margin-bottom: 140px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${size.xl3}px;
  ${mediaQueries.forPhoneOnly} {
    margin-bottom: ${size.xs1}px;
  }
`;

const StyledImage = styled.img`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
  }
`;

const StyledRow = styled(Row)`
  margin-top: ${size.xl5}px;
`;

export { Header, Subtitle, StyledButton, StyledImage, StyledRow };
