import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Box,
  theme,
} from '@everlywell/ui-kit';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import React, { useState, useEffect } from 'react';

import CarePlanInfoCard, {
  CarePlanInfoCardProps,
} from '../CarePlanInfoCard/CarePlanInfoCard';

export type CarePlanAccordionProps = {
  items: CarePlanInfoCardProps[] & { onClick?: () => void };
  simplified?: boolean;
};

const CarePlanAccordion = ({
  items,
  simplified = false,
}: CarePlanAccordionProps) => {
  const hasSingleItem = (items?.length ?? 0) === 1;
  const hasLessThenThreeItems = (items?.length ?? 0) <= 3;
  const defaultIndex = hasLessThenThreeItems || simplified ? [0] : [];
  const [expandedIndex, setExpandedIndex] = useState<number | number[]>(
    defaultIndex,
  );

  useEffect(() => {
    // Track default expanded section on initial page load
    if (defaultIndex.length > 0) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.ATHENA_CARE_PLAN.OPEN_ACCORDION,
          section: items[0]?.sectionName,
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (index: number | number[]) => {
    // Ensure we're ONLY tracking when user expands a section
    const previousIndex = expandedIndex;
    setExpandedIndex(index);

    // Track when user expands a new section
    if (typeof index === 'number' && index !== previousIndex) {
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: ANALYTICS.LABELS.ATHENA_CARE_PLAN.OPEN_ACCORDION,
          section: items[index].sectionName,
        },
      });
    }
  };

  return (
    <Accordion
      allowToggle={!hasSingleItem}
      defaultIndex={defaultIndex}
      onChange={handleChange}
    >
      <Flex direction="column" gap={[2, !simplified ? 4 : 2]}>
        {items.map((item, index) => {
          const isExpanded = Array.isArray(expandedIndex)
            ? expandedIndex.includes(index)
            : expandedIndex === index;

          return (
            <AccordionItem key={index} border={0}>
              <Box
                boxShadow={isExpanded ? `${theme.shadows.tooltip}` : 'none'}
                transition="box-shadow 0.2s ease-in-out"
                borderRadius="2xl"
                backgroundColor="tints.white"
              >
                <CarePlanInfoCard
                  simplified={simplified}
                  {...item}
                  headerWrapper={{
                    component: AccordionButton,
                    props: { padding: 0 },
                  }}
                  bodyWrapper={{
                    component: AccordionPanel,
                    props: { padding: 0 },
                  }}
                />
              </Box>
            </AccordionItem>
          );
        })}
      </Flex>
    </Accordion>
  );
};

export default CarePlanAccordion;
