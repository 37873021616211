import {
  colors,
  mediaQueries,
  typography,
  Button as LeavesButton,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const CardHeader = styled.h1`
  text-align: center;
  ${typography.h3Text};
  margin: 0;
`;

export const Text = styled.p`
  ${typography.bodyText};
  color: ${colors.gray5};
  text-align: center;
  margin: 0;

  ${mediaQueries.forTabletVerticalUp} {
    color: ${colors.gray4};
  }
`;

export const CustomLink = styled.a`
  text-decoration: none;
  font-weight: ${typography.weight.bold};
  color: ${colors.green4};
`;

export const Button = styled(LeavesButton)`
  width: 100%;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 350px;
  }
`;
