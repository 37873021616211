import {
  theme,
  Box,
  Container,
  Flex,
  Skeleton,
  SkeletonCircle,
} from '@everlywell/ui-kit';
import Layout from 'components/Layout';
import React from 'react';

const KitResultCarePlanSkeleton = () => (
  <Layout>
    <Box width="100%" backgroundColor="tints.cream" padding="6">
      <SkeletonCircle width="48px" height="48px" marginBottom="64" />
      <Container marginLeft="auto" marginRight="auto">
        <Flex
          justifyContent="center"
          alignItems="center"
          marginRight="auto"
          marginLeft="auto"
          maxWidth="640px"
          gap="10"
          direction="column"
        >
          <Skeleton width="100%" maxWidth="250px" height="40px" />
          <Flex direction="column" gap="2" width="100%">
            {Array.from({ length: 6 }, (_, i) => i).map((_, index) => (
              <Skeleton key={index} width="100%" height="62px" />
            ))}
          </Flex>
          <Skeleton width="100%" height="170px" />
          <Skeleton width="100%" maxWidth="200px" height="30px" />
          <Flex wrap="wrap" gap="2" width="100%">
            {Array.from({ length: 4 }, (_, i) => i).map((_, index) => (
              <Skeleton
                key={index}
                width={`calc(50% - ${theme.space[2]})`}
                height="300px"
              />
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  </Layout>
);

export default KitResultCarePlanSkeleton;
