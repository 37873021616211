import { TelehealthIntakeForm } from 'common/apis/telehealthApis';
import useProgramType from 'common/hooks/useProgramType';
import { last } from 'lodash';
import { NATIVE_SCHEDULING_PATH } from 'pages/VirtualCare/NativeScheduling';
import React, { createContext, ReactNode, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getRelativePath } from '../utils/helpers';

type ContextProps = {
  currentProgramSlug: string;
  currentStep: string;
  goToNextStep: () => void;
  nextStep: string;
};

type Form = TelehealthIntakeForm & {
  pathname: string;
  component: ReactNode | (() => ReactNode);
};

export type NativeIntakeContextProviderProps = {
  children: ReactNode;
  forms: Form[];
  program: string;
};

export const NativeIntakeContext = createContext<ContextProps>({
  currentStep: '',
  goToNextStep: () => {},
  currentProgramSlug: '',
  nextStep: '',
});

export const redirectAfterLastStep = (
  programType: string,
  navigate: ReturnType<typeof useNavigate>,
) => {
  if (programType === 'on_demand') {
    navigate(getRelativePath('on-demand/confirmation?book_appointment=true'));
    return;
  } else if (programType === 'async') {
    navigate(getRelativePath('review'));
    return;
  }

  // defaultProgram programType === 'scheduled'
  navigate(NATIVE_SCHEDULING_PATH);
};

export const NativeIntakeContextProvider = ({
  children,
  forms,
  program,
}: NativeIntakeContextProviderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const programType = useProgramType(program);

  const steps = forms.map((form) => form.slug);

  const getFormPath = (slug: string) => {
    const foundForm = forms.find((form) => form.slug === slug);

    if (!foundForm) return '';

    return getRelativePath(foundForm?.pathname);
  };

  const getCurrentStep = () => {
    if (
      location.pathname.match(getRelativePath('insurance')) ||
      location.pathname.match(getRelativePath('/insurance/add'))
    ) {
      return 'insurance';
    }
    return steps.find((step) => getFormPath(step) === location.pathname) ?? '';
  };

  const currentStep = getCurrentStep();

  const getNextStep = (currentStep: string) => {
    const currentIndex = steps.indexOf(currentStep);

    return steps[currentIndex + 1];
  };
  const nextStep = getNextStep(currentStep);

  const goToNextStep = () => {
    if (currentStep === last(steps)) {
      redirectAfterLastStep(programType, navigate);
      return;
    }

    const nextStep = getNextStep(currentStep);

    if (nextStep === undefined) return;

    navigate(getFormPath(nextStep));
  };

  return (
    <NativeIntakeContext.Provider
      value={{
        nextStep,
        goToNextStep,
        currentStep,
        currentProgramSlug: program,
      }}
    >
      {children}
    </NativeIntakeContext.Provider>
  );
};

export const useNativeIntakeContext = () => useContext(NativeIntakeContext);
