import { Box, Flex, Text, theme } from '@everlywell/ui-kit';
import React from 'react';
import { Link } from 'react-router-dom';

export type ActionCardProps = {
  label: string;
  href?: string;
  onClick?: () => void;
  action?: React.ReactNode;
  asset?: React.ReactNode;
};

const ActionCard: React.FC<ActionCardProps> = (props) => {
  const { asset, action, href, onClick, label } = props;

  const Element = () => (
    <Box
      role={`${onClick ? 'button' : 'presentation'}`}
      width="100%"
      padding="3"
      backgroundColor="tints.white"
      borderRadius="12"
      cursor={onClick || href ? 'pointer' : 'auto'}
      onClick={onClick && !href ? onClick : () => {}}
      maxWidth="720px"
      border="1px solid transparent"
      _hover={{
        borderColor: 'rgba(0, 0, 0, 0.05)',
        boxShadow: theme.shadows.tooltip,
      }}
    >
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex direction="row" alignItems="center" gap="2">
          {asset && asset}
          <Text size="lg" color="tints.black" fontWeight="medium">
            {label}
          </Text>
        </Flex>
        {action && action}
      </Flex>
    </Box>
  );

  if (href) {
    return (
      <Box maxWidth="720px" width="100%">
        <Link to={href} role="link">
          <Element />
        </Link>
      </Box>
    );
  }
  return <Element />;
};

export default ActionCard;
