import { Box } from '@everlywell/ui-kit';
import React from 'react';

import OuterCircleTooltip from './OuterCircleTooltip';

export interface OuterCircleProps {
  outerRadius?: number;
  markersCount?: number;
  markersWidth?: string;
  markersHeight?: string;
  markers: {
    status: 'normal' | 'abnormal' | 'dnr';
    name?: string;
  }[];
  isLoading?: boolean;
}

/**
 * Renders the outer circle of the WellnessCircle.
 * It receives an array of markers, which are rendered as colored lines around the circle.
 * Each biomarker is positioned at a different angle around the circle.
 * The color of the line is green if the biomarker is normal, and red if it is abnormal.
 * When hovering over a biomarker, a tooltip is displayed with the name and color of the biomarker.
 * For now, some variables are hardcoded, such as the number of markers, the outer radius, the width and height of the markers.
 * If we require more flexibility in the future, we can add props for these variables.
 */

const MARKERS_COLORS = {
  normal: 'viridian.lighter',
  abnormal: 'terracotta.base',
  dnr: 'tints.darkCream',
};

const LOADING_COLOR = 'tints.medGrey';

const OuterCircle: React.FC<OuterCircleProps> = ({
  markersCount = 88,
  outerRadius = 140,
  markersWidth = '2px',
  markersHeight = '22px',
  markers,
  isLoading,
}) => (
  <Box width="inherit" height="inherit">
    {markers.map((marker, index) => {
      const rotate = (360 / markersCount) * index; // define the angle for each marker
      const color = isLoading ? LOADING_COLOR : MARKERS_COLORS[marker.status]; // green if normal, red if abnormal, grey if DNR or loading

      return (
        <OuterCircleTooltip
          key={index}
          markerName={marker.name ?? ''}
          markersHeight={markersHeight}
          markersWidth={markersWidth}
          rotate={rotate}
          outerRadius={outerRadius}
          color={color}
          isLoading={isLoading}
        />
      );
    })}
  </Box>
);

export default OuterCircle;
