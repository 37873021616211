import { REGISTRATION_CONFIRMATION as DATA_TEST } from 'common/utils/constants/dataTest';
import { BaseModal } from 'components/KitRegistration/styles';
import React, { ReactElement } from 'react';

import * as S from './styles';

export interface ConfirmationErrorModalProps {
  openModal: boolean;
  setOpenModal: (cta: string) => void;
  onClose?: () => void;
  title: string;
  message: string | ReactElement;
  cta: string;
  barcodeSerialNumber?: string;
}

const ConfirmationErrorModal = ({
  openModal,
  setOpenModal,
  title,
  message,
  cta,
  onClose,
  barcodeSerialNumber,
}: ConfirmationErrorModalProps) => (
  <BaseModal
    open={openModal}
    openHandler={onClose || setOpenModal}
    data-test={`${DATA_TEST.MODAL}-error`}
  >
    <S.Title>{title}</S.Title>
    <S.Subtitle>{message}</S.Subtitle>
    <S.Accept
      appearance="primary"
      onClick={() => setOpenModal(barcodeSerialNumber || cta)}
      data-test={`${DATA_TEST.BUTTON}-error`}
    >
      {cta}
    </S.Accept>
  </BaseModal>
);
export default ConfirmationErrorModal;
