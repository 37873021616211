import {
  H3,
  typography,
  size,
  colors,
  mediaQueries,
  breakpoints,
} from '@everlywell/leaves';
import { bodyCopyLarge, CardContainer } from 'common/styles/app-styles';
import styled from 'styled-components';

export const Container = styled(CardContainer as any)`
  display: flex;
  padding: ${size.lg}px;
  border-top: 4px solid ${colors.teal4};
  flex-direction: column-reverse;
  text-align: center;
  align-items: center;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    text-align: initial;
    padding: ${size.xl3}px;
    align-items: flex-start;
  }

  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopDown}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Heading = styled(H3)`
  margin: 0;
  margin-bottom: ${size.lg}px;
  font-weight: ${typography.weight.book};
`;

export const Body = styled.span`
  ${bodyCopyLarge}
  font-weight: ${typography.weight.light};

  a {
    font-weight: ${typography.weight.bold};
    text-decoration: none;
  }
`;

export const Image = styled.img`
  max-height: 48px;
  max-width: 48px;
  margin-bottom: ${size.lg}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
    margin-left: ${size.xl5}px;
  }
`;
