import { Button } from '@everlywell/leaves';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { User } from 'common/utils/types';
import { isUserMemberOfProgram } from 'common/utils/user';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import { shouldRestartHealthProfile } from 'components/HealthProfile/helpers';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import React from 'react';
import { Link } from 'react-router-dom';

import BadgeIcon from './components/BadgeIcon';
import ProgressCircle from './components/ProgressCircle';

export interface HealthProfileCardProps {
  progress: number;
  unansweredQuestions: number;
  user: User;
  lastInteraction?: string | null;
}

const HealthProfileCard = ({
  progress,
  unansweredQuestions,
  user,
  lastInteraction,
}: HealthProfileCardProps) => {
  const bannerViewed = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_HUB.HEALTH_PROFILE_CARD,
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
      },
    });
  });

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        label: seen
          ? ANALYTICS.LABELS.ACCOUNT_HUB.FINISH_HEALTH_PROFILE
          : ANALYTICS.LABELS.ACCOUNT_HUB.START_HEALTH_PROFILE,
      },
    });
  };

  const isEw360Member = user && isUserMemberOfProgram(user, ['health360']);
  const shouldRestart = shouldRestartHealthProfile(user, lastInteraction);
  const seen = progress > 0;

  let headline, text;
  switch (true) {
    case progress === 0:
      headline = isEw360Member
        ? 'Start your Everlywell 360 intake'
        : 'Start your health profile';
      text = isEw360Member
        ? 'Answer these questions to receive your personalized care plan and WellScore with your results.'
        : 'Answer some questions for personalized data and insights.';
      break;
    case progress === 100:
      headline = 'You did it!';
      text = isEw360Member
        ? 'Thanks for completing your Everlywell 360 intake. We are creating a personalized care plan and WellScore to be shared with your results.'
        : 'Thanks for completing your health profile and helping us work on making your experience better.';
      break;
    default:
      if (shouldRestart) {
        headline = 'Start your Everlywell 360 intake';
        text =
          'Answer these questions to receive your personalized care plan and WellScore with your results.';
      } else {
        headline = isEw360Member
          ? 'Complete your Everlywell 360 intake'
          : 'Complete your health profile';
        text = isEw360Member
          ? `Please complete the remaining ${unansweredQuestions} ${
              unansweredQuestions === 1 ? 'question' : 'questions'
            } to receive your WellScore and personal insights in your Care Plan.`
          : `Answer ${unansweredQuestions} more ${
              unansweredQuestions === 1 ? 'question' : 'questions'
            } for more personalized data.`;
      }
      break;
  }

  return (
    <BaseCard
      ref={bannerViewed}
      image={
        <>
          {progress < 100 && (
            <BaseCardIcon
              as={ProgressCircle}
              progress={shouldRestart ? 0 : progress ?? 0}
            />
          )}
          {progress >= 100 && <BaseCardIcon as={BadgeIcon} />}
        </>
      }
      content={{
        title: headline,
        description: text,
      }}
      cta={
        progress < 100 && (
          <Link
            to={`/health-profile/${shouldRestart ? '' : '?unanswered=true'}`}
            state={{
              user,
              unansweredParam: shouldRestart ? 'false' : 'true',
              lastInteraction: shouldRestart ? null : lastInteraction,
            }}
          >
            <Button appearance="secondary" hasArrow onClick={handleClick}>
              {shouldRestart || !seen ? 'Get started' : 'Finish'}
            </Button>
          </Link>
        )
      }
    />
  );
};

export default HealthProfileCard;
