import {
  size,
  colors,
  typography,
  Button,
  mediaQueries,
  H1,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: ${size.xs1}px 0 0;
  margin: 0 0 ${size.xl1}px;
  background: ${colors.white};
  min-height: 112px;
  font-weight: ${typography.weight.book};

  ${mediaQueries.forTabletVerticalUp} {
    width: 100%;
    margin: 0;
    padding: 0 0 ${size.xl3}px;
    align-items: flex-start;
    min-height: auto;
  }
`;

export const Heading = styled(H1)`
  ${typography.h2Text};
  font-weight: ${typography.weight.book};
  color: ${colors.green4};
  text-align: center;
  margin: ${size.md}px 0 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 0;
    text-align: left;
    color: ${colors.teal4};
  }

  span {
    ${typography.subtitle}
    display: block;
    text-align: center;
    color: ${colors.gray4};
    font-weight: ${typography.weight.book};

    ${mediaQueries.forTabletVerticalUp} {
      text-align: left;
    }
  }
`;

export const CTA = styled(Button)`
  color: ${colors.white};
`;
