import { breakpoints } from '@everlywell/leaves';
import { Box, Flex, Text, Heading, Icon } from '@everlywell/ui-kit';
import {
  ForkKnife,
  CirclesThreePlus,
  PersonSimpleWalk,
  Sun,
} from '@phosphor-icons/react';
import useMediaQuery from 'common/hooks/useMediaQuery';
import { sanitize } from 'common/utils/domHelpers';
import parse from 'html-react-parser';
import React from 'react';

export type RecommendationsProps = {
  data: RecommendationSection[];
};

export type RecommendationSection = {
  sectionName: string;
  iconName: IconName;
  description: string;
  color: string;
  isLoading?: boolean;
  bgColor: string;
};

type IconName = 'ForkKnife' | 'CirclesThreePlus' | 'PersonSimpleWalk' | 'Sun';

const IconComponents = {
  ForkKnife: ForkKnife,
  CirclesThreePlus: CirclesThreePlus,
  PersonSimpleWalk: PersonSimpleWalk,
  Sun: Sun,
};

function Recommendations(props: RecommendationsProps) {
  const { data } = props;
  const isMobile = useMediaQuery(
    `(max-width: ${breakpoints.forTabletVerticalUp}px)`,
  );
  const formatRecommendation = (html: string) =>
    html ? parse(sanitize(html)) : '';

  return (
    <Box
      backgroundColor="transparent"
      border="none"
      overflow="hidden"
      marginRight={['-1.5em', '0px']}
      marginLeft={['-1.5em', '0px']}
    >
      <Box>
        <Heading
          as="h2"
          size="md"
          textAlign="center"
          color="tints.black"
          marginBottom="10"
        >
          Recommendations
        </Heading>
      </Box>

      <Flex
        flexWrap={isMobile ? 'nowrap' : 'wrap'}
        gap="2"
        overflowX="auto"
        maxWidth={isMobile ? 'calc(100vw)' : '100%'}
        justifyContent="start"
      >
        {data.map((recommendation, index) => {
          const IconComponent = IconComponents[recommendation.iconName];
          return (
            <Box
              key={`recommendation-${index}`}
              borderRadius="2xl"
              backgroundColor="tints.white"
              as="header"
              minWidth={isMobile ? '280px' : 'calc(50% - .25em)'}
              width={isMobile ? '280px' : 'calc(50% - .25em)'}
              marginLeft={isMobile && index === 0 ? '1.5em' : 0}
              marginRight={isMobile && index === data.length - 1 ? '1.5em' : 0}
              padding="2"
            >
              <Flex
                direction="column"
                gap="3"
                padding="2"
                backgroundColor={recommendation.bgColor}
                borderRadius="lg"
              >
                <Icon
                  as={IconComponent}
                  size="md"
                  fill={recommendation.color}
                  weight="regular"
                  role="img"
                  aria-label={`${recommendation.iconName} icon`}
                />
                <Text size="lg" color="tints.black" fontWeight="medium">
                  {recommendation.sectionName}
                </Text>
              </Flex>
              <Text
                fontSize={['sm', 'md']}
                color="tints.darkGrey"
                paddingY={1}
                paddingRight={2}
                paddingLeft={4}
                marginTop="2"
              >
                {formatRecommendation(recommendation.description)}
              </Text>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}

export default Recommendations;
