import { Button, H3, mediaQueries, Row, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Btn = styled(Button)`
  &:hover {
    text-decoration: none;
  }
`;

const Title = styled(H3)`
  font-weight: ${typography.weight.book};
`;

const Wrapper = styled(Row)`
  padding: 60px 0;
  box-sizing: border-box;

  ${mediaQueries.forPhoneOnly} {
    padding: 30px 0;
  }
`;

export { Btn, Title, Wrapper };
