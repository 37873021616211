import {
  KitResultCarePlan,
  KitResultCarePlanRecommendations,
  KitResultCarePlanRecommendationTypes,
  KitResultCarePlanSections,
  KitResultCarePlanSectionTypes,
  getWellnessCarePlan,
} from 'common/apis/telehealthApis';
import { useQuery } from 'react-query';

import {
  CarePlanRecommendationTypes,
  CarePlanSectionTypes,
} from '../../../pages/KitResultCarePlanPage/constants';

/**
 * Custom hook to fetch and process care plan data for given results.
 *
 * @param {string} program - The program slug used to filter care plan data.
 * @returns {Object} An object containing the care plan details:
 * - `summary` {string} - The clinician summary information from the care plan.
 * - `sections` {Object} - The filtered sections from the care plan.
 * - `date` {string} - The locked_at date from the care plan used in the summary component.
 * - `recommendations` {Object} - The filtered recommendations from the care plan.
 * - `isLoading` {boolean} - Indicates if the data is currently being loaded or fetched.
 * - `isError` {boolean} - Indicates if there was an error fetching the data.
 * - `error` {Object} - The error object if an error occurred.
 *
 * @description
 * This hook uses the `useQuery` hook from `react-query` to fetch the care plan data
 * for a given program. It processes the response to extract relevant information
 * such as summary, sections, and recommendations. The sections and recommendations
 * are filtered to exclude any empty content.
 *
 * The `filteredSections` object is created by iterating over the `care plan sections`
 * and including only those sections that have non-empty content. This ensures that the
 * returned sections object contains only meaningful data.
 * The `filteredRecommendations` object is created by iterating over the `care plan recommendations`
 * and including only those recommendations that have non-empty content. This ensures that the
 * returned recommendations object contains only meaningful data.
 *
 */
const useKitResultCarePlan = (program?: string) => {
  const {
    data: response,
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(
    ['telehealth-wellness-care-plan', program],
    () => getWellnessCarePlan(program),
    {
      refetchOnWindowFocus: false,
    },
  );

  const carePlan = (response?.data?.care_plans?.[0] as KitResultCarePlan) ?? [];
  const summary = carePlan?.summary ?? '';
  const recommendations =
    (carePlan?.recommend as KitResultCarePlanRecommendations) ?? [];

  // Get Care Plan sections from carePlan response
  const filteredSections =
    carePlan &&
    Object.keys(carePlan).reduce((acc, key) => {
      const type = key as KitResultCarePlanSectionTypes;
      // Filters only care plan sections and check for content
      if (
        CarePlanSectionTypes.includes(type) &&
        ((carePlan[type].actions && carePlan[type].actions.length > 0) ||
          (carePlan[type].insights && carePlan[type].insights.length > 0))
      ) {
        acc[type] = carePlan[type];
      }
      return acc;
    }, {} as KitResultCarePlanSections);

  // Get Care Plan recommendations from carePlan response
  const filteredRecommendations =
    carePlan &&
    recommendations &&
    Object.entries(recommendations).reduce((acc, [key, value]) => {
      const type = key as KitResultCarePlanRecommendationTypes;
      // Filters only care plan recommendations and check for content
      if (
        CarePlanRecommendationTypes.includes(type) &&
        value &&
        value?.length > 0
      ) {
        acc[type] = value;
      }
      return acc;
    }, {} as KitResultCarePlanRecommendations);

  // Check if care plan data is ready
  const isReady =
    (!isLoading || !isFetching) && carePlan && Object.keys(carePlan).length > 0;

  return {
    summary,
    isError,
    error,
    isReady,
    sections: filteredSections,
    date: carePlan?.locked_at,
    recommendations: filteredRecommendations,
    isLoading: isLoading || isFetching,
  };
};

export default useKitResultCarePlan;
