import { isWcAsyncScheduleEnabled } from 'common/utils/flags';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import React from 'react';

import PhoneIcon from './assets/PhoneIcon.svg';
import * as S from './FollowUpVisitCard.styles';

export const HEALTHIE_PHONE_NUMBER = '(708)-787-4438';
export const CONTACT_DAY_TIME_TEXT = ' (7 a.m.-7 p.m. CST) ';
export const WC_CONTACT_EMAIL_ADDRESS = 'wcsupport@everlywell.com';
export const CONTACT_EMAIL_ADDRESS = 'patientsupport@openloophealth.com';

export const CARD_CONTENT = {
  FIRST_TWO_APPOINTMENTS: {
    HEADER_TEXT: 'Schedule an appointment',
    CONTACT_TEXT:
      "Have questions? From appointments, to labs, all the way to prescription support, we're here to partner with you each step of the way. Contact support at ",
    CONTACT_TEXT_2: 'or email us at ',
    CONTACT_TEXT_3: ' for program assistance.',
  },
  DEFAULT: {
    HEADER_TEXT: 'Get a follow-up visit',
    CONTACT_TEXT:
      "Have questions? From appointments, to labs, all the way to prescription support, we're here to partner with you each step of the way. Contact support at ",
    CONTACT_TEXT_2: 'or email us at ',
    CONTACT_TEXT_3: ' for program assistance.',
  },
  ASYNC: {
    HEADER_TEXT: 'Refill your prescription',
    CONTACT_TEXT:
      "Refill your prescription without having to meet with a provider! Have questions? From appointments, to labs, all the way to prescription support, we're here to partner with you each step of the way. Contact support at ",
    CONTACT_TEXT_2: 'or email us at ',
    CONTACT_TEXT_3: ' for program assistance.',
  },
  QUEST_WC_NO_APPOINTMENTS: {
    HEADER_TEXT: 'Schedule an appointment',
    CONTACT_TEXT:
      "Have questions? From appointments, to labs, all the way to prescription support, we're here to partner with you each step of the way.",
    CONTACT_TEXT_2: ' Email us at ',
    CONTACT_TEXT_3: ' for program assistance.',
    hidePhone: true,
  },
  QUEST_WC: {
    HEADER_TEXT: 'Refill your prescription',
    CONTACT_TEXT:
      "Refill your prescription without having to meet with a provider! Have questions? From appointments, to labs, all the way to prescription support, we're here to partner with you each step of the way.",
    CONTACT_TEXT_2: ' Email us at ',
    CONTACT_TEXT_3: ' for program assistance.',
    hidePhone: true,
  },
};

type FollowUpVisitCardProps = {
  extraButton?: React.ReactNode;
  extraLink?: React.ReactNode;
  hasAsync?: boolean;
  isWcPlusMember?: boolean;
  completedWCAppointmentsAmount?: number;
  isQuestWcMember?: boolean;
};

function FollowUpVisitCard({
  extraButton,
  extraLink,
  hasAsync,
  isWcPlusMember,
  completedWCAppointmentsAmount,
  isQuestWcMember,
}: FollowUpVisitCardProps) {
  let hidePhone = false;

  const getContent = () => {
    if (
      completedWCAppointmentsAmount !== undefined &&
      completedWCAppointmentsAmount <= 1 &&
      !isQuestWcMember
    ) {
      return CARD_CONTENT.FIRST_TWO_APPOINTMENTS;
    }

    if (isQuestWcMember) {
      hidePhone = true;
      if (
        completedWCAppointmentsAmount !== undefined &&
        completedWCAppointmentsAmount >= 1
      ) {
        return CARD_CONTENT.QUEST_WC;
      } else {
        return CARD_CONTENT.QUEST_WC_NO_APPOINTMENTS;
      }
    }

    if (hasAsync && isWcAsyncScheduleEnabled()) {
      return CARD_CONTENT.ASYNC;
    }

    return CARD_CONTENT.DEFAULT;
  };

  const CONTENT = getContent();

  const EMAIL_ADDRESS = isWcPlusMember
    ? WC_CONTACT_EMAIL_ADDRESS
    : CONTACT_EMAIL_ADDRESS;

  return (
    <BaseCard
      image={<BaseCardIcon src={PhoneIcon} alt="" />}
      content={{
        title: CONTENT.HEADER_TEXT,
        description: (
          <>
            {CONTENT.CONTACT_TEXT}
            {!hidePhone ? (
              <>
                <S.Link href={`tel:${HEALTHIE_PHONE_NUMBER}`}>
                  {HEALTHIE_PHONE_NUMBER}
                </S.Link>
                {CONTACT_DAY_TIME_TEXT}
              </>
            ) : null}
            {CONTENT.CONTACT_TEXT_2}
            <S.Link href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</S.Link>
            {CONTENT.CONTACT_TEXT_3}

            {extraButton ? (
              <S.ButtonWrapper>{extraButton}</S.ButtonWrapper>
            ) : null}
            {extraLink ? <S.LinkWrapper>{extraLink}</S.LinkWrapper> : null}
          </>
        ),
      }}
    />
  );
}

export default FollowUpVisitCard;
