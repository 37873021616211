import {
  Col,
  mediaQueries,
  Button,
  H3,
  size,
  typography,
  colors,
  Accordion,
  Icons,
  Row,
} from '@everlywell/leaves';
import styled from 'styled-components';

const SkeletonContainer = styled(Row)`
  margin-top: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    min-width: 532px;
  }
`;
const SkeletonWrapper = styled.span`
  margin-bottom: ${size.md}px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const BoxImageContainer = styled.div`
  height: 176px;
  width: 176px;
  background-color: ${colors.teal1};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 40px;

  ${mediaQueries.forDesktopUp} {
    margin-right: -${size.xl6}px;
  }

  ${mediaQueries.forPhoneOnly} {
    height: ${size.xl7}px;
    width: ${size.xl7}px;
  }
`;
const BoxImage = styled.img`
  width: 100%;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
`;

const ImageCol = styled(Col)`
  display: flex;
  justify-content: center;

  ${mediaQueries.forDesktopUp} {
    padding-right: 25%;
  }
`;

const CheckMark = styled(Icons.CheckMark)`
  background-color: ${colors.green4};
  border-radius: 50%;
  padding: 6px;
  color: white;
  width: 38px;
  height: 38px;
  margin-top: -${size.xs2}%;

  ${mediaQueries.forDesktopUp} {
    margin-left: 26%;
  }

  ${mediaQueries.forPhoneOnly} {
    width: 26px;
    height: 26px;
    padding: ${size.xs2}px ${size.xs2}px ${size.xs2}px ${size.xs2}px;
  }
`;

const Header = styled(H3)`
  margin: 40px 0 ${size.md}px 0;
  font-weight: ${typography.weight.book};
  font-size: 23px;
  text-align: center;
`;
const Subtitle = styled.p`
  ${typography.bodyText}
  font-size: 18px;
  font-weight: ${typography.weight.light};
  margin-bottom: 40px;
  text-align: center;
`;

const ContinueButton = styled(Button)`
  width: 100%;
  margin-top: 40px;
`;

const AccordionWrapper = styled(Accordion.Wrapper)`
  max-width: 532px;
`;

const AccordionHeading = styled(Accordion.Heading)`
  font-weight: ${typography.weight.bold};
  font-size: 18px;
  padding: ${size.md}px ${size.lg}px ${size.md}px ${size.lg}px;
`;

const AccordionItem = styled(Accordion.Item)`
  border-top: 1px solid ${colors.teal2};
  height: auto;
  color: ${colors.green5};
  + div {
    border-bottom-color: ${colors.teal2};
  }
`;

const AccordionChild = styled(Accordion.Child)`
  border-top: none;
  padding-bottom: 0;
  ${mediaQueries.forPhoneOnly} {
    min-height: 158px;
  }
`;

const AccordionText = styled.p`
  ${typography.bodyText}
  font-size: 18px;
  padding: 0 ${size.lg}px ${size.lg}px ${size.lg}px;
  ${mediaQueries.forPhoneOnly} {
    font-size: ${size.md}px;
  }
`;

const Link = styled.a`
  color: ${colors.green5};
  font-weight: ${typography.weight.bold};
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: ${colors.green5};
  }
`;

export {
  AccordionChild,
  AccordionHeading,
  AccordionItem,
  AccordionText,
  AccordionWrapper,
  BoxImage,
  BoxImageContainer,
  CheckMark,
  ContinueButton,
  Header,
  ImageCol,
  Link,
  SkeletonContainer,
  SkeletonWrapper,
  Subtitle,
};
