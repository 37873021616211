import {
  colors,
  mediaQueries,
  typography,
  TertiaryLink,
} from '@everlywell/leaves';
import { bodyCopy, bodyCopyLarge } from 'common/styles/app-styles';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

type Props = {
  backgroundSrc: string;
};

const Link = styled(TertiaryLink)``;

const Wrapper = styled.div<Props>`
  /* prettier-ignore */
  background-image: url("${(props) => props.backgroundSrc}");
  padding: 120px 32px 32px;
  background-position: center 32px;
  background-repeat: no-repeat;
`;

const Title = styled.h3`
  ${bodyCopy};
  color: ${colors.gray5};
  font-weight: ${typography.weight.book};
  text-align: left;
  margin: 0 0 8px;
`;

const Description = styled.div`
  ${bodyCopy};
  color: ${colors.gray5};
  font-weight: ${typography.weight.light};
  line-height: 30px;

  p:not(:last-child),
  li:not(:last-child) {
    margin-bottom: 8px;
  }

  ${Link}

  ${mediaQueries.forTabletVerticalUp} {
    ${bodyCopyLarge};
    color: ${colors.gray5};
  }
`;

const Gradient = styled.div`
  display: block;
  height: 80px;
  width: calc(100% - 2px);
  position: absolute;
  bottom: 1px;
  left: 1px;
  background: linear-gradient(
    0deg,
    rgba(${hexToRGB(colors.white)}, 1) 10%,
    rgba(${hexToRGB(colors.white)}, 0) 100%
  );
`;

export { Wrapper, Title, Description, Gradient };
