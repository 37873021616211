import { Grid } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import {
  Box,
  Flex,
  Container as UIKitContainer,
  Skeleton,
} from '@everlywell/ui-kit';
import { CaretRight, ClipboardText, Spinner } from '@phosphor-icons/react';
import useApi from 'common/hooks/useApi';
import useKitResultCarePlan from 'common/hooks/useKitResultCarePlan';
import useWellnessScore from 'common/hooks/useWellnessScore';
import analytics from 'common/utils/analytics';
import {
  FS_PERSONALIZATION_CONDITIONS,
  LOCALIZE_SKUS,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { KIT_RESULT_DETAIL } from 'common/utils/constants/dataTest';
import { TAKE_ACTION_ORDER_OVERRIDE } from 'common/utils/constants/features';
import { logError } from 'common/utils/helpers';
import {
  groupBySeverity,
  groupFSMarkersBySeverity,
} from 'common/utils/resultGenerator';
import {
  FactCardValues,
  HealthProfileProps,
  Personalizations,
} from 'common/utils/types';
import ActionCard, { CardIcon } from 'components/ActionCard';
import CategoryCard from 'components/CategoryCard';
import WellnessCircle from 'components/WellnessCircle/WellnessCircle';
import {
  DispatchProps,
  StateProps,
} from 'containers/KitResultDetailPageContainer';
import ResultsIntro from 'containers/ResultsIntro';
import SubNavbar from 'pages/KitResultDetailPage/SubNavbar';
import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import Container from './Container';
import Header from './Header';
import { normalizeWellnessCircleCategories, groupCategories } from './utils';

export type KitResultDetailPage360Props = StateProps & DispatchProps;

const KitResultDetailPage360 = (props: KitResultDetailPage360Props) => {
  const {
    contentToken,
    kitResultIdOrPublishHash,
    kitResult,
    getKitResult,
    testId,
    viewingSharedKitResult,
    sku,
  } = props;
  const { wellnessScore, categories, isLoading } = useWellnessScore(
    kitResult?.barcode_serial_number ?? '',
  );

  const { isReady: isCarePlanReady, isLoading: isCarePlanLoading } =
    useKitResultCarePlan();

  // Personalizations
  const { getPersonalizations } = useApi();
  const [personalizations, setPersonalizations] = useState<HealthProfileProps>({
    fact_cards: [] as FactCardValues[],
    food_sensitivity: [] as Array<string>,
    pcp: null,
  });

  // load personalizations
  useEffectOnce(() => {
    const loadPersonalizations = async () => {
      try {
        const personalizations: Personalizations = await getPersonalizations();
        setPersonalizations(personalizations.health_profile);
      } catch (err) {
        logError((err as Error).message, {
          errorInfo: 'Error fetchGraphql fetching Erroring personalizations',
          component: 'KitResultDetailPage',
          method: 'loadPersonalizations',
        });
      }
    };
    if (!viewingSharedKitResult) {
      loadPersonalizations();
    }
  });

  // load kit result

  useEffect(() => {
    if (kitResult) return;

    getKitResult({
      kitResultIdOrPublishHash,
      shared: viewingSharedKitResult,
    });
  }, [
    getKitResult,
    kitResult,
    kitResultIdOrPublishHash,
    viewingSharedKitResult,
  ]);

  const groupedCategories = groupCategories(categories);
  const wellnessCircleCategories =
    normalizeWellnessCircleCategories(categories);

  // track kit result view
  useEffect(() => {
    if (!kitResult) return;

    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: viewingSharedKitResult
          ? ANALYTICS.PAGES.SHARED_KIT_RESULT
          : ANALYTICS.PAGES.KIT_RESULT,
        'Kit Result Number': kitResult.number,
        'Test ID': testId,
        'Test Name': kitResult.test && kitResult.test.name,
      },
    });
  }, [kitResult, testId, viewingSharedKitResult]);

  // Initialize Localize
  const getUserPreferredLanguage = (): string => {
    let prevLanguage = 'en';

    if (window.Localize) {
      window.Localize.detectLanguage((err: Error, languages: string[]) => {
        if (err) {
          logError((err as Error).message, {
            errorInfo: 'Error Window Localize',
            component: 'NewLayoutKitResultDetailPage',
            method: 'getUserPreferredLanguage',
          });
          return;
        }
        if (languages[0].startsWith('es')) {
          prevLanguage = 'es';
        }
        return null;
      });
    }

    return prevLanguage;
  };

  const setResultLanguage = (
    userPreferredLanguage: string,
    sku: string | undefined,
  ) => {
    const shouldLocalizeTestResult = sku && LOCALIZE_SKUS.includes(sku);

    const lang = shouldLocalizeTestResult ? userPreferredLanguage : 'en';

    window?.Localize?.setLanguage(lang);
  };

  useEffect(() => {
    if (!window.Localize) return;

    const userPreferredLanguage = getUserPreferredLanguage();

    setResultLanguage(userPreferredLanguage, sku);

    // Send the test id and sku to Datadog for better event tracking
    if (testId !== undefined && sku !== undefined) {
      datadogRum.setGlobalContextProperty('resultContext', {
        testId,
        sku,
      });
    }

    return () => {
      window?.Localize?.setLanguage(userPreferredLanguage);
    };
  }, [testId, sku]);

  // wait for kit result, test and content to load before rendering
  // shared results don't have content right now; let them pass for cypress
  if (
    !kitResult ||
    !kitResult.test ||
    (!viewingSharedKitResult && (!contentToken || !kitResult.test.content))
  ) {
    return null;
  }

  const {
    test,
    first_name: firstName,
    severity_resolution: severityResolution,
    severity_resolutions: severityResolutions,
    lab,
    clia,
    markers,
    marker_results: markerResults,
  } = kitResult;
  const { name: testName, marker_types } = test;

  let groupCount = {};

  const isFSTest = TAKE_ACTION_ORDER_OVERRIDE.includes(testName || '');

  if (markers && markerResults) {
    const groupedMarkers = isFSTest
      ? groupFSMarkersBySeverity(markers, markerResults)
      : groupBySeverity(markers, markerResults);

    groupCount = Object.keys(groupedMarkers).reduce(
      (acc: { [key: number]: number }, severityValue: string) => {
        const severity = Number(severityValue);
        try {
          acc[severity] = groupedMarkers[severity].length;
        } catch (err) {
          logError((err as Error).message, {
            errorInfo: 'Grouped Markers severity not found',
            component: 'KitResultDetailPage',
            method: 'groupCount',
          });
        }
        return acc;
      },
      {},
    );
  }

  const conditions = personalizations?.food_sensitivity.filter((condition) =>
    Object.values(FS_PERSONALIZATION_CONDITIONS).includes(condition),
  );

  const actionCardProps = isCarePlanReady
    ? {
        href: `/results/${kitResultIdOrPublishHash}/care-plan`,
        action: (
          <Box padding={2} bgColor="tints.lightCream" borderRadius={24}>
            <CaretRight color="tints.black" />
          </Box>
        ),
        label: 'View your Care Plan',
        asset: (
          <CardIcon
            icon={ClipboardText}
            color="viridian.base"
            bgColor="viridian.wash"
            ariaLabel="Care Plan icon"
          />
        ),
      }
    : {
        label: 'Your care plan is being processed...',
        asset: (
          <CardIcon
            icon={Spinner}
            color="viridian.base"
            bgColor="viridian.wash"
            ariaLabel="Loading icon"
          />
        ),
      };

  return (
    <Container paddingTop="0">
      {testName && (
        <SubNavbar
          testName={testName}
          kitResultNumber={kitResultIdOrPublishHash}
          testId={testId}
          data-test={KIT_RESULT_DETAIL.SUB_HEADER}
          shouldShowTelehealthConsult={false}
          shouldShowReferFriends={false}
        />
      )}
      <Header title="Everlywell 360" />
      <UIKitContainer
        display="flex"
        flexDirection="column"
        padding={4}
        marginRight="auto"
        marginLeft="auto"
        gap={6}
      >
        {categories && (
          <Flex
            direction="column"
            alignItems="center"
            transform="translateY(-22px)"
          >
            <WellnessCircle
              wellnessScore={wellnessScore}
              categories={wellnessCircleCategories}
              isLoading={isLoading}
            />
          </Flex>
        )}

        {contentToken && marker_types && (
          <Flex width="100%" maxWidth="720px" marginInline="auto">
            <ResultsIntro
              contentToken={contentToken}
              markerTypes={marker_types}
              userName={firstName || 'Everly'}
              resultsApprovedDate={kitResult.results_approved_at || 'unknown'}
              severityResolution={severityResolution}
              severityResolutions={severityResolutions}
              testId={testId || 0}
              testName={testName ?? ''}
              testType={kitResult.test.type}
              isThirdParty={false}
              labName={lab?.name ?? ''}
              clia={clia ?? ''}
              conditions={conditions}
              groupCount={groupCount}
            />
          </Flex>
        )}

        <Flex
          justify="center"
          direction="column"
          alignItems="center"
          marginBottom="6"
        >
          {isCarePlanLoading ? (
            <Skeleton
              maxWidth="720px"
              borderRadius="12"
              height="58px"
              width="100%"
            />
          ) : (
            <ActionCard {...actionCardProps} />
          )}
        </Flex>

        {groupedCategories && (
          <Grid
            width="100%"
            maxWidth="720px"
            templateColumns={['1fr', 'repeat(2, 1fr)', null, null]}
            marginLeft="auto"
            marginRight="auto"
            gap={4}
            justifyItems="center"
          >
            {groupedCategories.map((category) => (
              <CategoryCard
                title={category.name}
                detailsPage={`/results/${kitResultIdOrPublishHash}/detail/${category.key}`}
                abnormal={category.markers.abnormal.length}
                normal={category.markers.normal.length}
              />
            ))}
          </Grid>
        )}
      </UIKitContainer>
    </Container>
  );
};

export default KitResultDetailPage360;
