import { Box, HStack, Icon } from '@everlywell/ui-kit';
import { Sparkle } from '@phosphor-icons/react';
import React from 'react';

export type HealthSummary360Props = {
  children: string | React.ReactNode;
};

const HealthSummary360: React.FC<HealthSummary360Props> = ({ children }) => (
  <Box
    backgroundColor="eggplant.wash"
    padding={4}
    borderRadius="lg"
    display="flex"
    alignItems="flex-start"
    textAlign="center"
    width="100%"
  >
    <HStack spacing={3} align="flex-start">
      <Box
        backgroundColor="violet.lighter"
        borderRadius="full"
        border={`4px solid rgba(255, 255, 255, 0.4)`}
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="left"
      >
        <Icon as={Sparkle} color="violet.dark" boxSize="16" weight="fill" />
      </Box>

      <Box textAlign="left" color="tints.black" fontSize="lg">
        {children}
      </Box>
    </HStack>
  </Box>
);

export default HealthSummary360;
