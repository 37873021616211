import { typography, size, mediaQueries, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const medicationBoxPadding = `${size.md}px`;

export const Card = styled.div`
  padding: ${size.lg}px;
  border: 1px solid #edf5f1;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  align-self: flex-start;
  padding: 0;
`;

export const CardContent = styled.div`
  width: 100%;
  color: ${colors.gray4};
  display: flex;
  flex-direction: column;
  font-weight: ${typography.weight.light};
  font-size: ${size.md}px;
  line-height: 26px;
  letter-spacing: 0.25px;
  font-feature-settings: 'ss01' on;

  ${mediaQueries.forTabletVerticalUp} {
    width: 90%;
    margin-left: 55px;
  }
`;

export const CardListContainer = styled.div`
  margin: 0;
  width: 100%;
`;

export const Heading = styled.div`
  background-color: #f8f9f9;
  padding: ${medicationBoxPadding};
  display: flex;
  margin-top: ${size.md}px;
`;

export const ToggleButton = styled.button`
  align-self: flex-start;
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: ${typography.type.dmSans};
  font-weight: ${typography.weight.xbold};
  font-size: 14px;
  letter-spacing: 0.23px;
  color: ${colors.green4};
  cursor: pointer;
  margin-left: auto;
`;

export const Title = styled.span`
  ${typography.buttonTextSmall};
  flex: 1 0 50%;
  font-size: ${size.md}px;
  line-height: ${size.lg}px;
  font-weight: ${typography.weight.bold};
  color: ${colors.gray4};
`;

export const Child = styled.div`
  ${typography.bodyText}
  background-color: #f8f9f9;
`;

export const Content = styled.div`
  padding: ${medicationBoxPadding};
  padding-top: 0;
`;

export const MedicationItem = styled.div`
  display: flex;
  font-weight: ${typography.weight.light};
  font-size: ${size.md}px;
  line-height: 26px;
  letter-spacing: 0.25px;
`;

export const MedicationItemText = styled.div`
  font-weight: ${typography.weight.xbold};
  padding-right: 5px;
`;

export const MedicationItemValue = styled.div`
  font-weight: ${typography.weight.light};
`;
