import {
  TelehealthFormAnswer,
  savePatientRegistration,
  getTelehealthPatient,
} from 'common/apis/telehealthApis';
import { BaseRequestError, handleError } from 'common/hooks/useApi/request';
import { useCommonT2TAnalytics } from 'common/hooks/useCommonT2TAnalytics';
import useEnterpriseProgramSlug from 'common/hooks/useEnterpriseProgramSlug';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { logError } from 'common/utils/helpers';
import Layout from 'components/Layout';
import { useNotificationContext } from 'components/Notification/NotificationContext';
import FormBuilder from 'components/telehealth/FormBuilder';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FormPageBuilderSkeleton from 'pages/FormPageBuilder/FormPageBuilder.skeleton';
import { DYNAMIC_FORM_ROUTE } from 'pages/InterstitialPage';
import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { customModules } from './config';
import { parseFormData, setDefaultValues } from './utils';

/**
 * EHS T2T Appointment Registration Form
 */
function AppointmentRegistrationPage() {
  const { setNotificationData } = useNotificationContext();
  const { enterpriseTestToTreat } = useFlags();
  const { enterpriseProgramSlug } = useEnterpriseProgramSlug();
  const { commonT2TAnalyticsData } = useCommonT2TAnalytics();

  const navigate = useNavigate();

  const { data: member, isLoading: isLoadingMemberData } = useQuery(
    'getMember',
    () => getTelehealthPatient('enterprise'),
  );

  const {
    error: errorResponse,
    isLoading: isSubmitting,
    mutate: registerPatient,
    status,
  } = useMutation(savePatientRegistration, {
    onSuccess: async (_data) => {
      await analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label:
            ANALYTICS.LABELS.VIRTUAL_CARE_FORMS.PATIENT_REGISTRATION_CONTINUE,
          ...commonT2TAnalyticsData,
        },
      });
      navigate(DYNAMIC_FORM_ROUTE);
    },
  });
  const error = errorResponse as BaseRequestError | null;

  useEffectOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.EHS_T2T.REGISTRATION_PAGE,
        ...commonT2TAnalyticsData,
      },
    });
  });

  useEffect(() => {
    if (!enterpriseTestToTreat) {
      navigate('/404');
    }
  }, [enterpriseTestToTreat, navigate]);

  useEffect(() => {
    if (error?.fetchResponse?.status === 400) {
      setNotificationData({
        message: error.message[0],
        persist: false,
        type: 'error',
      });
    }
  }, [error, setNotificationData]);

  const onSubmit = (answers: TelehealthFormAnswer[]) => {
    const data = parseFormData(answers);
    data.program_slug = enterpriseProgramSlug || '';

    registerPatient(data);
  };

  useEffect(() => {
    if (error && status === 'error') {
      logError(error.message, error);
    }
  }, [error, status]);

  if (isLoadingMemberData) {
    return <FormPageBuilderSkeleton />;
  }

  const parsedCustomModules = customModules.filter((customModule) => {
    if (member?.data?.patient.mfa_verified) {
      return customModule.id !== 'email';
    }

    return true;
  });

  const defaultValues = setDefaultValues(parsedCustomModules, member?.data);

  return (
    <Layout>
      <FormBuilder
        custom_modules={parsedCustomModules}
        defaultValues={defaultValues}
        error={handleError(error)}
        id="appointment-registration-form"
        isSubmitting={isSubmitting}
        name="Appointment registration"
        onSubmit={onSubmit}
      />
    </Layout>
  );
}

export default AppointmentRegistrationPage;
