import { breakpoints, colors, size, typography } from '@everlywell/leaves';
import useMediaQuery from 'common/hooks/useMediaQuery';
import { RGB_DICTIONARY } from 'common/utils/constants';
import { formatStringSeparator } from 'common/utils/formatText';
import { getRGBColorBySeverityIndex } from 'common/utils/helpers';
import { MarkerWithResult, HistoricalResult } from 'common/utils/types';
import LineGraph from 'components/LineGraph';
import { format, parseISO } from 'date-fns';
import React from 'react';

/* eslint-disable @typescript-eslint/no-var-requires */
export type Props = {
  markerWithResult: MarkerWithResult;
  historicalResults: HistoricalResult[];
  isShowing: boolean;
};

export type DataPoint = {
  value: number;
  tooltipValue: string | number;
  date: string | number | Date;
  fullDate: string | number | Date;
  originalDate: string;
  y: number;
  severityColor: string;
  marker_result_id: number;
};

export type SeenMap = {
  [key: string]: number;
};

export type BuildLineGraphParams = {
  arrayOfYValues: (number | null)[];
  arrayOfXAxisLabels: (string | null | string[])[];
  arrayForHighlightData: (number | null)[];
  yAxisGridLineColors: string[];
  arrayOfIndicesWithValues: number[];
  yAxisBoundaryLabels: number[];
  yAxisBoundaryColors: string[];
  arrayOfSeverityColors: string[];
};

export type LabelMap = {
  [key: number]: number;
};

export const findLowerBound = (value: number, boundaries: number[]) => {
  let lowerBound = 0;
  for (let i = 0; boundaries[i] < value; i += 1) {
    if (value > boundaries[i]) {
      lowerBound = boundaries[i];
    }
  }

  return lowerBound;
};
export const INDEX_OF_FIRST_DATA_POINT_ON_AXIS = 4;

export const translateValueToYCoordinate = (
  value: number,
  markerBoundaries: number[],
  yLabels: number[],
) => {
  const lowerBound = findLowerBound(value, markerBoundaries);
  const lowerBoundIndex = yLabels.indexOf(lowerBound);
  const upperBound = yLabels[lowerBoundIndex + 1];
  const percentagePastLowerBound =
    (value - lowerBound) / (upperBound - lowerBound);

  return lowerBoundIndex + percentagePastLowerBound;
};

export const parseDataFromResults = (
  historicalResults: HistoricalResult[],
  boundaries: number[],
  yLabels: number[],
  markerWithResult: MarkerWithResult,
) => {
  const markerValues: DataPoint[] = historicalResults
    .map((result) => {
      let resultValue = Number(result.value);
      if (typeof result.value === 'string') {
        resultValue = Number(result.value.replace(/[^\d.-]/g, ''));
      }
      return {
        marker_result_id: result.id,
        value: resultValue,
        tooltipValue: result.value,
        severityColor: getRGBColorBySeverityIndex(
          result.severity_index,
          markerWithResult,
        ),
        date:
          result.collected_at !== null
            ? `'${format(parseISO(result.collected_at), 'yy')}`
            : `'${format(parseISO(result.results_approved_at), 'yy')}`,
        fullDate:
          result.collected_at !== null
            ? format(parseISO(result.collected_at), 'MMM y')
            : format(parseISO(result.results_approved_at), 'MMM y'),
        originalDate:
          result.collected_at !== null
            ? result.collected_at
            : result.results_approved_at,
        y: translateValueToYCoordinate(resultValue, boundaries, yLabels),
      };
    })
    // historicalResults comes in descending order
    // we feed into the chart in ascending order to show most recent test on the right
    .reverse();

  return markerValues;
};

export const getYAxisBoundaryLabels = (
  markerBoundaries: number[],
  maxValue: number,
) => {
  // TODO: do we ever have lower boundary than 0 ?
  const yLabels: number[] = [
    0,
    ...markerBoundaries,
    maxValue || 100, // TODO: what should the max value be if there is no max_value associated with the marker
  ];
  return yLabels;
};

/**
 * example of what is returned for a marker that has 2 historical results in 2018
 *
 * arrayOfYValues - [null, 1.5333, null, null, 2, null]
 * arrayOfXAxisLabels - ["", "2018", "", "", "", ""]
 * yAxisGridLineColors - ['transparent', '#fcfcfc']
 * arrayOfIndicesWithValues - [1, 4] - the indices of arrayOfYValues that are not null
 * yAxisBoundaryLabels - [0, 4, 24.8] - Y-axis marker boundaries
 * yAxisBoundaryColors - ['yello', 'green', 'yello']
 */
const scaleDataForGraph = (
  currentResultId: number,
  dataPoints: DataPoint[],
  yLabels: number[],
  isMobile: boolean,
) => {
  // this is the yAxis grid line colors, dotted horizontal lines at each boundary
  // we want to hide the top yAxis boundary, thats why we start with 'transparent' in the array
  const yAxisGridLineColors: string[] = ['transparent'];

  // push in yAxis grid lines with correct color for boundaries
  for (let i = 1; i < yLabels.length - 1; i += 1) {
    yAxisGridLineColors.push(colors.gray2);
  }

  const arrayOfTooltipValues: string[] = [];
  const arrayOfTooltipTitles: string[] = [];
  const arrayOfSeverityColors: string[] = [];
  const arrayOfXAxisLabels: (string | null | string[])[] = [];
  const arrayOfYValues: (number | null)[] = [];
  const arrayForHighlightData: (number | null)[] = [];
  const arrayOfMarkerResultIds: (number | null)[] = [];

  const numOfDataPoints = dataPoints.length;

  /** this constant controls how many points are on the x-axis to evenly distribute the ticks and datapoints */
  const NUM_OF_POINTS_ON_X_AXIS = numOfDataPoints * 10;
  let arrayOfIndicesWithValues: number[] = [];

  // figure out the distribution of dataPoints so they are evenly distributed on graph
  if (numOfDataPoints < 3) {
    // if only 2 dataPoints - put them at the start and end of the chart
    arrayOfIndicesWithValues = [
      INDEX_OF_FIRST_DATA_POINT_ON_AXIS,
      NUM_OF_POINTS_ON_X_AXIS - INDEX_OF_FIRST_DATA_POINT_ON_AXIS,
    ];
  } else {
    // more than 2 dataPoints, we need to equally distribute data points along X-axis
    const equalSectionOfLine = 1 / (numOfDataPoints - 1);
    // middlePoints will be an array of indices where we will put values so that are evenly distributed between beginning and end of graph
    const middlePoints: number[] = [];

    for (
      let lineLength = 0, i = 0;
      i < dataPoints.length - 1;
      lineLength += equalSectionOfLine, i += 1
    ) {
      if (lineLength !== 0) {
        middlePoints.push(
          Math.ceil(NUM_OF_POINTS_ON_X_AXIS * equalSectionOfLine) * i,
        );
      }
    }
    // spread the middlePoints into the array of indices where we will have a value
    arrayOfIndicesWithValues = [
      INDEX_OF_FIRST_DATA_POINT_ON_AXIS,
      ...middlePoints,
      NUM_OF_POINTS_ON_X_AXIS - INDEX_OF_FIRST_DATA_POINT_ON_AXIS,
    ];
  }

  const seenLabels: SeenMap = {};

  // create the arrays of Y values, labels and hidden lines for the graph
  for (let i = 0; i <= NUM_OF_POINTS_ON_X_AXIS; i += 1) {
    if (arrayOfIndicesWithValues.indexOf(i) > -1) {
      // should show a value at this index
      const dataPoint = dataPoints[arrayOfIndicesWithValues.indexOf(i)];
      arrayOfYValues[i] = dataPoint.y;

      if (dataPoint.marker_result_id === currentResultId) {
        // Set highlight bar on current marker result
        arrayOfMarkerResultIds[i] = currentResultId;
        arrayForHighlightData[i] = 4;
      } else {
        // Set highlight bar on other marker result
        arrayForHighlightData[i] = 4;
      }
      // show different labels depending on screen size
      const label = isMobile ? `${dataPoint.date}` : `${dataPoint.fullDate}`;
      if (seenLabels[`${label}`]) {
        arrayOfXAxisLabels[i] = '';
      } else {
        // created nested array to make labels multi-line
        const twoLines = label.split(' ');
        arrayOfXAxisLabels[i] = twoLines ? twoLines.map((x) => x) : label;
      }

      const tooltipMonth = format(
        parseISO(dataPoint.originalDate),
        'MMM',
      ).toString();
      const tooltipYear = format(
        parseISO(dataPoint.originalDate),
        'yy',
      ).toString();

      seenLabels[`${label}`] = 1;
      arrayOfTooltipValues[i] = `${dataPoint.tooltipValue}`;
      arrayOfTooltipTitles[i] = isMobile
        ? `${tooltipMonth} '${tooltipYear}`
        : ''; // `${label}`;
      arrayOfSeverityColors[i] = `rgb(${dataPoint.severityColor})`;
    }
    // should not show a value at this index, push in empty values
    arrayOfMarkerResultIds.push(null);
    arrayOfYValues.push(null);
    arrayOfXAxisLabels.push('');
    arrayOfTooltipValues.push('');
    arrayOfTooltipTitles.push('');
    arrayOfSeverityColors.push('');
    arrayForHighlightData.push(null);
  }

  return {
    arrayOfYValues,
    arrayOfXAxisLabels,
    arrayOfTooltipValues,
    arrayOfTooltipTitles,
    arrayOfSeverityColors,
    yAxisGridLineColors,
    arrayOfIndicesWithValues,
    arrayForHighlightData,
    arrayOfMarkerResultIds,
  };
};

/* use scaled data arrays to create prop values for LineGraph */
const buildLineGraphProps = ({
  arrayOfYValues,
  arrayOfXAxisLabels,
  yAxisGridLineColors,
  arrayOfIndicesWithValues,
  yAxisBoundaryLabels,
  yAxisBoundaryColors,
  arrayForHighlightData,
  arrayOfSeverityColors,
}: BuildLineGraphParams) => {
  const labelMap: LabelMap = {};

  // only show labels for middle Y-axis boundary(ies), not first or last
  yAxisBoundaryLabels.forEach((label: number, index: number) => {
    if (index !== yAxisBoundaryLabels.length - 1 || index === 0) {
      labelMap[index] = label;
    }
  });

  // the colored severity line on Y-Axis is created with datasets
  const datasetsForSeverityLine = yAxisBoundaryLabels.map(
    (label: number, i: number) => ({
      label: `${label}-boundary-severity`,
      data: [i + 1],
      backgroundColor: `rgb(${RGB_DICTIONARY[yAxisBoundaryColors[i]]})`,
      hoverBackgroundColor: `rgb(${RGB_DICTIONARY[yAxisBoundaryColors[i]]})`,
      barThickness: 10,
      barPercentage: 1,
      categoryPercentage: 1,
    }),
  );

  const datasets = [
    ...datasetsForSeverityLine,
    {
      type: 'line',
      id: 'lineGraphData',
      label: 'results over time',
      data: arrayOfYValues,
      fill: true,
      borderWidth: 5,
      backgroundColor: 'transparent',
      spanGaps: true,
      pointBorderWidth: 1,
      pointRadius: 6,
      pointBorderColor: arrayOfSeverityColors,
      pointHoverBorderColor: arrayOfSeverityColors,
      pointBackgroundColor: arrayOfSeverityColors,
      pointHoverRadius: 8,
      pointHoverBorderWidth: 4,
      pointHoverBackgroundColor: `rgba(${colors.white}, 1)`, // good
      xAxisId: 'xAxis',
    },
    {
      label: 'Bar Dataset',
      id: 'barGraphData',
      data: arrayOfYValues,
      type: 'bar',
      backgroundColor: 'transparent',
      hoverBackgroundColor: `rgba(${colors.gray2}, 0.25)`,
      barThickness: 40,
    },
  ];

  const yAxes = [
    {
      id: 'severityLevels',
      type: 'linear',
      position: 'left',
      gridLines: {
        borderDash: [4, 3],
        circular: true,
        z: 0,
        lineWidth: 2,
        drawBorder: false,
        zeroLineColor: colors.gray2,
        zeroLineWidth: 3,
        color: yAxisGridLineColors,
        tickMarkLength: 10,
      },
      ticks: {
        fontColor: colors.gray4,
        fontSize: 16,
        lineHeight: 1.5,
        fontFamily: `${typography.type.dmSans}`,
        fontWeight: `${typography.weight.regular}`,
        padding: 4,
        max: yAxisBoundaryLabels.length - 1,
        min: 0,
        stepSize: 1,
        callback(label: number) {
          if (labelMap[label]) {
            return labelMap[label];
          }
          return '';
        },
      },
    },
  ];

  const xAxes = [
    {
      stacked: true,
      id: 'xAxis',
      display: true,
      offset: false,
      gridLines: {
        zeroLineColor: 'transparent',
        drawBorder: false,
        offsetGridLines: false,
        lineWidth: 3,
        tickMarkLength: 12,
        drawOnChartArea: false,
      },
      ticks: {
        fontColor: colors.gray5,
        fontSize: size.md,
        lineHeight: 1.5,
        autoSkip: false,
        fontFamily: `${typography.type.dmSans}`,
        fontWeight: `${typography.weight.light}`,
        padding: 4,
        callback(value: string, index: number) {
          if (index === INDEX_OF_FIRST_DATA_POINT_ON_AXIS) {
            return `  ${value}  `;
          }
          if (arrayOfIndicesWithValues.includes(index)) {
            return `  ${arrayOfXAxisLabels[index]}  `;
          }
          return null;
        },
      },
    },
  ];

  return {
    datasets,
    labels: arrayOfXAxisLabels,
    yAxes,
    xAxes,
  };
};

const ResultsOverTimeContainer = (props: Props) => {
  const { markerWithResult, isShowing, historicalResults = [] } = props;
  const { marker_result: markerResult, name, descriptors } = markerWithResult;
  const { boundaries, id: currentResultId } = markerResult;

  /**
   * The relationship between descriptors and boundaries is: boundaries = usableDescriptors - 1
   * We want to ignore the optional descriptors here, like TNP, so using this marker as example
   * descriptors: ["Low", "Normal", "High", "TNP"]
   * boundaries: [3, 8]
   * For this case the usableDescriptors should be ["Low", "Normal", "High"]
   * **/
  const usableDescriptors =
    descriptors?.slice?.(0, boundaries.length + 1) ?? [];

  const yAxisBoundaryColors = markerWithResult.severity_colors;

  const maxValue = Math.max(...historicalResults.map((r) => Number(r.value)));

  /** get the Y-axis boundary labels: [0, ...boundaries, maxValue] */
  const yAxisBoundaryLabels = getYAxisBoundaryLabels(
    boundaries,
    maxValue * 1.3,
  );

  /** get array of objects representing each dataPoint */
  const arrayOfDataPoints = parseDataFromResults(
    historicalResults,
    boundaries,
    yAxisBoundaryLabels,
    markerWithResult,
  );

  const maxBoundaryLength = Math.max(
    ...boundaries.map(
      (boundary) => boundary.toString().replace('.', '').length,
    ),
  );

  const isMobile = useMediaQuery(
    `(max-width: ${breakpoints.forTabletVerticalUp}px)`,
  );

  /** use dataPoints & yAxisBoundaryLabels to create arrays of computed graph values */
  const {
    arrayOfYValues,
    arrayOfXAxisLabels,
    arrayOfTooltipValues,
    arrayOfTooltipTitles,
    arrayOfSeverityColors,
    arrayForHighlightData,
    yAxisGridLineColors,
    arrayOfIndicesWithValues,
    arrayOfMarkerResultIds,
  } = scaleDataForGraph(
    currentResultId,
    arrayOfDataPoints,
    yAxisBoundaryLabels,
    isMobile,
  );

  /** use the arrays of computed graph values to get Line Graph props */
  const { datasets, labels, yAxes, xAxes } = buildLineGraphProps({
    arrayOfYValues,
    arrayOfXAxisLabels,
    yAxisGridLineColors,
    arrayOfIndicesWithValues,
    yAxisBoundaryLabels,
    yAxisBoundaryColors,
    arrayForHighlightData,
    arrayOfSeverityColors,
  });

  const indexOfMostRecent =
    arrayOfMarkerResultIds.indexOf(currentResultId) ||
    INDEX_OF_FIRST_DATA_POINT_ON_AXIS;

  const datasetForLineGraph = datasets.find(
    (dataset: any) => dataset.id === 'lineGraphData',
  );
  const datasetForBarGraph = datasets.find(
    (dataset: any) => dataset.id === 'barGraphData',
  );

  // @ts-ignore
  const indexOfLineDataset = datasets.indexOf(datasetForLineGraph);
  // @ts-ignore
  const indexOfBarDataset = datasets.indexOf(datasetForBarGraph);

  // do not render the graph while its hidden inside of a collapsed section
  if (!isShowing) {
    return null;
  }

  return (
    <div
      data-test={`${formatStringSeparator(name.toLowerCase())} - rot - graph`}
    >
      <LineGraph
        graphId={`${markerWithResult.id}`}
        // @ts-ignore
        datasets={datasets}
        labels={labels}
        yAxes={yAxes}
        yAxisLabels={usableDescriptors}
        // @ts-ignore
        xAxes={xAxes}
        arrayOfTooltipValues={arrayOfTooltipValues}
        arrayOfTooltipTitles={arrayOfTooltipTitles}
        arrayOfSeverityColors={arrayOfSeverityColors}
        activeDataPointIndex={indexOfMostRecent}
        indexOfLineDataset={indexOfLineDataset}
        indexOfBarDataset={indexOfBarDataset}
        maxBoundaryLength={maxBoundaryLength}
      />
    </div>
  );
};

export default ResultsOverTimeContainer;
