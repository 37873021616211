import { Box, useDisclosure } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { Tooltip } from '@everlywell/ui-kit';
import React from 'react';

export type OuterCircleTooltipProps = {
  markerName: string;
  color: string;
  rotate: number;
  outerRadius: number;
  markersWidth: string;
  markersHeight: string;
  isLoading?: boolean;
};

// Pulse animation for loading state
const isLoadingPulseAnimation = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;

const OuterCircleTooltip = ({
  markerName,
  color,
  rotate,
  outerRadius,
  markersWidth,
  markersHeight,
  isLoading,
}: OuterCircleTooltipProps) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const interactionHandlers = isLoading
    ? {}
    : {
        onMouseEnter: onOpen,
        onMouseLeave: onClose,
        onClick: onToggle,
      };

  return (
    <Tooltip
      isOpen={isOpen}
      label={
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            as="span"
            backgroundColor={color}
            borderRadius="50%"
            width="8px"
            height="8px"
          />
          {markerName}
        </Box>
      }
      hasArrow
      placement="auto"
      backgroundColor="tints.white"
      color="viridian.dark"
      boxShadow="md"
      role="tooltip"
      id={`${markerName}-tooltip`}
      isDisabled={isLoading}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transformOrigin="center bottom"
        transform={`rotate(${rotate}deg) translateY(-${outerRadius}px)`}
        {...interactionHandlers}
        pointerEvents={isLoading ? 'none' : 'auto'}
      >
        <Box
          width={markersWidth}
          height={markersHeight}
          transformOrigin="center bottom"
          backgroundColor={color}
          transition="background-color 0.6s ease-in-out"
          animation={
            isLoading
              ? `${isLoadingPulseAnimation} 3s ease-in-out infinite`
              : 'none'
          }
          _hover={isLoading ? {} : { transform: 'scale(1.8)' }}
          data-test={`${markerName}-tooltip-trigger`}
          cursor={isLoading ? 'default' : 'pointer'}
        />
      </Box>
    </Tooltip>
  );
};

export default OuterCircleTooltip;
