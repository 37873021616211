import {
  Button,
  colors,
  H3,
  Icons,
  mediaQueries,
  size,
  typography,
  Input,
  Row,
  Tooltip,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

type Props = {
  align?: string;
  bold?: boolean;
};

const Container = styled.div`
  width: 100%;
  margin: 0 auto ${size.md}px auto;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 532px;
    margin: 0 auto ${size.xl1}px auto;
  }
`;

const Title = styled(H3)`
  width: 100%;
  text-align: center;
  margin: 0 0 ${size.lg}px 0;
  font-weight: ${typography.weight.book};
  margin-top: ${size.xl1}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: ${size.xl4}px;
  }
`;

const Cards = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: space-between;
    margin: 0;
  }
`;

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  background: ${colors.green1};
  padding: ${size.lg}px ${size.xs2}px;
  border-radius: ${size.xs1}px;
  border: 1px solid ${colors.green2};
  margin-bottom: ${size.xl1}px;

  &:first-child {
    margin-right: 0;
  }

  &:last-child {
    margin-left: 0;
  }

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.md}px ${size.xl1}px;
  }
`;

const Subtitle = styled.div<Props>`
  width: 100%;
  text-align: ${(props) => props.align || 'center'};
  color: ${colors.gray4};
  font-weight: ${typography.weight.book};
`;

const PerksSubtitle = styled.div<Props>`
  width: 100%;
  text-align: ${(props) => props.align || 'center'};
  color: ${colors.gray4};
  font-weight: ${typography.weight.book};
  margin-bottom: 10px;
`;

const Caption = styled.div<Props>`
  ${typography.captionText}
  width: 100%;
  font-weight: ${(props) =>
    props.bold ? typography.weight.bold : typography.weight.book};
  text-align: ${({ align }) => align || 'center'};
  color: ${colors.gray4};
`;

const Price = styled(H3)`
  width: 100%;
  text-align: center;
  color: ${colors.green4};
  margin: ${size.xs2}px 0;

  :nth-of-type(2) {
    ${typography.subtitleSmall};
    color: ${colors.green4};
  }
`;

const Description = styled.div<Props>`
  width: 100%;
  text-align: ${(props) => props.align || 'center'};
  ${typography.bodyTextSmall}
`;

const List = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  color: ${colors.teal4};
  display: flex;
  align-items: start;
  padding: ${size.xs2}px 0;
`;

const Icon = styled(Icons.CheckMark)`
  color: ${colors.teal4};
  width: ${size.sm}px;
  height: ${size.sm}px;
  margin-right: ${size.sm}px;
  margin-top: 8px;
`;

const Meta = styled.div`
  color: ${colors.gray4};
  flex: 1;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl2}px;
  }
`;

const AcceptedCardsContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-start;

  @supports (-moz-appearance: none) {
    width: 67%;
  }

  ${mediaQueries.forTabletVerticalUp} {
    width: 10%;

    @supports (-moz-appearance: none) {
      width: 45%;
    }
  }
`;

const ProgressCircleAnimation = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  opacity: 0.95;
  z-index: 100;

  &:after {
    position: absolute;
    animation: loading 500ms infinite linear;
    border: ${size.xs3}px solid ${colors.green3};
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    height: ${size.xl1}px;
    width: ${size.xl1}px;
    left: 50%;
    top: 40%;
    margin-left: -0.4rem;
    margin-top: -0.4rem;
  }
`;

const CardDetailsInputsContainer = styled.div`
  width: 100%;
  margin: ${size.lg}px 0 0 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.lg}px 0;
  }
`;

const ZipCodeInput = styled(Input)`
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
`;

const StyledRow = styled(Row)`
  margin: 0;
`;

const HorizontalLine = styled.hr`
  border: 1px solid ${colors.green2};
  margin: ${size.lg}px;
`;

const PerksContainer = styled.div`
  margin-left: ${size.lg}px;
`;

const Legend = styled.div`
  background-color: ${colors.gray1};
  padding: ${size.md}px ${size.lg}px;
  border-radius: ${size.xs1}px;
`;

const LegendPoint = styled.div`
  ${typography.captionText}
  width: 100%;
  color: ${colors.gray4};
  padding-bottom: ${size.lg}px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${colors.green4};
  font-weight: ${typography.weight.bold};
`;

const StripeInputWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const StripeLabel = styled.label`
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.dmSans};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.5;
  letter-spacing: normal;
`;

const StripeInput = styled.div<{ name: string }>`
  color: ${colors.gray4};
  font-family: ${typography.type.dmSans};
  font-size: 18px;
  line-height: 1.78;
  background: ${colors.white};
  border-radius: 1px;
  padding: ${size.xs1}px ${size.md}px;
  border: 1px solid ${colors.green2};
  box-shadow: ${size.xs2}px ${size.xs2}px 15px -5px rgba(0, 0, 0, 0.1);
  &.StripeElement--focus {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.teal5};
    box-shadow: ${colors.teal5} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.teal4};
    }
  }
  &.StripeElement--invalid {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.red3};
    box-shadow: ${colors.red3} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.red3};
    }
  }
`;

const StripeErrorLabel = styled.span`
  background: none;
  color: ${colors.red3};
  display: block;
  font-size: ${size.md}px;
  margin-bottom: ${size.lg}px;
  opacity: 1;
`;

const StyledInputContainer = styled.div`
  position: relative;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 80%;
  top: -2%;
  color: ${colors.green4};

  ${mediaQueries.forTabletVerticalUp} {
    right: 87%;
    top: -2%;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    right: 75%;
    top: -2%;
  }
`;

const CvcTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 150px;
    height: 225px;
    padding: ${size.xs1}px ${size.sm}px;

    ${mediaQueries.forTabletVerticalUp} {
      width: 295px;
      height: 110px;
      padding: ${size.xs1}px ${size.sm}px;
    }
  }
`;

const CreditCards = styled.div`
  align-self: center;
  display: flex;
  gap: ${size.xs1}px;
`;

const card = css`
  width: 100%;
  max-width: 52px;
  grid-column-end: span 2;
  ${mediaQueries.forTabletHorizontalUp} {
    grid-column-end: span 1;
  }
`;

const MasterCard = styled.img`
  ${card};
`;

const Visa = styled.img`
  ${card};
`;

const AmericanExpress = styled.img`
  ${card};
`;

const Discover = styled.img`
  ${card};
`;

const Exponent = styled.sup`
  font-size: ${size.sm}px;
`;

export const ToastWrapper = styled.div`
  & > div {
    max-width: 600px;
    width: calc(100% - ${size.xl1}px);

    ${mediaQueries.forTabletVerticalUp} {
      width: 65%;
      margin: ${size.md}px auto;
    }
  }
`;

export {
  AcceptedCardsContainer,
  Actions,
  Caption,
  Card,
  Cards,
  CardDetailsInputsContainer,
  Container,
  CvcTooltip,
  Description,
  Exponent,
  HorizontalLine,
  Icon,
  Item,
  Legend,
  LegendPoint,
  Link,
  List,
  Meta,
  PerksContainer,
  PerksSubtitle,
  Price,
  ProgressCircleAnimation,
  StripeInputWrapper,
  StripeInput,
  StripeLabel,
  StyledInputContainer,
  StyledRow,
  SubmitButton,
  Subtitle,
  Title,
  TooltipWrapper,
  ZipCodeInput,
  CreditCards,
  MasterCard,
  Visa,
  AmericanExpress,
  Discover,
  StripeErrorLabel,
};
