import {
  size,
  colors,
  typography,
  mediaQueries,
  Tooltip,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const LabelWrapper = styled.div`
  display: flex;
`;
const Label = styled.label`
  ${typography.bodyText};
  font-weight: ${typography.weight.bold};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.625;
  letter-spacing: normal;
  margin-bottom: ${size.xs2}px;
`;

const Input = styled.div<{ name: string; isDisabled: boolean }>`
  color: ${colors.gray4};
  font-family: ${typography.type.dmSans};
  font-size: 18px;
  line-height: 1.61;
  background: ${colors.white};
  border-radius: ${size.xs2}px;
  padding: ${size.xs1}px ${size.md}px;
  border: 1px solid ${colors.green2};
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.1);
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          background-color: ${colors.gray5}0d;
          border-color: ${colors.gray2};

          + ${Label} {
            color: ${colors.gray3};
          }
        `
      : css``};

  &.StripeElement--focus {
    border: 1px solid ${colors.green3};
    border-bottom: 1px solid ${colors.gray3};
    box-shadow: ${colors.green3} 0 -1px 0 0 inset;
    + ${Label} {
      color: ${colors.green5};
    }
  }
  &.StripeElement--invalid {
    border: 1px solid ${colors.red3};
    border-bottom: 1px solid ${colors.red3};
    + ${Label} {
      color: ${colors.red3};
    }

    &.StripeElement--focus {
      border: 1px solid ${colors.red3};
      border-bottom: 1px solid ${colors.red3};
      box-shadow: ${colors.red3} 0 -1px 0 0 inset;
    }
  }
`;

const TooltipWrapper = styled.div`
  color: ${colors.green4};
  margin-left: ${size.xs1}px;
  ${mediaQueries.forTabletHorizontalDown} {
    margin-right: 10px;
  }
  ${mediaQueries.forPhoneOnly} {
    margin-right: 10px;
  }
`;

const CvcTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 295px;
    height: 110px;
    padding: ${size.xs1}px ${size.sm}px;
    ${mediaQueries.forPhoneOnly} {
      width: 150px;
      height: 210px;
      padding: ${size.xs1}px ${size.sm}px;
    }
  }
`;

const ErrorLabel = styled.span`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.red3};
  line-height: 1.5;
  background: none;
  display: block;
  margin-bottom: ${size.lg}px;
  opacity: 1;
`;

export {
  CvcTooltip,
  ErrorLabel,
  Input,
  InputWrapper,
  Label,
  LabelWrapper,
  TooltipWrapper,
};
