import { typography } from '@everlywell/leaves';
import styled from 'styled-components';

const FooterContainer = styled.div`
  position: relative;

  /* stylelint-disable */
  #newsletterSubscriptionForm svg {
    display: none;
    fill: none;
  }
`;

const HumanaCopy = styled.span`
  display: block;
  font-family: ${typography.type.dmSans};
  margin-block-end: 1em;
  margin-block-start: 1em;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
  margin: 0px auto 10px auto;
`;

export { FooterContainer, HumanaCopy };
