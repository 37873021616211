import {
  KitResultCarePlanRecommendations,
  KitResultCarePlanRecommendationTypes,
  KitResultCarePlanSectionDataResponse,
  KitResultCarePlanSections,
  KitResultCarePlanSectionTypes,
} from '../../common/apis/telehealthApis';
import {
  CARE_PLAN_RECOMMENDATION_SECTION_TYPE_MAPPING,
  CARE_PLAN_SECTION_TYPE_MAPPING,
} from './constants';

const SectionItems: KitResultCarePlanSectionDataResponse = {
  insights: 'Insights from your panel',
  actions: 'Taking action',
};

const setItems = (
  data: KitResultCarePlanSectionDataResponse,
): { description: string; note: string }[] =>
  // If content is empty space it filters out and return object
  Object.entries(data)
    .filter(([, value]) => value.trim().length > 0)
    .map(([key, value]) => ({
      description:
        SectionItems[key as keyof KitResultCarePlanSectionDataResponse],
      note: value,
    }));

export const formatKitResultSectionsAccordionItems = (
  sections: KitResultCarePlanSections | null,
  props: {
    isLoading?: boolean;
  },
) => {
  if (!sections) return [];

  return Object.entries(sections).map(([key, value]) => {
    const orderType = key as KitResultCarePlanSectionTypes;
    const section = CARE_PLAN_SECTION_TYPE_MAPPING?.[orderType];

    return {
      sectionName: section?.text,
      iconName: section?.iconName,
      items: setItems(value ?? { insights: '', actions: '' }),
      isLoading: props.isLoading,
    };
  });
};

export const formatKitResultRecommendationsItems = (
  recommendations: KitResultCarePlanRecommendations | null,
  props: {
    isLoading?: boolean;
  },
) => {
  if (!recommendations) return [];

  return Object.entries(recommendations).map(([key, value]) => {
    const recommendationType = key as KitResultCarePlanRecommendationTypes;
    const recommendation =
      CARE_PLAN_RECOMMENDATION_SECTION_TYPE_MAPPING?.[recommendationType];

    return {
      sectionName: recommendation?.text,
      iconName: recommendation?.iconName,
      color: recommendation?.color,
      bgColor: recommendation?.bgColor,
      description: value ?? '',
      isLoading: props.isLoading,
    };
  });
};
