import {
  Button,
  colors,
  H3,
  mediaQueries,
  Row,
  TertiaryLink,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const ListTitle = styled(H3)`
  font-weight: ${typography.weight.book};
  text-align: left;
  margin: 16px 0;
`;

export const Container = styled.div``;

export const ItemContainer = styled.div`
  margin-bottom: 16px;
`;

export const BulletContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const IconContainer = styled.img`
  margin-right: 8px;
  margin-top: 6px;
  width: 16px;
  height: 16px;
`;

export const Content = styled.p`
  ${typography.bodyTextSmall}
  color: ${colors.gray4};
  text-align: left;
`;

export const MobileImage = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
  ${mediaQueries.forDesktopUp} {
    display: none;
  }
`;

export const DesktopImage = styled.img`
  display: none;
  max-width: 100%;
  width: 100%;
  ${mediaQueries.forDesktopUp} {
    display: block;
  }
`;

const Btn = styled(Button)`
  &:hover {
    text-decoration: none;
  }
`;

const Title = styled(H3)`
  font-weight: ${typography.weight.book};
  text-align: center;
`;

const Wrapper = styled(Row)`
  padding: 0 0 60px;
  box-sizing: border-box;

  ${mediaQueries.forPhoneOnly} {
    padding: 30px 0;
  }
`;

const VideoLabel = styled.span`
  ${typography.buttonText};
  color: ${colors.teal4};
`;
const VideoLink = styled(TertiaryLink)``;

const VideoLinkContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { Btn, Title, Wrapper, VideoLabel, VideoLink, VideoLinkContainer };
