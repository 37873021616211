import {
  Button,
  size,
  colors,
  typography,
  Col,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

const StickyQuestionFooter = styled(Col)<{ inView?: boolean }>`
  background: white;
  padding: ${size.md}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: sticky;
  margin-top: ${size.lg}px;
  bottom: 0;
  max-width: none;
  z-index: 1;
  box-shadow: 0 ${size.xs2}px ${size.sm}px rgba(0, 0, 0, 0.1);
  border-radius: ${size.xs2}px;

  ${mediaQueries.forPhoneOnly} {
    margin-left: ${({ inView }) => (inView ? size.xs1 : -size.xs1)}px;
    margin-right: ${({ inView }) => (inView ? size.xs1 : -size.xs1)}px;
    padding-bottom: calc(
      ${size.md}px +
        ${({ inView }) =>
          inView ? '0px' : `env(safe-area-inset-bottom, 50px)`}
    );
  }

  ${mediaQueries.forTabletVerticalUp} {
    bottom: ${size.lg}px;
    margin-left: ${size.xs1}px;
    margin-right: ${size.xs1}px;
  }
`;

const BackButton = styled(Button)`
  width: auto;
  flex-grow: 1;
`;

type ContinueButtonWidthProps = {
  showBackButton: boolean;
};

const ContinueButton = styled(Button)<ContinueButtonWidthProps>`
  margin-left: ${(props) => (props.showBackButton ? '16px' : '0px')};
  width: ${(props) => (props.showBackButton ? 'auto' : '100%')};
  flex-grow: 2;
`;

const SkipQuestionButton = styled.button`
  color: ${colors.green5};
  cursor: pointer;
  font-size: 18px;
  font-weight: ${typography.weight.bold};
  height: ${size.lg}px;
  margin-top: ${size.lg}px;
  text-align: center;
  width: 100%;

  :disabled {
    color: ${colors.gray2};
  }
`;

export { StickyQuestionFooter, BackButton, ContinueButton, SkipQuestionButton };
