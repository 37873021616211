import { Box, Icon } from '@everlywell/ui-kit';
import { Icon as tIcon } from '@phosphor-icons/react';
import React from 'react';

export type CardIconProps = {
  icon: tIcon;
  bgColor?: string;
  color?: string;
  ariaLabel?: string;
};

const CardIcon: React.FC<CardIconProps> = (props) => {
  const { icon, bgColor, color, ariaLabel } = props;

  return (
    <Box
      borderRadius="8"
      backgroundColor={bgColor}
      padding="2"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Icon
        as={icon}
        color={color}
        height="16px"
        width="16px"
        role="img"
        aria-label={ariaLabel}
      />
    </Box>
  );
};

export default CardIcon;
