import {
  colors,
  H4,
  mediaQueries,
  size,
  typography,
  Col,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled(Col)`
  max-width: 58rem;
  margin: auto;
  justify-content: center;
  width: 100%;
`;

const Header = styled(H4)`
  text-align: left;
  margin: ${size.xl7}px 0 ${size.xs1}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    text-align: center;
    margin-bottom: ${size.md}px;
  }
`;

const Description = styled.div`
  color: ${colors.gray4};
  font-size: 18px;
  font-weight: ${typography.weight.light};
  line-height: ${size.xl1}px;
  text-align: left;

  a {
    color: ${colors.green3};
    font-weight: ${typography.weight.bold};
    text-decoration: none;
  }

  ${mediaQueries.forTabletVerticalUp} {
    text-align: center;
  }
`;

export { Wrapper, Header, Description };
