import { Col } from '@everlywell/leaves';
import { Box } from '@everlywell/ui-kit';
import useApi from 'common/hooks/useApi';
import useWellnessScore from 'common/hooks/useWellnessScore';
import {
  FS_PERSONALIZATION_CONDITIONS,
  MARKER_TYPES,
} from 'common/utils/constants';
import { logError } from 'common/utils/helpers';
import {
  FactCardValues,
  HealthProfileProps,
  Personalizations,
} from 'common/utils/types';
import FoodSensitivityResultDetail from 'components/FoodSensitivityResultDetail';
import IntensityResultDetail from 'components/IntensityResultDetail';
import DescriptiveResultDetailContainer from 'containers/DescriptiveResultDetailContainer';
import {
  DispatchProps,
  StateProps,
} from 'containers/KitResultDetailPageContainer';
import NumericalResultDetailContainer from 'containers/NumericalResultDetailContainer';
import _ from 'lodash';
import { CARE_PLAN_SECTION_TYPE_MAPPING } from 'pages/KitResultCarePlanPage/constants';
import React, { useState, useEffect } from 'react';
import { Link, useParams, Navigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { KitResultCarePlanSectionTypes } from '../../common/apis/telehealthApis';
import Container from '../KitResultDetailPage360/Container';
import Header from '../KitResultDetailPage360/Header';
import CategoryPageSkeleton from './CategoryPage.skeleton';

export type CategoryPageProps = StateProps & DispatchProps;

const CategoryPage = (props: CategoryPageProps) => {
  const {
    kitResultIdOrPublishHash,
    kitResult,
    getKitResult,
    viewingSharedKitResult,
  } = props;
  const { categoryKey, kitResultIdOrPublishHash: kitResultId } = useParams();
  const { getPersonalizations } = useApi();
  const [personalizations, setPersonalizations] = useState<HealthProfileProps>({
    food_sensitivity: [] as Array<string>,
    pcp: null,
    fact_cards: [] as FactCardValues[],
  });
  const categorySelected =
    CARE_PLAN_SECTION_TYPE_MAPPING[
      categoryKey as KitResultCarePlanSectionTypes
    ];

  // load personalizations
  useEffectOnce(() => {
    const loadPersonalizations = async () => {
      try {
        const personalizations: Personalizations = await getPersonalizations();
        setPersonalizations(personalizations?.health_profile);
      } catch (err) {
        logError((err as Error).message, {
          errorInfo: 'Error fetchGraphql fetching Erroring personalizations',
          component: 'NewLayoutKitResultDetailPage',
          method: 'loadPersonalizations',
        });
      }
    };
    if (!viewingSharedKitResult) {
      loadPersonalizations();
    }
  });

  useEffect(() => {
    if (kitResult) return;

    getKitResult({
      kitResultIdOrPublishHash,
      shared: viewingSharedKitResult,
    });
  }, [
    getKitResult,
    kitResult,
    kitResultIdOrPublishHash,
    viewingSharedKitResult,
  ]);

  const { categories, isLoading: isLoadingCategories } = useWellnessScore(
    kitResult?.barcode_serial_number ?? '',
  );

  if (!categorySelected || (kitResultId && !categoryKey)) {
    return <Navigate to={`/results/${kitResultId}`} />;
  }

  if (!categoryKey && !kitResultId) {
    return <Navigate to="/results" />;
  }

  if (!kitResult || isLoadingCategories) {
    return <CategoryPageSkeleton />;
  }

  const { markers, marker_results: markerResults } = kitResult ?? {};

  if (!markers || !markerResults) return null;

  const matchedCategoryKey = Object.entries(
    CARE_PLAN_SECTION_TYPE_MAPPING,
  ).find(([key, value]) => value.text === categorySelected.text)?.[0];

  if (!isLoadingCategories && !categories) {
    return <Navigate to={`/results/${kitResultId}`} />;
  }
  const category = categories.find(
    (i) => i.category_name === matchedCategoryKey,
  );

  if (!category?.markers) {
    return <Navigate to={`/results/${kitResultId}`} />;
  }
  const categoryMarkersIds = category.markers.map((marker) => marker.marker_id);
  const markersToUse = markers.filter((i) => categoryMarkersIds.includes(i.id));
  const markersToUseIds = markersToUse.map((i) => i.id);
  const markerResultsToUse = markerResults.filter((i) =>
    markersToUseIds.includes(i.marker_id),
  );

  if (markersToUse?.length === 0 || markerResultsToUse.length === 0) {
    return <Navigate to={`/results/${kitResultId}`} />;
  }
  const isFoodSensitivity =
    kitResult?.test?.marker_types &&
    kitResult?.test?.marker_types?.includes(MARKER_TYPES.FOOD_SENSITIVITY);
  const conditions = personalizations?.food_sensitivity.filter((condition) =>
    Object.values(FS_PERSONALIZATION_CONDITIONS).includes(condition),
  );

  const controlGroups = _.uniqBy(
    // @ts-ignore
    _.flatten(_.map(markers, 'groupings')),
    'grouping_type',
  );

  const modifiedKitResult: any = {
    ...kitResult,
    marker_results: markerResultsToUse,
    markers: markersToUse,
  };
  modifiedKitResult.marker_results = [];
  const fsKr = _.cloneDeep(modifiedKitResult);
  const descriptiveKr = _.cloneDeep(modifiedKitResult);
  const intensityKr = _.cloneDeep(modifiedKitResult);
  const numericalKr = _.cloneDeep(modifiedKitResult);

  const markerResultGroups = {
    [MARKER_TYPES.FOOD_SENSITIVITY]: fsKr.marker_results,
    [MARKER_TYPES.DESCRIPTIVE]: descriptiveKr.marker_results,
    [MARKER_TYPES.INTENSITY]: intensityKr.marker_results,
    default: numericalKr.marker_results,
  };

  markerResultsToUse?.forEach((markerResult) => {
    // Group here is one of the four objects (fsKr, descriptiveKr, intensityKr, and numericalKr)
    const group =
      markerResultGroups[markerResult.kind as string] ||
      markerResultGroups.default;
    group.push(markerResult);
  });
  const showFsPersonalization = isFoodSensitivity && conditions.length;
  const resultDetails = [];
  if (fsKr.marker_results && fsKr.marker_results.length > 0) {
    resultDetails.push(
      <FoodSensitivityResultDetail
        kitResult={fsKr}
        showFsPersonalization={Boolean(showFsPersonalization)}
        key={fsKr.id}
      />,
    );
  }
  if (descriptiveKr.marker_results && descriptiveKr.marker_results.length > 0) {
    resultDetails.push(
      <DescriptiveResultDetailContainer
        kitResult={descriptiveKr}
        key={descriptiveKr.id}
      />,
    );
  }
  if (intensityKr.marker_results && intensityKr.marker_results.length > 0) {
    resultDetails.push(
      <IntensityResultDetail
        kitResult={intensityKr}
        controlGroups={controlGroups}
        key={intensityKr.id}
      />,
    );
  }
  if (numericalKr.marker_results && numericalKr.marker_results.length > 0) {
    resultDetails.push(
      <NumericalResultDetailContainer
        kitResult={numericalKr}
        viewingSharedKitResult={viewingSharedKitResult}
        key={numericalKr.id}
      />,
    );
  }

  return (
    <Container>
      <Header
        title={categorySelected.text || ''}
        backButton={{
          as: Link,
          to: `/results/${kitResultIdOrPublishHash}`,
          'aria-label': 'Go back to Results',
        }}
      />
      <Box padding={4}>
        <Col
          lg={10}
          lgOffset={1}
          md={10}
          mdOffset={1}
          xs={12}
          data-test="marker_results"
        >
          {kitResult && resultDetails.map((details) => details)}
        </Col>
      </Box>
    </Container>
  );
};
export default CategoryPage;
