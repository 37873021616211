import {
  size,
  colors,
  typography,
  H4,
  Icons,
  mediaQueries,
  breakpoints,
  Col,
} from '@everlywell/leaves';
import styled from 'styled-components';

const SectionWrapper = styled(Col)`
  ${mediaQueries.forDesktopUp} {
    max-width: ${breakpoints.forDesktopDown}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Section = styled.div`
  background: ${colors.white};
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.8);
  padding: ${size.xl1}px ${size.lg}px 0;
  overflow: hidden;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 56px ${size.xl5}px 0;
  }
`;

const SectionHeader = styled.h4`
  ${typography.overlineText};
  margin-bottom: ${size.md}px;
`;

const Intro = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 56px;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const IntroIcon = styled.img`
  ${mediaQueries.forTabletHorizontalUp} {
    order: 2;
    flex: 0 0 ${size.xl5}px;
  }
`;

const IntroCopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
    flex: 0 1 624px;
    flex-flow: row wrap;
  }
`;

const IntroHeader = styled(H4)`
  margin: ${size.lg}px 0 ${size.xs1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    font-weight: 500 !important;
    ${typography.h3Text}
  }
`;

const IntroText = styled.p`
  ${typography.bodyTextSmall}
  color: ${colors.gray5};
  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.subtitle}
    font-weight: 400;
  }
`;

const InfoBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  &:first-child {
    margin-bottom: ${size.lg}px;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 1 1 50%;
    &:last-child {
      margin-left: ${size.xl2}px;
    }
  }
`;

const InfoBlockContent = styled.div`
  margin-left: ${size.md}px;
`;

const InfoBlockTitle = styled.h5`
  ${typography.bodyTextSmall};
  color: ${colors.gray5};
  margin: 0 0 ${size.xs2}px;
  font-weight: ${typography.weight.book};
`;

const InfoBlockText = styled.p`
  ${typography.bodyTextSmall};
  color: ${colors.gray5};
`;

const IconWrapper = styled.div`
  background-color: ${colors.teal1};
  padding: ${size.sm}px;
  border-radius: 50%;
`;

const Icon = styled.img`
  width: ${size.xl1}px;
`;

const ContainerToggleButton = styled.button`
  width: 100%;
  height: 58px;
  position: relative;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.gray1};
  color: ${colors.green5};
  font-style: normal;
  font-size: 18px;
  font-weight: ${typography.weight.bold};
  cursor: pointer;
`;

const UpCaretIcon = styled(Icons.UpCaret)`
  height: 7px;
  width: ${size.sm}px;
  left: ${size.sm}px;
  top: 0;
  margin-left: ${size.sm}px;
`;

export {
  SectionWrapper,
  Section,
  SectionHeader,
  Intro,
  IntroIcon,
  IntroCopyWrapper,
  IntroHeader,
  IntroText,
  InfoBlockContainer,
  InfoBlock,
  InfoBlockContent,
  InfoBlockTitle,
  InfoBlockText,
  IconWrapper,
  Icon,
  ContainerToggleButton,
  UpCaretIcon,
};
