import {
  Box,
  Flex,
  Skeleton,
  SkeletonCircle,
  Container,
} from '@everlywell/ui-kit';
import Layout from 'components/Layout';
import React from 'react';

const CategoryPageSkeleton = () => (
  <Layout>
    <Box width="100%" backgroundColor="tints.cream" padding="6" gap="6">
      <Flex direction="row" justifyContent="space-between" width="100%">
        <SkeletonCircle width="48px" height="48px" marginBottom="64" />
        <Skeleton width="100%" maxWidth="250px" height="40px" />
        <Box width="48px" />
      </Flex>
      <Container marginLeft="auto" marginRight="auto">
        <Flex
          justifyContent="center"
          alignItems="center"
          gap="10"
          direction="column"
        >
          <Flex direction="column" gap="6" width="100%">
            {Array.from({ length: 6 }, (_, i) => i).map((_, index) => (
              <Skeleton key={index} width="100%" height="200px" />
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  </Layout>
);

export default CategoryPageSkeleton;
