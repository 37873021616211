import {
  Icons,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const CheckInWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0 0 ${size.xs1}px ${size.xs1}px;
  margin-bottom: ${size.sm}px;

  ${mediaQueries.forPhoneOnly} {
    flex-wrap: wrap;
  }
`;

const TextIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ToolTipContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-right: ${size.lg}px;
  ${mediaQueries.forPhoneOnly} {
    margin-bottom: ${size.md}px;
  }
`;

const ToolTip = styled.div`
  background-color: ${colors.green1};
  color: ${colors.green5};
  font-size: 14px;
  font-weight: ${typography.weight.bold};
  border: 1px solid ${colors.green3};
  border-radius: ${size.xs1}px;
  padding: ${size.xs1}px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 72%;
    left: calc(0% + ${size.xs2}px);
    border: 11px solid transparent;
    border-bottom-color: ${colors.green3};
    transform: rotate(226deg);
  }

  &:after {
    left: calc(0% + 6px);
    border: 10px solid transparent;
    border-bottom-color: ${colors.green1};
  }

  ${mediaQueries.forTabletVerticalUp} {
    &:before,
    &:after {
      bottom: 75%;
    }
  }
`;

const CheckInQuestion = styled.p`
  color: ${colors.black};
  display: flex;
  align-items: center;
  font-size: ${size.md}px;
  font-weight: ${typography.weight.book};

  ${mediaQueries.forTabletVerticalUp} {
    font-size: ${size.md}px;
  }
`;

const IconImageWrapper = styled.button`
  height: 34px;
  cursor: pointer;
  padding: 0;
  svg {
    width: 34px;
    height: 34px;
    fill: ${colors.white};
    stroke: ${colors.gray3};
  }

  svg:hover {
    fill: ${colors.green1};
    stroke: ${colors.green5};
  }

  :hover {
    border-radius: 50%;
    background-color: ${colors.green1};
  }
`;

const IconsWrapper = styled.div`
  margin: ${size.xs2}px 0 0 ${size.lg}px;
  button:first-child {
    margin-right: ${size.lg}px;
  }
`;

const FeedbackMessage = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  color: ${colors.black};
  padding: ${size.sm}px;
`;

const CheckMark = styled(Icons.CheckMark)`
  background-color: ${colors.green4};
  border-radius: 50%;
  padding: ${size.xs2}px;
  color: ${colors.white};
  width: ${size.lg}px;
  height: ${size.lg}px;
  margin-right: ${size.md}px;
`;

export {
  CheckInQuestion,
  CheckInWrapper,
  CheckMark,
  FeedbackMessage,
  IconImageWrapper,
  IconsWrapper,
  TextIconWrapper,
  ToolTip,
  ToolTipContainer,
};
