import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { SUB_NAVBAR as DATA_TEST } from 'common/utils/constants/dataTest';
import { LEGACY_APP_ROOT } from 'common/utils/constants/urls';
import { isEnterpriseCustomer, isHumanaCustomer } from 'common/utils/helpers';
import FollowUpSubHeaderButton from 'components/SubHeader/FollowUpSubHeaderButton';
import ReferAFriendButton from 'components/SubHeader/ReferAFriendButton';
import * as React from 'react';

import * as S from './styles';

export type Props = {
  kitResultNumber: string;
  testId?: number;
  testName: string;
  shouldShowTelehealthConsult: boolean;
  shouldShowReferFriends?: boolean;
};
// TODO: Use this component here if possible app/components/SubNavbar/SubNavbar.tsx
function SubNavbar(props: Props) {
  const { kitResultNumber, testId, testName, shouldShowTelehealthConsult, shouldShowReferFriends = true } =
    props;
  const doctorPDFUrl = `${LEGACY_APP_ROOT}/results/${kitResultNumber}/doctor_results.pdf`;
  const analyticsAttributes = {
    'data-analytics-event': ANALYTICS.EVENTS.CLICKED_BUTTON,
    'data-analytics-category': ANALYTICS.CATEGORIES.DOWNLOAD_PDF,
    'data-analytics-label': 'Save a PDF',
    'data-analytics-test-id': testId,
  };

  const trackDownload = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.DOWNLOAD_PDF_REPORT,
      },
    });
    window.open(doctorPDFUrl, '_blank');
  };

  const isHumana = isHumanaCustomer();
  const isShowingReferFriends = !isHumana && shouldShowReferFriends;
  const route = isEnterpriseCustomer() ? '/dashboard' : '/results';
  const copy = isEnterpriseCustomer() ? 'Dashboard' : 'Test results';

  return (
    <S.SubNavbar>
      <S.SubNavbarContent>
        <S.ContentLeft>
          <S.BackToDashboard to={route} data-test={DATA_TEST.DASHBOARD_BUTTON}>
            <span>{copy}</span>
          </S.BackToDashboard>
        </S.ContentLeft>
        <S.ContentRight>
          {isShowingReferFriends && <ReferAFriendButton testName={testName} />}
          {shouldShowTelehealthConsult && (
            <FollowUpSubHeaderButton testName={testName} testId={testId} />
          )}
          <S.PdfButton
            data-test={DATA_TEST.SAVE_PDF_BUTTON}
            appearance={'primary'}
            onClick={trackDownload}
            {...analyticsAttributes}
          >
            <S.DownloadIcon />
            <span>Download Results</span>
          </S.PdfButton>
        </S.ContentRight>
      </S.SubNavbarContent>
    </S.SubNavbar>
  );
}

export default SubNavbar;
