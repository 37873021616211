import {
  typography,
  size,
  colors,
  Col,
  Input as RawInput,
  mediaQueries,
  Tooltip,
  Button,
} from '@everlywell/leaves';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${mediaQueries.forDesktopUp} {
    flex-direction: row;
  }
`;

interface CardsProps {}

const AddCardCol = styled(Col)`
  text-align: center;
  ${mediaQueries.forTabletVerticalUp} {
    text-align: right;
  }
`;

const SubTitle = styled.h3`
  font-weight: ${typography.weight.light};
  font-size: 18px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.gray4};
  margin: 0 0 16px;
  grid-column-end: span 4;
  ${mediaQueries.forTabletVerticalUp} {
    grid-row: 1;
    grid-column: 1 / 3;
  }
`;

const PaymentMethods = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${size.md}px;
  grid-auto-flow: dense;
`;

const AcceptedCardsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  max-width: 50%;
`;

const Cards = styled.div<CardsProps>`
  align-self: center;
  display: flex;
  gap: ${size.xs1}px;
`;

const card = css<CardsProps>`
  width: 100%;
  max-width: 52px;
  grid-column-end: span 2;
  ${mediaQueries.forTabletHorizontalUp} {
    grid-column-end: span 1;
  }
`;

const MasterCard = styled.img`
  ${card};
`;

const Visa = styled.img`
  ${card};
`;

const AmericanExpress = styled.img`
  ${card};
`;

const Discover = styled.img`
  ${card};
`;

const Note = styled.div`
  flex: 1;
  display: flex;
  gap: ${size.sm}px;
  ${typography.bodyTextSmall};
  align-items: stretch;
  align-self: self-start;
  grid-column-end: span 8;
  margin-top: ${size.md}px;

  ${mediaQueries.forDesktopUp} {
    margin-top: 0;
    grid-column-end: span 4;
  }
`;

const Tip = styled(Tooltip)`
  .paymentTooltip {
    width: 250px;
  }
`;
const HSA = styled.div`
  /* Noop */
`;

const UpdateButton = styled(Button)`
  grid-column-end: span 4;
  ${mediaQueries.forTabletVerticalUp} {
    grid-row: 1;
    grid-column: 3 / -1;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    grid-row: 1;
    grid-column: 4 / -1;
  }
`;

const BillingOptions = styled.div`
  margin-bottom: ${size.xl1}px;
`;

const BillingOptionRadio = styled.div<{ checked: boolean }>`
  border: 1px solid
    ${(props) => (props.checked ? colors.green4 : colors.green2)};
  padding: ${size.md}px;
  cursor: pointer;
  ${(props) =>
    props.checked ? '' : 'box-shadow: 0 1.5px 10px 0 rgba(0, 0, 0, 0.1)'};
`;

const StripeInputWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: ${size.lg}px;
`;

const StripeLabel = styled.label`
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.dmSans};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.5;
  letter-spacing: normal;
`;

const StripeInputStyles = css`
  color: ${colors.gray4};
  font-family: ${typography.type.dmSans};
  font-size: 18px;
  line-height: 1.78;
  background: ${colors.white};
  border-radius: 1px;
  padding: ${size.xs1}px ${size.md}px;
  border: 1px solid ${colors.green2};
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.1);
  &.StripeElement--focus {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.teal5};
    box-shadow: ${colors.teal5} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.teal4};
    }
  }
  &.StripeElement--invalid {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.red3};
    box-shadow: ${colors.red3} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.red3};
    }
  }
`;

const StripeInput = styled.div<{ name: string }>`
  ${StripeInputStyles}
`;

/* stylelint-disable no-descending-specificity */
const StyledInputContainer = styled.div`
  position: relative;
  padding-bottom: ${size.sm}px;
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: ${size.sm}px;
    margin-bottom: 0;
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 2%;
  top: 33%;
  color: ${colors.green4};
  ${mediaQueries.forTabletHorizontalDown} {
    top: 33%;
    margin-right: 10px;
  }
  ${mediaQueries.forPhoneOnly} {
    top: 33%;
    margin-right: 10px;
  }
`;

const CvcTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 295px;
    height: 110px;
    padding: ${size.xs1}px ${size.sm}px;
    ${mediaQueries.forPhoneOnly} {
      width: 150px;
      height: 210px;
      padding: ${size.xs1}px ${size.sm}px;
    }
  }
`;

const ModalHeading = styled.header`
  text-align: center;
  margin-bottom: ${size.lg}px;
`;
const ModalTitle = styled.h4`
  ${typography.displayText};
  margin-bottom: ${size.xs1}px;
  color: ${colors.gray4};
`;
const ModalSubTitle = styled.p`
  ${typography.bodyTextSmall};
  color: ${colors.gray4};
`;

const UpdateForm = styled.form`
  margin: ${size.lg}px 0;
`;

const Wrapper = styled.div`
  /* noop */
`;

const SaveButton = styled(Button)`
  outline: none;
  align-self: center;
  margin-top: 38px;
  font-size: 18.7px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 230px;
  height: 50px;
  border-radius: 0;
  background: ${colors.green5};
  position: relative;

  &[disabled] {
    border-color: transparent;
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08);
    color: ${colors.gray3};
    font-weight: ${typography.weight.bold};
  }

  &.loading {
    background: ${colors.green5} !important;
  }
`;

const Input = styled(RawInput)`
  svg {
    fill: none;
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    stroke-width: 5;
    display: none;
  }
`;

export {
  AcceptedCardsContainer,
  AddCardCol,
  AmericanExpress,
  BillingOptionRadio,
  BillingOptions,
  Cards,
  Container,
  CvcTooltip,
  Discover,
  HSA,
  Input,
  MasterCard,
  ModalHeading,
  ModalSubTitle,
  ModalTitle,
  Note,
  PaymentMethods,
  SaveButton,
  StripeInput,
  StripeInputStyles,
  StripeInputWrapper,
  StripeLabel,
  StyledInputContainer,
  SubTitle,
  Tip,
  TooltipWrapper,
  UpdateButton,
  UpdateForm,
  Visa,
  Wrapper,
};
