import { size, typography } from '@everlywell/leaves';
import { API_ROOT } from 'common/utils/constants';
import { defaultSanitizeOptions, sanitize } from 'common/utils/domHelpers';
import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import React from 'react';

import { rgbErrorRed } from '../../../Field/utils/constants';

export type HtmlParserProps = {
  /** This is the text that is displayed in the component */
  html: string;
  isInlineError?: boolean;
};

const { ALLOWED_TAGS, ALLOWED_ATTR } = defaultSanitizeOptions;

// https://github.com/remarkablemark/html-react-parser/issues/633
function domNodeIsElement(domNode: DOMNode): domNode is Element {
  return domNode.type === 'tag';
}

function HtmlParser(props: HtmlParserProps) {
  const { html, isInlineError } = props;

  return (
    <>
      {parse(
        sanitize(html, {
          ALLOWED_TAGS: ['span', 'strong'].concat(ALLOWED_TAGS as string[]),
          ALLOWED_ATTR: ['style'].concat(ALLOWED_ATTR as string[]),
        }),
        {
          replace: (domNode) => {
            if (!domNodeIsElement(domNode)) return;

            if (isInlineError) {
              domNode.attribs.style = `${
                domNode?.attribs?.style || ''
              } margin-top: -${size.xl1}px;`;

              domNode.attribs?.style?.replace(
                /color: rgb\(\s*(?:(\d{1,3})\s*,?){3}\)/i,
                `color: rgb(${rgbErrorRed})`,
              );

              if (domNode.name !== 'strong') {
                domNode.attribs.style = `${
                  domNode?.attribs?.style || ''
                } font-weight: ${typography.weight.regular};`;
              }
            }

            if (domNode.name === 'a') {
              let href = domNode.attribs.href;

              // extract document id from href or data attribute
              const documentId = href?.match(
                /https?:\/\/.*\/documents\/(\w*)\/download/i,
              )?.[1];

              if (documentId) {
                href = `${API_ROOT}/v2/telehealth/documents/${documentId}`;
              }

              return (
                <a {...domNode.attribs} href={href}>
                  {domToReact(domNode.children as DOMNode[])}
                </a>
              );
            }
          },
        },
      )}
    </>
  );
}

export default HtmlParser;
