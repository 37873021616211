import { KitResultCarePlan } from 'common/apis/telehealthApis';

const baseCarePlan: KitResultCarePlan = {
  heart: {
    insights: '',
    actions: `<ul>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
      </ul>`,
  },
  nutrition: {
    insights: 'Insights',
    actions: `<ul>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
      </ul>`,
  },
  hormone: {
    insights: 'Insights',
    actions: `<ul>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
      </ul>`,
  },
  immune: {
    insights: 'Insights',
    actions: `<ul>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
      </ul>`,
  },
  inflammation: {
    insights: 'Insights',
    actions: `<ul>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
      </ul>`,
  },
  liver: {
    insights: 'Insights',
    actions: `<ul>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
        <li>The way you eat (and drink) can help you manage symptoms and promote healthy hormone levels</li>
      </ul>`,
  },
  recommend: {
    food: `<ul><li>Follow an anti-inflammatory diet, emphasizing whole foods, healthy fats, and antioxidant-rich vegetables.</li>
      <li>Limit processed sugars and refined carbs to improve metabolic health and reduce inflammation.</li>
      <li>Increase lean proteins, healthy fats, and fiber-rich foods for heart, hormone, and immune balance.</li>
      <li>Add fermented foods (yogurt, kimchi, sauerkraut) to support gut and immune health</li></ul>`,
    supplement: `<ul><li>Follow an anti-inflammatory diet, emphasizing whole foods, healthy fats, and antioxidant-rich vegetables.</li>
      <li>Limit processed sugars and refined carbs to improve metabolic health and reduce inflammation.</li>
      <li>Increase lean proteins, healthy fats, and fiber-rich foods for heart, hormone, and immune balance.</li></ul>`,
    exercise: `<ul><li>Follow an anti-inflammatory diet, emphasizing whole foods, healthy fats, and antioxidant-rich vegetables.</li>
      <li>Limit processed sugars and refined carbs to improve metabolic health and reduce inflammation.</li>
      <li>Increase lean proteins, healthy fats, and fiber-rich foods for heart, hormone, and immune balance.</li>
      <li>Add fermented foods (yogurt, kimchi, sauerkraut) to support gut and immune health</li></ul>`,
    lifestyle: `<ul><li>Follow an anti-inflammatory diet, emphasizing whole foods, healthy fats, and antioxidant-rich vegetables.</li>
      <li>Limit processed sugars and refined carbs to improve metabolic health and reduce inflammation.</li>
      <li>Increase lean proteins, healthy fats, and fiber-rich foods for heart, hormone, and immune balance.</li></ul>`,
  },
  summary: `<strong>This is an updated example of the assessment and plan.</strong> <br />
    The patient has shown significant improvement in their condition. Continued monitoring and follow-up
    appointments are recommended to ensure ongoing progress. <br />Please adhere to the prescribed
    medication and lifestyle changes for optimal results.`,
  locked_at: '2022-06-29T14:45:00-05:00',
};

export const buildCarePlan = (
  overrideProps: Partial<KitResultCarePlan> = {},
) => ({
  ...baseCarePlan,
  ...overrideProps,
});
