import { size, colors, typography, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${mediaQueries.forDesktopUp} {
    justify-content: flex-start;
    height: auto;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${size.xl8}px;

  ${mediaQueries.forDesktopUp} {
    padding-bottom: ${size.sm}px;
  }
`;

export const Title = styled.span`
  width: 100%;
  ${typography.overlineText};
  margin-bottom: ${size.md}px;
`;

export const Header = styled.span`
  width: 100%;
  ${typography.h4Text};
  margin-bottom: ${size.xs1}px;
`;

export const SubHeader = styled.span`
  width: 100%;
  ${typography.bodyText};
  margin-bottom: ${size.xl3}px;

  ${mediaQueries.forDesktopUp} {
    margin-bottom: ${size.xl1}px;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  margin-bottom: ${size.xl1}px;
`;

export const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-bottom: ${size.md}px;
`;

export const ProductImage = styled.img`
  width: 170px;
  padding-left: ${size.md}px;
  padding-right: ${size.lg}px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductName = styled.span`
  ${typography.bodyText};
  color: ${colors.green4};
  font-weight: ${typography.weight.bold};
`;

export const DeliveryEstimation = styled.span`
  ${typography.captionText};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: ${size.md}px ${size.md}px;
  width: 100%;
  box-shadow: 0 -3px 19px rgba(0, 0, 0, 0.08);
  position: fixed;
  left: -1px;
  bottom: 0;

  > button {
    flex: 1;
  }

  ${mediaQueries.forDesktopUp} {
    position: initial;
    padding: 0;
    margin-top: ${size.md}px;
    box-shadow: none;
  }
`;
