import { colors, Information, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const ScreenerNoteBox = styled.div`
  background-color: ${colors.green1};
  height: auto;
  width: 100%;
  margin: ${size.xl1}px 0;
  padding: ${size.md}px;
  border-radius: ${size.xs2}px;
  display: flex;
`;

const ScreenerNoteText = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
  margin: 0 0 0 ${size.xs1}px;
`;

const Icon = styled(Information)`
  color: ${colors.teal4};
  height: ${size.lg};
  width: ${size.lg};
`;

const Image = styled.img`
  width: ${size.lg}px;
  margin-bottom: ${size.lg}px;
`;

export { ScreenerNoteBox, ScreenerNoteText, Icon, Image };
