import React, { useState, useEffect } from 'react';

import * as S from './styles';

export type Props = {
  boundaryRange: number;
  descriptor: string;
  markerResultPosition: number;
  index: number;
  isClassStyle: boolean;
  last: number;
  lastItemIndex?: number;
  rangeColors?: any;
  rangeLabelPosition?: string;
  totalDescriptors: number;
};

export const RangeLabel = (props: Props) => {
  const {
    boundaryRange,
    descriptor,
    isClassStyle,
    index,
    last,
    rangeColors,
    markerResultPosition,
    rangeLabelPosition,
    totalDescriptors,
  } = props;

  const [labelPosition, setLabelPosition] = useState('center');
  const lastItemIndex = totalDescriptors - 1;
  const isLastItem = index === lastItemIndex;
  const label = isClassStyle ? `Class ${index}` : descriptor;

  // Checks if this Range contains the Marker Result Position
  const isMarkerResultRange =
    markerResultPosition >= last &&
    markerResultPosition <= last + boundaryRange;
  const shouldOpaqueLabel = isClassStyle && !isMarkerResultRange;

  const updateLabelPosition = () => {
    if (isClassStyle) {
      setLabelPosition('center');
    } else if (rangeLabelPosition) {
      setLabelPosition(rangeLabelPosition);
    } else if (index <= 0) {
      setLabelPosition('left');
    } else if (isLastItem) {
      setLabelPosition('right');
    }
  };

  useEffect(() => {
    updateLabelPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Range
        className={labelPosition}
        color={rangeColors?.[index]?.toString() || undefined}
        key={`range_${index?.toString() || 'unknown'}`}
        start={last}
        width={boundaryRange}
      >
        <S.RangeLabel isOpaque={shouldOpaqueLabel} className={labelPosition}>
          {label}
        </S.RangeLabel>
      </S.Range>
      {isLastItem ? null : (
        <S.RangeSeparator start={last} width={boundaryRange} />
      )}
    </>
  );
};
