import {
  Button as LeavesButton,
  colors,
  H3,
  Input as RawInput,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Title = styled(H3)`
  ${typography.h2Text}
  font-weight: ${typography.weight.book};
  color: ${colors.teal4};
  margin: 0 0 ${size.lg}px;
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.md}px;
    max-width: 100%;
  }
`;

const SubTitle = styled.h3`
  margin-bottom: ${size.md}px;
  ${typography.overlineText};
  color: ${colors.green4};
`;

const Box = styled.div`
  padding: ${size.xl1}px ${size.md}px;
  border: 1px solid ${colors.green2};

  ${mediaQueries.forDesktopUp} {
    padding: ${size.xl1}px;
  }
`;

export const Button = styled(LeavesButton)({
  width: '100%',

  [mediaQueries.forTabletHorizontalUp]: {
    width: 'auto',
    minWidth: '210px',
  },
});

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    flex: 1 0 100%;
    justify-content: end;
  }
`;

const HiddenInput = styled(RawInput)`
  display: none;
`;

const InvalidAddress = styled.div`
  color: ${colors.red3};
`;

const Input = styled(RawInput)`
  svg {
    fill: none;
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
    stroke-width: 5;
    display: none;
  }
`;

export {
  ButtonRow,
  Title,
  SubTitle,
  Box,
  HiddenInput,
  InvalidAddress,
  Input,
  Wrapper,
};
