import { theme, Box, Flex, Text } from '@everlywell/ui-kit';
import { sanitize } from 'common/utils/domHelpers';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import React from 'react';

const { fontWeights } = theme;

export type ClinicianSummaryProps = {
  date: string;
  name: string;
  summary: string;
};

function ClinicianSummary(props: ClinicianSummaryProps) {
  const { date, name, summary } = props;
  const formattedSummary = summary ? parse(sanitize(summary)) : '';
  const formattedDate = date ? format(new Date(date), 'MM/dd/yy') : '';
  return (
    <Box
      backgroundColor="tints.lightCream"
      border="none"
      borderRadius="1.25rem"
      overflow="hidden"
      padding="6"
    >
      <Flex direction="column" gap="4">
        <Flex direction="row" justifyContent="space-between">
          <Text
            fontSize="md"
            color="tints.uiGrey"
            fontWeight={fontWeights.normal}
          >
            {name}
          </Text>
          <Text
            as="span"
            color="tints.uiGrey"
            fontSize="md"
            fontWeight={fontWeights.normal}
          >
            {formattedDate}
          </Text>
        </Flex>
        <Text
          as="span"
          color="tints.black"
          fontSize="md"
          fontWeight={fontWeights.normal}
        >
          {formattedSummary}
        </Text>
      </Flex>
    </Box>
  );
}

export default ClinicianSummary;
