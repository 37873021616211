import {
  WellnessScoreCategory,
  WellnessScoreCategoryMarker,
} from 'common/apis/telehealthApis';
import { WellnessCircleProps } from 'components/WellnessCircle/WellnessCircle';

export const CATEGORY_KEYS_MAPPING = {
  heart: 'Heart and Metabolic',
  nutrition: 'Nutritional',
  hormone: 'Hormone and Sexual',
  immune: 'Immune and Blood Cell',
  inflammation: 'Inflammation and Autoimmunity',
  liver: 'Liver, Kidney and Bladder',
};

export const groupCategories = (categories: WellnessScoreCategory[]) =>
  categories.map((category) => {
    const markers = category.markers.reduce(
      (acc, marker) => {
        if (marker.status === 'Abnormal') {
          acc.abnormal.push(marker);
        } else if (marker.status === 'Normal') {
          acc.normal.push(marker);
        } else {
          acc.dnr.push(marker);
        }
        return acc;
      },
      {
        normal: [] as WellnessScoreCategoryMarker[],
        abnormal: [] as WellnessScoreCategoryMarker[],
        dnr: [] as WellnessScoreCategoryMarker[],
      },
    );

    const categoryName =
      category.category_name as keyof typeof CATEGORY_KEYS_MAPPING;
    const name = CATEGORY_KEYS_MAPPING?.[categoryName] ?? categoryName;

    return {
      key: category.category_name,
      markers,
      name,
    };
  });

export const normalizeWellnessCircleCategories = (
  categories: WellnessScoreCategory[],
): WellnessCircleProps['categories'] =>
  categories.map((category) => ({
    name:
      CATEGORY_KEYS_MAPPING?.[
        category.category_name as keyof typeof CATEGORY_KEYS_MAPPING
      ] ?? category.category_name,
    status:
      (category.status?.toLocaleLowerCase() as 'normal' | 'abnormal') ??
      'normal',
    markers: category.markers.map((marker) => ({
      id: marker.marker_id,
      name: marker.name,
      status:
        marker.status === null
          ? 'dnr'
          : (marker.status.toLocaleLowerCase() as
              | 'normal'
              | 'dnr'
              | 'abnormal'),
    })),
  }));
