import {
  typography,
  colors,
  size,
  H2,
  Container,
  mediaQueries,
} from '@everlywell/leaves';
import { sectionTitle } from 'common/styles/app-styles';
import styled from 'styled-components';

const WelcomeText = styled(H2)`
  ${sectionTitle as any};
  color: ${colors.gray4};
  font-size: ${size.xl1}px;
  font-weight: ${typography.weight.book};
  letter-spacing: 0;
  margin: 0;
  ${mediaQueries.forTabletVerticalDown} {
    font-size: ${size.lg}px;
    text-align: center;
  }
`;

const HeaderTitle = styled('div')`
  align-items: flex-end;
  display: flex;
  ${mediaQueries.forTabletVerticalDown} {
    justify-content: center;
  }
`;

const IconImage = styled.img`
  height: 100%;
  width: auto;
`;

const ImageWrapper = styled.div`
  height: 38px;
  margin-bottom: ${size.md}px;
  overflow: hidden;
  width: 50px;

  ${mediaQueries.forTabletVerticalDown} {
    height: ${size.xl1}px;
    margin-top: ${size.xl3}px;
    padding: 0;
    width: ${size.xl2}px;
  }
`;

const PageHeaderWrapper = styled.div`
  background-color: ${colors.white};
`;

const DashboardContainer = styled(Container)<{
  isThirdParty: boolean;
}>`
  background-color: ${colors.white};
  background-size: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin-top: -1px;
  min-height: 476px;
  width: 100%;

  ${mediaQueries.forTabletVerticalDown} {
    padding: ${({ isThirdParty }) => (isThirdParty ? `${size.md}px 7%` : `0`)};
  }

  ${mediaQueries.forTabletHorizontalDown} {
    padding: 0;
  }

  ${mediaQueries.forDesktopUp} {
    padding: ${({ isThirdParty }) =>
      isThirdParty ? `${size.md}px 7% ${size.xl3}px` : ``};
  }
`;

const KitListContainer = styled.div<{
  isThirdParty: boolean;
}>`
  background-color: ${colors.white};
  background-size: 100%;
  display: flex;
  flex-direction: column;
  min-height: 476px;
  padding: ${size.lg}px ${size.md}px 0;

  ${mediaQueries.forTabletVerticalDown} {
    display: flex;
    flex-direction: column;
  }
  ${mediaQueries.forTabletHorizontalUp} {
    min-height: 902px;
  }
  ${mediaQueries.forTabletHorizontalDown} {
    min-height: 690px;
    padding: ${size.lg}px 7% 0;
    width: 100%;
  }
  ${mediaQueries.forPhoneOnly} {
    min-height: 530px;
    padding: ${size.md}px;
  }
  ${mediaQueries.forDesktopUp} {
    margin: ${({ isThirdParty }) => (isThirdParty ? '0 auto' : '0')};
    min-height: 659px;
  }
  ${mediaQueries.forTabletVerticalUp} {
    min-width: 803px;
  }
`;

const CardTitle = styled.div`
  color: ${colors.green5};
  font-size: 14.2px;
  font-weight: bold;
  height: ${size.md}px;
  letter-spacing: 3.16px;
  line-height: 1.13;
  margin: 0 129px ${size.sm}px 0;
  width: 214px;
`;

const KitListContent = styled.div<{
  isThirdParty?: boolean;
  isHealthPlan?: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${mediaQueries.forPhoneOnly} {
    width: 100%;
  }
  ${mediaQueries.forTabletVerticalUp} {
    margin: ${({ isThirdParty, isHealthPlan }) =>
      isThirdParty || isHealthPlan ? '0 auto' : '0'};
    max-width: ${({ isThirdParty, isHealthPlan }) =>
      isThirdParty || isHealthPlan ? '1157px' : '100%'};
  }
`;

const Banner = styled.div`
  width: 100%;
`;

const KitsAndMemberships = styled.div<{
  hasMembership: boolean;
}>`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  flex-direction: ${({ hasMembership }) =>
    hasMembership ? 'column-reverse' : 'column'};

  ${mediaQueries.forTabletVerticalUp} {
    flex-flow: row wrap;
  }
`;

export {
  CardTitle,
  DashboardContainer,
  HeaderTitle,
  IconImage,
  ImageWrapper,
  KitListContainer,
  KitListContent,
  PageHeaderWrapper,
  WelcomeText,
  Banner,
  KitsAndMemberships,
};
