import { WellnessScore } from 'common/apis/telehealthApis';

const baseWellnessScore: WellnessScore = {
  wellness_score: 100,
  categories: [
    {
      category_name: 'heart',
      status: 'Normal',
      markers: [
        {
          marker_id: 2302,
          name: 'CHOLESTEROL, TOTAL',
          status: 'Normal',
        },
        {
          marker_id: 2306,
          name: 'CHOL/HDLC RATIO',
          status: 'Normal',
        },
        {
          marker_id: 2238,
          name: 'GLUCOSE',
          status: 'Normal',
        },
        {
          marker_id: 2303,
          name: 'HDL CHOLESTEROL',
          status: 'Normal',
        },
        {
          marker_id: 2265,
          name: 'HEMOGLOBIN A1c',
          status: 'Normal',
        },
        {
          marker_id: 2240,
          name: 'HOMOCYSTEINE',
          status: 'Normal',
        },
        {
          marker_id: 2295,
          name: 'INSULIN',
          status: 'Normal',
        },
        {
          marker_id: 2305,
          name: 'LDL-CHOLESTEROL',
          status: 'Normal',
        },
        {
          marker_id: 2243,
          name: 'LIPOPROTEIN (a)',
          status: 'Normal',
        },
        {
          marker_id: 2307,
          name: 'NON HDL CHOLESTEROL',
          status: 'Normal',
        },
        {
          marker_id: 2304,
          name: 'TRIGLYCERIDES',
          status: 'Normal',
        },
        {
          marker_id: 2313,
          name: 'URIC ACID',
          status: 'Normal',
        },
      ],
    },
    {
      category_name: 'hormone',
      status: 'Abnormal',
      markers: [
        {
          marker_id: 2258,
          name: 'ANTI-MULLERIAN HORMONE (AMH), FEMALE',
          status: 'Normal',
        },
        {
          marker_id: 2370,
          name: 'CORTISOL, TOTAL',
          status: 'Abnormal',
        },
        {
          marker_id: 2259,
          name: 'DHEA SULFATE',
          status: 'Abnormal',
        },
        {
          marker_id: 2330,
          name: 'ESTRADIOL,ULTRASENSITIVE, LC/MS',
          status: 'Abnormal',
        },
        {
          marker_id: 2357,
          name: 'ESTRONE',
          status: 'Abnormal',
        },
        {
          marker_id: 2263,
          name: 'FSH',
          status: 'Normal',
        },
        {
          marker_id: 2299,
          name: 'LH',
          status: 'Normal',
        },
        {
          marker_id: 2236,
          name: 'T3, FREE',
          status: 'Abnormal',
        },
        {
          marker_id: 2308,
          name: 'T4, FREE',
          status: 'Abnormal',
        },
        {
          marker_id: 2251,
          name: 'TESTOSTERONE, TOTAL, MS',
          status: 'Abnormal',
        },
        {
          marker_id: 2309,
          name: 'TSH',
          status: 'Abnormal',
        },
      ],
    },
    {
      category_name: 'immune',
      status: 'Normal',
      markers: [
        {
          marker_id: 2163,
          name: 'ABSOLUTE BAND NEUTROPHILS',
          status: null,
        },
        {
          marker_id: 2179,
          name: 'ABSOLUTE BASOPHILS',
          status: null,
        },
        {
          marker_id: 2181,
          name: 'ABSOLUTE BLASTS',
          status: null,
        },
        {
          marker_id: 2177,
          name: 'ABSOLUTE EOSINOPHILS',
          status: 'Normal',
        },
        {
          marker_id: 2173,
          name: 'ABSOLUTE LYMPHOCYTES',
          status: null,
        },
        {
          marker_id: 2167,
          name: 'ABSOLUTE METAMYELOCYTES',
          status: null,
        },
        {
          marker_id: 2175,
          name: 'ABSOLUTE MONOCYTES',
          status: null,
        },
        {
          marker_id: 2169,
          name: 'ABSOLUTE MYELOCYTES',
          status: null,
        },
        {
          marker_id: 2164,
          name: 'ABSOLUTE NEUTROPHILS',
          status: null,
        },
        {
          marker_id: 2183,
          name: 'ABSOLUTE NUCLEATED RBC',
          status: null,
        },
        {
          marker_id: 2171,
          name: 'ABSOLUTE PROMYELOCYTES',
          status: null,
        },
        {
          marker_id: 2162,
          name: 'BAND NEUTROPHILS',
          status: null,
        },
        {
          marker_id: 2178,
          name: 'BASOPHILS',
          status: 'Normal',
        },
        {
          marker_id: 2180,
          name: 'BLASTS',
          status: 'Normal',
        },
        {
          marker_id: 2176,
          name: 'EOSINOPHILS',
          status: 'Normal',
        },
        {
          marker_id: 2154,
          name: 'HEMATOCRIT',
          status: 'Normal',
        },
        {
          marker_id: 2152,
          name: 'HEMOGLOBIN',
          status: 'Normal',
        },
        {
          marker_id: 2165,
          name: 'LYMPHOCYTES',
          status: 'Normal',
        },
        {
          marker_id: 2157,
          name: 'MCH',
          status: 'Normal',
        },
        {
          marker_id: 2158,
          name: 'MCHC',
          status: 'Normal',
        },
        {
          marker_id: 2156,
          name: 'MCV',
          status: 'Normal',
        },
        {
          marker_id: 2166,
          name: 'METAMYELOCYTES',
          status: null,
        },
        {
          marker_id: 2174,
          name: 'MONOCYTES',
          status: 'Normal',
        },
        {
          marker_id: 2184,
          name: 'MPV',
          status: 'Normal',
        },
        {
          marker_id: 2168,
          name: 'MYELOCYTES',
          status: null,
        },
        {
          marker_id: 2161,
          name: 'NEUTROPHILS',
          status: 'Normal',
        },
        {
          marker_id: 2182,
          name: 'NUCLEATED RBC',
          status: null,
        },
        {
          marker_id: 2160,
          name: 'PLATELET COUNT',
          status: 'Normal',
        },
        {
          marker_id: 2170,
          name: 'PROMYELOCYTES',
          status: null,
        },
        {
          marker_id: 2159,
          name: 'RDW',
          status: 'Normal',
        },
        {
          marker_id: 2172,
          name: 'REACTIVE LYMPHOCYTES',
          status: null,
        },
        {
          marker_id: 2150,
          name: 'RED BLOOD CELL COUNT',
          status: 'Normal',
        },
        {
          marker_id: 2149,
          name: 'WHITE BLOOD CELL COUNT',
          status: 'Normal',
        },
      ],
    },
    {
      category_name: 'inflammation',
      status: 'Abnormal',
      markers: [
        {
          marker_id: 2317,
          name: 'ANA PATTERN',
          status: 'Abnormal',
        },
        {
          marker_id: 2225,
          name: 'ANA SCREEN, IFA',
          status: 'Abnormal',
        },
        {
          marker_id: 2316,
          name: 'ANA TITER',
          status: 'Abnormal',
        },
        {
          marker_id: 2354,
          name: 'CENTROMERE B ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2355,
          name: 'CHROMATIN (NUCLEOSOMAL) ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2356,
          name: 'DNA (DS) ANTIBODY',
          status: 'Abnormal',
        },
        {
          marker_id: 2239,
          name: 'HS CRP',
          status: 'Abnormal',
        },
        {
          marker_id: 2360,
          name: 'JO-1 ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2362,
          name: 'RIBOSOMAL P ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2363,
          name: 'RNP ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2364,
          name: 'SCL-70 ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2365,
          name: "SJOGREN'S ANTIBODY (SS-A)",
          status: 'Normal',
        },
        {
          marker_id: 2366,
          name: "SJOGREN'S ANTIBODY (SS-B)",
          status: 'Normal',
        },
        {
          marker_id: 2367,
          name: 'SM ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2368,
          name: 'SM/RNP ANTIBODY',
          status: 'Normal',
        },
        {
          marker_id: 2331,
          name: 'THYROGLOBULIN ANTIBODIES',
          status: 'Abnormal',
        },
        {
          marker_id: 2266,
          name: 'THYROID PEROXIDASE ANTIBODIES',
          status: 'Abnormal',
        },
      ],
    },
    {
      category_name: 'liver',
      status: 'Normal',
      markers: [
        {
          marker_id: 2222,
          name: 'ALBUMIN',
          status: 'Normal',
        },
        {
          marker_id: 2223,
          name: 'ALBUMIN/GLOBULIN RATIO',
          status: 'Normal',
        },
        {
          marker_id: 2224,
          name: 'ALKALINE PHOSPHATASE',
          status: 'Normal',
        },
        {
          marker_id: 2221,
          name: 'ALT',
          status: 'Normal',
        },
        {
          marker_id: 2268,
          name: 'APPEARANCE',
          status: 'Normal',
        },
        {
          marker_id: 2226,
          name: 'AST',
          status: 'Normal',
        },
        {
          marker_id: 2283,
          name: 'BACTERIA',
          status: null,
        },
        {
          marker_id: 2272,
          name: 'BILIRUBIN',
          status: 'Normal',
        },
        {
          marker_id: 2246,
          name: 'BILIRUBIN, TOTAL',
          status: 'Normal',
        },
        {
          marker_id: 2228,
          name: 'BUN/CREATININE RATIO',
          status: 'Normal',
        },
        {
          marker_id: 2267,
          name: 'COLOR',
          status: 'Normal',
        },
        {
          marker_id: 2233,
          name: 'CREATININE',
          status: 'Normal',
        },
        {
          marker_id: 2234,
          name: 'EGFR',
          status: 'Normal',
        },
        {
          marker_id: 2264,
          name: 'GGT',
          status: 'Normal',
        },
        {
          marker_id: 2237,
          name: 'GLOBULIN',
          status: 'Normal',
        },
        {
          marker_id: 2271,
          name: 'GLUCOSE',
          status: 'Normal',
        },
        {
          marker_id: 2289,
          name: 'HYALINE CAST',
          status: null,
        },
        {
          marker_id: 2273,
          name: 'KETONES',
          status: 'Normal',
        },
        {
          marker_id: 2277,
          name: 'LEUKOCYTE ESTERASE',
          status: 'Normal',
        },
        {
          marker_id: 2276,
          name: 'NITRITE',
          status: 'Normal',
        },
        {
          marker_id: 2274,
          name: 'OCCULT BLOOD',
          status: 'Normal',
        },
        {
          marker_id: 2270,
          name: 'PH',
          status: 'Normal',
        },
        {
          marker_id: 2275,
          name: 'PROTEIN',
          status: 'Normal',
        },
        {
          marker_id: 2250,
          name: 'PROTEIN, TOTAL',
          status: 'Normal',
        },
        {
          marker_id: 2279,
          name: 'RBC',
          status: null,
        },
        {
          marker_id: 2269,
          name: 'SPECIFIC GRAVITY',
          status: 'Normal',
        },
        {
          marker_id: 2280,
          name: 'SQUAMOUS EPITHELIAL CELLS',
          status: null,
        },
        {
          marker_id: 2227,
          name: 'UREA NITROGEN (BUN)',
          status: 'Normal',
        },
        {
          marker_id: 2278,
          name: 'WBC',
          status: null,
        },
      ],
    },
    {
      category_name: 'nutrition',
      status: 'Abnormal',
      markers: [
        {
          marker_id: 2229,
          name: 'CALCIUM',
          status: 'Normal',
        },
        {
          marker_id: 2230,
          name: 'CARBON DIOXIDE',
          status: 'Normal',
        },
        {
          marker_id: 2232,
          name: 'CHLORIDE',
          status: 'Normal',
        },
        {
          marker_id: 2235,
          name: 'FERRITIN',
          status: 'Abnormal',
        },
        {
          marker_id: 2249,
          name: 'IRON BINDING CAPACITY',
          status: 'Abnormal',
        },
        {
          marker_id: 2241,
          name: 'IRON, TOTAL',
          status: 'Abnormal',
        },
        {
          marker_id: 2300,
          name: 'MAGNESIUM',
          status: 'Abnormal',
        },
        {
          marker_id: 2244,
          name: 'POTASSIUM',
          status: 'Abnormal',
        },
        {
          marker_id: 2242,
          name: '% SATURATION',
          status: 'Normal',
        },
        {
          marker_id: 2245,
          name: 'SODIUM',
          status: 'Abnormal',
        },
        {
          marker_id: 2314,
          name: 'VITAMIN B12',
          status: 'Abnormal',
        },
        {
          marker_id: 2252,
          name: 'VITAMIN D,25-OH,TOTAL,IA',
          status: 'Normal',
        },
        {
          marker_id: 2315,
          name: 'ZINC',
          status: 'Normal',
        },
      ],
    },
  ],
};

export const buildWellnessScore = (
  overrideProps: Partial<WellnessScore> = {},
) => ({
  ...baseWellnessScore,
  ...overrideProps,
});
