import { typography, size, colors, mediaQueries } from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';

const bodyCopy = css`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  font-stretch: normal;
`;

const bodyCopySmall = css`
  font-size: 14.2px;
  line-height: 1.69;
  letter-spacing: 1px;
  font-stretch: normal;
`;

const bodyCopyLarge = css`
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.7px;
  font-stretch: normal;
`;

const pageTitle = css`
  font-size: 32.4px;
  line-height: 1.48;
  letter-spacing: 0.1px;
  font-stretch: normal;
`;

const sectionTitle = css`
  font-size: 20.2px;
  line-height: 1.58;
  letter-spacing: 0.6px;
  font-stretch: normal;
`;

const sectionTitleLarge = css`
  font-size: 32.4px;
  line-height: 1.23;
  letter-spacing: 0.1px;
  font-stretch: normal;
`;

const sectionTitleExtraLarge = css`
  font-size: 52px;
  line-height: 69px;
  letter-spacing: normal;
  font-stretch: normal;
`;

const modalContentTitle = css`
  font-size: 22.8px;
  line-height: 32px;
  letter-spacing: normal;
  font-stretch: normal;
`;

// Wrapper for rounded cards, this is used on all the results details page
const blackHex = hexToRGB(colors.black);
const CardContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  color: ${colors.gray4};
  background-color: ${colors.white};
  margin-bottom: 24px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0 rgba(${blackHex}, 0.05);
  font-weight: ${typography.weight.regular};
  overflow: hidden;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 32px;
  }
`;

const InnerContent = styled.div`
  width: 86%;
  margin: 0 auto;

  ${mediaQueries.forDesktopUp} {
    width: calc(100% - 288px);
  }
`;

const ArrowDown = css`
  padding: 0;
  margin: 6px 0 0 6px;
  border: solid ${colors.white};
  border-width: 6px;
  border-top-color: ${colors.green4};

  &:hover {
    border-top-color: ${colors.green3};
  }
`;

const ArrowUp = css`
  padding: 0;
  margin: 0 0 6px 6px;
  border: solid ${colors.white};
  border-width: 6px;
  border-bottom-color: ${colors.green4};

  &:hover {
    border-bottom-color: ${colors.green3};
  }
`;

const baseFont = '"DM Sans", Helvetica, Arial, sans-serif';

export {
  bodyCopy,
  bodyCopySmall,
  bodyCopyLarge,
  pageTitle,
  sectionTitle,
  sectionTitleLarge,
  sectionTitleExtraLarge,
  InnerContent,
  CardContainer,
  modalContentTitle,
  ArrowDown,
  ArrowUp,
  baseFont,
};
