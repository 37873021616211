import { typography, mediaQueries, size } from '@everlywell/leaves';
import styled from 'styled-components';

/**
 * Marker Chart legacy colors
 */
const coral = '#e97f72';
const markerChartBoundaryBadge = '#484848';
const deepTeal = '#003133';
const primaryTextColor = '#002021';
const markerChartBoundary = '#a2a2a2';

export const MarkerChartWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 40px;
  margin-top: 40px;
  padding-top: 10px;
  position: relative;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 75px;
  }
`;

type ResultMarkerWrapperProps = {
  percentage: number;
};
export const ResultMarkerWrapper = styled.div<ResultMarkerWrapperProps>`
  height: 32px;
  left: ${(props) => props.percentage}%;
  position: absolute;
  transform: translateX(-50%);
  width: 6px;
  z-index: 2;
`;

type ResultMarkerProps = {
  alignItems: string;
};
export const ResultMarker = styled.div<ResultMarkerProps>`
  align-items: ${(props) => props.alignItems};
  background-color: ${deepTeal};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

type ResultMarkerBadgeProps = {
  color: string;
};
export const ResultMarkerBadge = styled.div<ResultMarkerBadgeProps>`
  background-color: rgba(${(props) => props.color}, 0.05);
  border-radius: ${size.xs2}px;
  border: 2px solid rgb(${(props) => props.color});
  color: ${primaryTextColor};
  font-size: ${size.md}px;
  font-weight: ${typography.weight.regular};
  line-height: 1.5;
  padding: 2px 11px;
  position: relative;
  top: -36px;
`;

export const MarkerChartBase = styled.div`
  background-color: ${coral};
  border-radius: 20px;
  height: 8px;
  overflow: visible;
  position: relative;
  width: 100%;
`;

type RangeProps = {
  color: string;
  start: number;
  width: number;
};
export const Range = styled.div<RangeProps>`
  background-color: rgb(${(props) => props.color});
  bottom: 0;
  left: ${(props) => props.start}%;
  position: absolute;
  top: 0;
  width: ${(props) => props.width}%;

  &.left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &.right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const RangeLabel = styled.div`
  color: ${markerChartBoundaryBadge};
  font-family: ${typography.type.dmSans};
  font-family: ${typography.weight.light};
  font-size: ${size.md}px;
  left: 50%;
  line-height: 1.5;
  position: absolute;
  text-align: center;
  top: 30px;
  transform: translateX(-50%);

  &.left {
    left: 0;
    transform: translateX(0);
  }

  &.right {
    left: unset;
    right: 0;
    transform: translateX(0);
  }

  ${mediaQueries.forTabletVerticalDown} {
    display: none;
  }
`;

type BoundaryProps = {
  percentage: number;
};
export const Boundary = styled.div<BoundaryProps>`
  border: 1px dashed ${markerChartBoundary};
  bottom: 0;
  height: 33px;
  left: ${(props) => props.percentage}%;
  position: absolute;
  top: 0;
  transform: translateX(-1px);
  z-index: 1;

  ${mediaQueries.forDesktopUp} {
    height: 70px;
  }
`;

export const BoundaryBadge = styled.div`
  color: ${markerChartBoundaryBadge};
  font-size: 16px;
  font-weight: 500;
  left: -20px;
  line-height: 1.5;
  opacity: 0.9;
  padding: 2px 11px;
  position: absolute;
  top: 33px;

  ${mediaQueries.forDesktopUp} {
    top: 70px;
  }
`;

export const MarkerChartCard = styled.div`
  display: flex;
  flex-direction: column;
`;
