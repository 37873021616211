/* stylelint-disable no-descending-specificity */
import { Button, mediaQueries, typography } from '@everlywell/leaves';
import chevronIcon from 'components/SubHeader/SubHeaderButton/images/chevron.svg';
import styled from 'styled-components';

type ChevronProps = {
  isActive?: boolean;
  hideChevronOnMobile?: boolean;
};

const PdfButton = styled(Button)`
  text-decoration: none !important;
  height: max-content;
  margin-bottom: 10px;

  &:hover,
  &:focus {
    span {
      background-color: white;
    }
  }
`;

const PopoverWrap = styled.div`
  position: relative;
  display: inherit;
  justify-content: center;
  z-index: 9999;
`;

const Content = styled.div`
  a {
    color: rgb(0, 98, 152);
    font-weight: 500;
  }

  a,
  p {
    display: block;
    padding: 16px 29px;
    text-align: left;
    font-family: ${typography.type.dmSans};

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }

  p {
    color: rgb(72, 72, 72);
    font-weight: 300;

    a {
      padding: 0;
    }
  }
`;

const PdfButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;

  p {
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    color: rgb(72, 72, 72);
    font-weight: 300;

    a {
      color: rgb(0, 98, 152);
      font-weight: 500;
    }
  }
`;

const Chevron = styled.span<ChevronProps>`
  /* prettier-ignore */
  mask: url("${chevronIcon}");
  background-color: #1e824c;
  width: 11px;
  height: 11px;
  margin: 0 5px 0 10px;
  display: ${(props) => (props.hideChevronOnMobile ? 'none' : 'inline-block')};
  transform: ${(props) => (props.isActive ? 'rotate(180deg)' : '')};

  ${mediaQueries.forTabletVerticalUp} {
    display: inline-block;
  }
`;

export { PdfButton, PdfButtonWrapper, PopoverWrap, Content, Chevron };
