import { getHealthProfileProgress } from 'common/apis/healthProfileApi';
import { FactCollectionItem } from 'common/utils/contentfulGraphQlQueries';
import { logError } from 'common/utils/helpers';
import { User } from 'common/utils/types';
import { shuffle } from 'lodash';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import ErrorCard from '../../../../components/ErrorCard';
import { GreyCardHeading } from '../Cards/BaseCard.styles';
import Facts from '../Cards/FactCard';
import HealthProfileCard from '../Cards/HealthProfileCard';
import HealthProfileCardSkeleton from '../Cards/HealthProfileCard/HealthProfileCard.skeleton';
import usePersonalizedFacts from './hooks/usePersonalizedFacts';

export type HealthProfileSectionProps = {
  user: User;
};

function HealthProfileSection({ user }: HealthProfileSectionProps) {
  const {
    data: healthProfileProgressResponse,
    isLoading: isLoadingHealthProfile,
    isError: isErrorHealthProfile,
    refetch: healthProfileProgressRefetch,
  } = useQuery('healthProfileProgressStatus', getHealthProfileProgress, {
    onError(error) {
      logError((error as Error).message, {
        errorInfo: 'Error Health Profile Progress',
        component: 'healthProfileSection',
        method: 'getHealthProfileProgress',
      });
    },
  });

  const { facts, isLoading: isLoadingPersonalizations } =
    usePersonalizedFacts();

  const healthProfileData = healthProfileProgressResponse?.data?.questionnaire;
  const progress = healthProfileData?.progress || 0;
  const last_interacted_at = healthProfileData?.last_interacted_at;
  const unanswered_questions = healthProfileData?.unanswered_questions || 0;

  const isLoading = isLoadingHealthProfile || isLoadingPersonalizations;
  const isHealthProfileComplete = !isLoading && progress >= 100;
  const hasPersonalizedFacts = !isLoading && facts.length > 0;
  const shouldRenderFactsCard = isHealthProfileComplete && hasPersonalizedFacts;

  const selectedFact: FactCollectionItem | null = useMemo(
    () => (hasPersonalizedFacts ? shuffle(facts)[0] : null),
    [hasPersonalizedFacts, facts],
  );

  return (
    <section>
      <GreyCardHeading>Health Profile</GreyCardHeading>

      {isLoading ? (
        <HealthProfileCardSkeleton />
      ) : isErrorHealthProfile ? (
        <ErrorCard onRetry={healthProfileProgressRefetch} />
      ) : shouldRenderFactsCard && selectedFact ? (
        <Facts fact={selectedFact} />
      ) : (
        <HealthProfileCard
          progress={progress}
          unansweredQuestions={unanswered_questions}
          user={user}
          lastInteraction={last_interacted_at}
        />
      )}
    </section>
  );
}

export default HealthProfileSection;
