import { Box, TextProps } from '@everlywell/ui-kit';
import { sanitize } from 'common/utils/domHelpers';
import parse from 'html-react-parser';
import React from 'react';

export type CarePlanInfoCardTextProps = {
  content: string;
  color?: TextProps['color'];
  simplified?: boolean;
};

const CarePlanInfoCardText = ({
  content,
  color = 'tints.uiGrey',
  simplified = false,
}: CarePlanInfoCardTextProps) => {
  const props = !simplified
    ? {
        size: 'lg',
        color,
      }
    : {
        fontSize: 'md',
        color,
      };

  return (
    <Box
      {...props}
      sx={{
        ul: {
          listStyleType: 'disc',
          paddingLeft: '4',
        },
        li: {
          marginBottom: '1',
        },
        '*': {
          color: 'tints.darkGrey',
          marginBottom: '1',
        },
      }}
    >
      {parse(sanitize(content))}
    </Box>
  );
};

export default CarePlanInfoCardText;
