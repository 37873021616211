import { Col } from '@everlywell/leaves';
import { EVERLYWELL_SAMPLE_COLLECTION } from 'common/utils/constants/urls';
import Steps from 'components/Steps';
import { StepsItemsPerRow } from 'components/Steps/model';
import React from 'react';

import img3 from './assets/instructions-test-taking-tips-hard-surface.svg';
import img2 from './assets/instructions-test-taking-tips-side-puncture.svg';
import img1 from './assets/instructions-test-taking-tips-warm-hands.svg';
import * as S from './styles';

export const bloodCollectionTips = [
  {
    id: '1',
    image: img1,
    alt: 'Warm your hands with hot water for at least 1 minute. Then, shake them at your sides for 15-20 seconds.',
    description:
      'Warm your hands with hot water for at least 1 minute. Then, shake them at your sides for 15-20 seconds.',
  },
  {
    id: '2',
    image: img2,
    alt: 'The best site for a finger puncture is just off the center of the finger pad. The tip of the finger should be avoided.',
    description:
      'The best site for a finger puncture is just off the center of the finger pad. The tip of the finger should be avoided.',
  },
  {
    id: '3',
    image: img3,
    alt: 'Place your finger on a firm surface, like a countertop, to ensure that you get the proper depth when activating the safety lancet.',
    description:
      'Place your finger on a firm surface, like a countertop, to ensure that you get the proper depth when activating the safety lancet.',
  },
];

const BloodCollection = () => (
  <S.Wrapper center="xs" middle="xs">
    <Col xs={10} md lg={9}>
      <S.Title>Tips before you collect</S.Title>

      <Steps list={bloodCollectionTips} itemsPerRow={StepsItemsPerRow.auto} />

      <S.Btn appearance="secondary" href={EVERLYWELL_SAMPLE_COLLECTION}>
        More sample collection tips
      </S.Btn>
    </Col>
  </S.Wrapper>
);

export default BloodCollection;
