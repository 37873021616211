import { EW360_SLUG } from './constants';
import { MembershipProgram, ProgramType, User, VCV_PROGRAMS } from './types';

export const isUserInWMLite = (user: User): boolean =>
  user.enrolled_programs?.some(
    (program) => program.slug === 'weight-management',
  ) || false;

export const isUserWeightCarePlusActiveMember = (user: User): boolean => {
  const enabledUserPlans = user?.user_plans?.enabled ?? [];

  const isWCPlusActiveMember = enabledUserPlans.some(
    (user_plan) => user_plan.program_plan_type === 'weight_management',
  );

  return isWCPlusActiveMember;
};

export const iseUserQuestWcMember = (user: User): boolean => {
  const subscriptions = user?.subscriptions ?? [];

  return subscriptions.some(
    (subscription) =>
      subscription.frequency === 'Quest WC+ 1 month subscription' &&
      subscription.state === 'active',
  );
};

export const isUserMemberOfProgram = (
  user: User,
  planTypes: MembershipProgram[],
): boolean => {
  const enabledUserPlans = user?.user_plans?.enabled ?? [];

  return enabledUserPlans.some((user_plan) =>
    planTypes.includes(user_plan.program_plan_type as MembershipProgram),
  );
};

export const is360Results = (sku: string) =>
  sku === EW360_SLUG.FEMALE_360 || sku === EW360_SLUG.MALE_360;

export const isVCVProgram = (program: ProgramType): boolean =>
  VCV_PROGRAMS.includes(program);

export const isUserInEwPlusSigningUpForVCV = (
  user: User,
  program: ProgramType,
): boolean => {
  const isInEverlywellPlus = isUserMemberOfProgram(user, ['control']);
  const isSigningUpForVCV = isVCVProgram(program);

  return isInEverlywellPlus && isSigningUpForVCV;
};
