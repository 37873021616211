import { colors, mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const CardContent = styled.div`
  padding: ${size.md}px;

  /* This color is used in some places but not coming from leaves, it needs a variable */
  background-color: #f8f9f9;
  margin-top: ${size.lg}px;
  display: flex;
  flex-direction: column;

  /* Same rule as the Medication List */
  ${mediaQueries.forTabletVerticalUp} {
    width: 90%;
    margin-left: 55px;
  }
`;

export const ContentHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  gap: ${size.xl1}px;
  justify-content: space-between;
  margin-bottom: ${({ isOpen }) => (isOpen ? `${size.md}px` : '')};
`;

export const LabName = styled.p<{ isOpen: boolean }>`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
  white-space: ${({ isOpen }) => (isOpen ? '' : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ToggleButton = styled.button`
  align-self: flex-start;
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: ${typography.type.dmSans};
  font-weight: ${typography.weight.xbold};
  font-size: 14px;
  letter-spacing: 0.23px;
  color: ${colors.green4};
  cursor: pointer;
  margin-left: auto;
  white-space: nowrap;
`;

export const LabDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size.md}px;
`;

export const Disclaimer = styled.p`
  ${typography.bodyTextSmall};
`;

export const LabCodeLabel = styled.span`
  ${typography.bodyTextSmall};
`;

export const LabCodeLink = styled.a`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.green4};
  text-decoration: none;
  cursor: pointer;
`;
