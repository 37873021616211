/* eslint-disable @typescript-eslint/no-var-requires */
import {
  typography,
  colors,
  size,
  mediaQueries,
  H3,
  Col,
} from '@everlywell/leaves';
import { animated } from 'react-spring';
import styled from 'styled-components';

type TabProps = {
  active: boolean;
};

const DesktopTableCol = styled(Col)`
  overflow-x: auto;
`;

const HealthProfileHeading = styled.div`
  ${typography.bodyTextSmall}
  color: ${colors.teal4};
  text-transform: uppercase;
`;

const MobilePillContext = styled(animated.table)`
  ${typography.bodyTextSmall};
  text-align: left;
  margin: 0;
  th[scope='row'] {
    font-weight: ${typography.weight.regular};
    padding: ${size.sm}px ${size.md}px ${size.sm}px 0;
    vertical-align: top;
  }
  tr:nth-child(2) {
    border-top: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.gray2};
  }
  td {
    font-weight: ${typography.weight.book};
    padding: ${size.sm}px 0;
  }
  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

const Header = styled(H3)`
  font-weight: normal;
  text-align: center;
  margin-bottom: 38px;
  white-space: nowrap;
  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: ${size.xl1}px;
    text-align: left;
  }
`;

const Table = styled.table`
  max-width: 930px;
  min-height: 356px;
  margin: ${size.xl1}px auto 56px;
  text-align: left;
  font-style: normal;

  col:nth-child(even) {
    background-color: ${colors.green1};
  }

  th[scope='col'] {
    min-width: 206px;
    font-size: 18px;
    color: ${colors.green5};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px ${size.sm}px ${size.sm}px;
  }

  th[scope='row'] {
    ${typography.subtitle}
    vertical-align: top;
    padding-top: ${size.sm}px;
    font-weight: ${typography.weight.book};
  }

  td {
    ${typography.subtitle}
    font-weight: ${typography.weight.book};
    padding: ${size.sm}px;
    min-width: 120px;
    min-height: 170px;
    vertical-align: top;
  }

  tr:nth-child(3) {
    border-top: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.gray2};
  }

  tr:last-child td:nth-child(even) {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  ${mediaQueries.forPhoneOnly} {
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  width: calc(100% + 24px);
  white-space: nowrap;
  overflow-x: auto;
  position: relative;
  /* stylelint-disable */
  scrollbar-width: none; /* Firefox */
  /* stylelint-enable */
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

const Tab = styled.button<TabProps>`
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${size.md}px;
  font-weight: ${typography.weight.bold};
  letter-spacing: 0.5px;
  line-height: ${size.lg}px;
  margin: 0;
  padding: 6px ${size.sm}px 6px ${size.sm}px;
  z-index: 10;
  transition: color 0.25s;
  color: ${(props) => (props.active ? 'white' : colors.green5)};
`;

const BubbleTab = styled(animated.div)`
  background-color: ${colors.green5};
  border-radius: ${size.md}px;
  position: absolute;
  height: 36px;
`;

export {
  DesktopTableCol,
  HealthProfileHeading,
  Header,
  Table,
  MobilePillContext,
  Tabs,
  Tab,
  BubbleTab,
};
