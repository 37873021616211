import { size, colors, mediaQueries, typography, H1 } from '@everlywell/leaves';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${colors.teal5};
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: ${size.md}px ${size.md}px ${size.xl1}px;
  width: 100%;
  margin-bottom: ${size.xl1}px;

  ${mediaQueries.forTabletVerticalUp} {
    background-color: transparent;
    border-radius: 0;
    padding: ${size.xs1}px 0 ${size.xl2}px;
    margin-bottom: 0;
  }
`;

export const HeaderLink = styled(Link)`
  color: ${colors.white};
  display: inline-flex;
  text-decoration: none;
  width: 50px;

  ${mediaQueries.forTabletVerticalUp} {
    color: ${colors.green4};
    width: auto;
  }
`;

export const Header = styled(H1)`
  margin: 0;
  color: ${colors.white};
  font-weight: ${typography.weight.book};

  ${mediaQueries.forTabletVerticalUp} {
    color: ${colors.teal4};
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
`;

export const Image = styled.img`
  margin-left: ${size.xs1}px;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

export const PageHeaderText = styled.div`
  ${typography.buttonTextSmall};
  ${mediaQueries.forPhoneOnly} {
    display: none;
  }
  ${mediaQueries.forTabletVerticalUp} {
    padding-left: ${size.xs1}px;
  }
`;

export const VisuallyHidden = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
