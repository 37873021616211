import {
  Button,
  colors,
  H3,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Header = styled(H3)`
  font-weight: ${typography.weight.book};
  font-size: 23px;
  margin-bottom: ${size.xs1}px;
  text-align: center;
`;
const Subtitle = styled.p`
  ${typography.bodyText}
  font-size: 18px;
  text-align: center;
  margin-bottom: 56px;
  ${mediaQueries.forPhoneOnly} {
    margin-bottom: 307px;
    font-size: ${size.md}px;
  }
`;

const LabSubtitle = styled.p`
  ${typography.bodyText}
  text-align: center;
  margin-bottom: ${size.xl1}px;
  font-size: ${size.md}px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${mediaQueries.forPhoneOnly} {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  height: 100%;
  ${mediaQueries.forPhoneOnly} {
    width: 100%;
    margin-bottom: ${size.lg}px;
  }
`;

const DashboardButton = styled(Button)`
  width: 100%;
`;

const ProfileStep = styled.div`
  display: flex;
  margin-bottom: ${size.xl1}px;

  img {
    width: 64px;
    height: 64px;
    margin-right: ${size.md}px;
  }

  h3 {
    font-size: ${size.lg}px;
    font-weight: ${typography.weight.book};
    margin: 0 0 ${size.xs1}px 0;
  }

  p {
    font-size: ${size.md}px;
    font-weight: ${typography.weight.light};
  }
`;

const SubText = styled.p`
  ${typography.bodyTextSmall}
  text-align: center;
`;

const RedirectText = styled.p`
  ${typography.bodyTextSmall}
  margin-top: 184px;
  text-align: center;
`;

const LinkTo = styled.a`
  color: ${colors.green5};
  font-weight: ${typography.weight.bold};
  text-decoration: none;

  :hover {
    color: ${colors.green5};
  }
`;

const Image = styled.img`
  display: block;
  margin: auto;
`;

export {
  Header,
  SubText,
  Subtitle,
  ButtonsContainer,
  StyledButton,
  DashboardButton,
  LabSubtitle,
  LinkTo,
  ProfileStep,
  RedirectText,
  Image,
};
