import {
  H1,
  Tooltip,
  colors,
  mediaQueries,
  size,
  typography,
  Icons,
} from '@everlywell/leaves';
import { bodyCopy } from 'common/styles/app-styles';
import { Col_12_Span_8 } from 'common/styles/grid-styles';
import styled from 'styled-components';

const GridWrapper = styled(Col_12_Span_8)`
  background-color: ${colors.white};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    margin-top: 80px;
    margin-bottom: 12px;
  }
`;

const ResultsIntro = styled.div`
  opacity: 1;
  text-align: center;
  z-index: 1;
  transition: opacity 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) 1.1s;
`;

const Welcome = styled(H1)`
  margin: 0.67em 0;
  font-size: 2.886rem;
  line-height: 3.5rem;
  letter-spacing: -0.76px;
  font-weight: ${typography.weight.book};

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 4.11rem;
    line-height: 4.5rem;
    letter-spacing: -0.89px;
  }
`;

const IntroHeader = styled.div`
  font-weight: ${typography.weight.book};
  ${bodyCopy};
  color: ${colors.gray4};
  margin: 10px 0;

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 23px;
    line-height: 31px;
    letter-spacing: 0.4px;
  }
`;

const Intro = styled.div`
  ${bodyCopy};
  font-weight: ${typography.weight.book};
  color: ${colors.gray4};
  margin: ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 23px;
    line-height: 32px;
    margin: ${size.xl1}px 0;
    letter-spacing: 0.5px;
  }

  a {
    font-weight: ${typography.weight.bold};
    color: ${colors.green4};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${colors.green3};
    }
  }
`;

const IntroBold = styled.span`
  font-weight: ${typography.weight.book};
`;

const AbnormalCount = styled.button`
  color: ${colors.green4};
  cursor: pointer;
  font-family: ${typography.type.dmSans};
  font-size: inherit;
  font-weight: ${typography.weight.bold};
  line-height: inherit;
  padding: 0;

  &:hover,
  &:focus {
    color: ${colors.green3};
  }
`;

const ConditionsHistory = styled.button`
  font-weight: ${typography.weight.bold};
  display: contents;
`;

const ScrollToContent = styled.div`
  line-height: 1.75;
  display: inline;

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 23px;
    line-height: 32px;
  }
  a {
    font-weight: ${typography.weight.bold};
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
  }
`;

const LabInfo = styled.div`
  ${bodyCopy};
  color: ${colors.gray4};
  font-weight: ${typography.weight.book};
  padding: 24px 0;
  font-size: 14px;
  margin: ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 23px;
    line-height: 32px;
    margin: ${size.xs2}px 0;
  }
`;

const ScienceLink = styled.a`
  color: ${colors.green4};
  font-size: inherit;
  font-weight: ${typography.weight.bold};
  line-height: inherit;
  text-decoration: none;

  &:hover {
    color: ${colors.green3};
  }

  ${mediaQueries.forTabletVerticalUp} {
    font-size: 23px;
    letter-spacing: 0.5px;
    line-height: 32px;
    margin: ${size.xs2}px 0;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  white-space: break-spaces;
`;

const CliaTooltip = styled(Tooltip)`
  color: ${colors.green4};
  font-weight: ${typography.weight.book};

  .tooltip-box {
    padding: 10px;
    width: max-content;
    max-width: 40vw; /* NOTE: this is a workaround to avoid tooltip's content to crop when the content is long and viewport is small. */
  }
`;

const InfoWrapper = styled.span`
  align-items: center;
  display: flex;
  height: 100%;
  width: auto;
  font-weight: ${typography.weight.bold};

  &:hover,
  &:focus {
    color: ${colors.green3};
  }

  ${mediaQueries.forPhoneOnly} {
    svg {
      height: 15px;
      width: 15px;
    }
  }
`;

const LabIcon = styled(Icons.InformationV2)`
  fill: none;

  > :first-child {
    stroke: ${colors.green4};
  }

  > :nth-child(2) {
    fill: ${colors.green4};
  }
`;

export {
  AbnormalCount,
  CliaTooltip,
  ConditionsHistory,
  GridWrapper,
  InfoWrapper,
  Intro,
  IntroBold,
  IntroHeader,
  LabInfo,
  LabIcon,
  ResultsIntro,
  ScienceLink,
  ScrollToContent,
  TooltipWrapper,
  Welcome,
  Wrapper,
};
