import { colors, mediaQueries, size, typography, H5 } from '@everlywell/leaves';
import styled from 'styled-components';

interface MobileProps {
  length: number;
}

const CONTENT_BOX_HEIGHT = 271;

const ContentWrapper = styled.div<MobileProps>`
  white-space: normal;
  height: ${CONTENT_BOX_HEIGHT}px;

  ${mediaQueries.forTabletVerticalUp} {
    width: ${(props) => (props.length > 1 ? '100%' : '80%')};
  }
`;

const Copy = styled.section`
  a {
    color: ${colors.green5};
    font-weight: ${typography.weight.bold};
    text-decoration: underline;
  }

  p {
    color: ${colors.black};
    font-size: ${size.md}px;
    font-weight: ${typography.weight.book};
    line-height: 26px;
    margin-bottom: ${size.md}px;
  }

  ${mediaQueries.forPhoneOnly} {
    p {
      margin-bottom: 42px;
      margin-right: ${size.md}px;
    }
  }
`;

const minWidthContainer = 320;
const FactContainer = styled.div<MobileProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  min-width: ${minWidthContainer}px;
  padding-left: ${size.md}px;
  padding-right: ${size.md}px;
  position: relative;

  .superscript {
    ${typography.captionText}
    color: ${colors.teal4};
    font-weight: ${typography.weight.bold};
    margin: 0 1px;
    text-decoration: none;
    vertical-align: 0.3em;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    ${(props) => {
      if (props.length === 1) {
        return `
    padding-left: ${size.xl1}px;
    padding-right: ${size.xl2}px;
    `;
      } else {
        return `
    padding-left: ${size.md}px;
    padding-right: ${size.md}px;
    `;
      }
    }}
  }
`;

const Image = styled.img<MobileProps>`
  height: 40px;
  width: 40px;

  ${mediaQueries.forTabletVerticalUp} {
    height: ${(props) => (props.length > 1 ? '40px' : '70px')};
    width: ${(props) => (props.length > 1 ? '40px' : '70px')};
  }
`;

const ImageWrapper = styled.div<MobileProps>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: ${size.sm}px;
  margin-left: ${size.xl2}px;
  position: absolute;
  right: ${size.md}px;
  top: ${size.md}px;

  ${mediaQueries.forTabletVerticalUp} {
    right: ${(props) => (props.length > 1 ? '16px' : '32px')};
    top: ${(props) => (props.length > 1 ? '10px' : '32px')};
  }
`;

const Source = styled.p`
  align-items: center;
  color: ${colors.teal4};
  display: flex;
  font-size: 14px;
  height: ${size.lg}px;
  margin-right: ${size.md}px;
`;
const SourcesWrapper = styled.section<MobileProps>`
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-bottom: ${(props) => (props.length > 1 ? '16px' : '32px')};

  :last-child {
    margin-bottom: ${size.md}px;
  }
`;

const Title = styled(H5)<MobileProps>`
  color: ${colors.teal4};
  font-size: 21px;
  font-weight: ${typography.weight.book};
  line-height: 1.28;
  margin-top: ${(props) => (props.length > 1 ? '16px' : '32px')};
  margin-bottom: ${size.xs1}px;
  width: 100%;

  &:first-letter {
    text-transform: uppercase;
  }

  ${mediaQueries.forTabletVerticalUp} {
    width: 80%;
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.white};
  border-radius: ${size.xs1}px;
  box-shadow: 0 7px 30px -10px rgba(220, 228, 229, 0.5);
  display: flex;
  flex-direction: column;
  height: ${CONTENT_BOX_HEIGHT}px;
  text-decoration: none;
  width: 100%;
  padding-bottom: 0;
  :nth-child(even) {
    margin: 0 0 0 ${size.xs1}px;
    ${mediaQueries.forTabletVerticalUp} {
      margin: 0 0 0 ${size.xl1}px;
    }
  }
`;

export {
  ContentWrapper,
  Copy,
  FactContainer,
  Image,
  ImageWrapper,
  Source,
  SourcesWrapper,
  Title,
  Wrapper,
};
