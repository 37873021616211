import {
  Button as RawButton,
  Input as RawInput,
  colors,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

type SavingProps = {
  saving?: boolean;
};

const Title = styled.h2`
  font-weight: ${typography.weight.book};
  font-size: ${size.lg}px;
  line-height: 1.58;
  color: ${colors.teal4};
  margin: 0 0 24px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 16px;
  }
`;

const SubTitle = styled.h3`
  font-weight: ${typography.weight.book};
  font-size: 18px;
  line-height: 32px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.gray4};
  margin: 0 0 16px;
`;

const Box = styled.div`
  padding: 32px 16px;
  border-radius: 3px;
  box-shadow: 0 ${size.xs3}px ${size.md}px 0
    rgba(${hexToRGB(colors.black)}, 0.1);
  background-color: ${colors.white};
  margin-bottom: 53px;
  ${mediaQueries.forTabletVerticalUp} {
    padding: 32px 24px;
    margin-bottom: 35px;
  }
`;

const InnerForm = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

const Field = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 calc(50% - 12px);
  }
`;

const RadioField = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 calc(50% - 12px);
  }
`;

const Button = styled(RawButton)`
  outline: none;
  align-self: center;
  margin-top: 38px;
  font-size: 18.7px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 230px;
  height: 50px;
  border-radius: 0;
  background: ${colors.green5};
  position: relative;

  &:hover {
    background: ${colors.teal5};
  }

  &[disabled].not('.loading') {
    border-color: transparent;
    background: ${colors.gray1};
    cursor: not-allowed;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: flex-start;
    flex: 1 0 100%;
  }
`;

export const ButtonSpinner = styled.div<SavingProps>`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border-width: 4px;
  border-color: transparent;
  border-style: solid;
  border-top-color: ${colors.gray1};
  box-sizing: border-box;
  animation: opacity 0.8s linear infinite;
  visibility: ${(props) => (props.saving ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.saving ? 1 : 0)};
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  @keyframes opacity {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.4;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
`;

export const ButtonText = styled.span<SavingProps>`
  visibility: ${(props) => (props.saving ? 'hidden' : 'visible')};
  opacity: ${(props) => (props.saving ? 0 : 1)};
`;

export const Input = styled(RawInput)`
  svg {
    display: none;
  }
`;

export default {
  Title,
  SubTitle,
  Box,
  InnerForm,
  Input,
  Field,
  RadioField,
  Button,
  ButtonRow,
};
