import {
  Text,
  Icon,
  Tooltip,
  Tag,
  useDisclosure,
  Box,
} from '@everlywell/ui-kit';
import { Info } from '@phosphor-icons/react';
import React from 'react';

export interface InnerCircleProps {
  wellnessScore?: number | null;
}

const WellnessScore: React.FC<InnerCircleProps> = ({ wellnessScore }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  if (wellnessScore === null) {
    return (
      <Box
        backgroundColor="tints.white"
        borderRadius="lg"
        paddingY={2}
        paddingX={1}
        boxShadow="sm"
        textAlign="center"
        maxWidth="200px"
      >
        <Text color="tints.uiGrey" fontSize="10px" lineHeight="15px">
          Your Wellscore will be available when we have more information from
          your results.
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Tag
        textTransform="uppercase"
        color="tints.darkGrey"
        backgroundColor="tints.white"
        opacity={0.6}
        marginBottom={1}
        size="sm"
      >
        Beta
      </Tag>

      <Text
        fontSize="11xl"
        color="viridian.dark"
        lineHeight="64px"
        fontWeight="normal"
        fontFamily="heading"
      >
        {wellnessScore}
      </Text>
      <Text fontSize="lg" color="tints.darkGrey">
        WellScore
      </Text>
      <Tooltip
        backgroundColor="tints.white"
        boxShadow="md"
        color="viridian.dark"
        hasArrow
        label="WellScore is based on your biological age, your chronological age, and key health information, and it will give you a number between 0-100."
        placement="top"
        maxWidth="200px"
        isOpen={isOpen}
      >
        <Icon
          as={Info}
          fill="viridian.base"
          width="16px"
          height="16px"
          zIndex="tooltip"
          role="button"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          onClick={onToggle}
        />
      </Tooltip>
    </>
  );
};

export default WellnessScore;
