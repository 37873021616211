import {
  Row,
  Button,
  Checkbox,
  Col,
  colors,
  Tooltip,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

import { BaseDropdown, BaseInput, BaseRadioButton } from '../styles';

const HeaderColumn = styled(Col)`
  margin-bottom: ${size.xl1}px;
`;

const Header = styled.p`
  ${typography.bodyTextSmall};
  ${typography.weight.book};
`;

const DataPrivacyLink = styled.a`
  color: ${colors.green5};
  font-weight: ${typography.weight.bold};
  cursor: pointer;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Label = styled.p<ErrorProps>`
  ${typography.bodyTextSmall}
  font-weight: ${typography.weight.bold};
  padding-right: ${size.xs1}px;
  color: ${(props) => (props.error ? colors.red3 : colors.black)};
`;

const SectionHeader = styled.p`
  ${typography.h5Text};
  margin-bottom: ${size.md}px;
`;

const PersonalInformationColumn = styled(Col)`
  margin-bottom: ${size.xl1}px;
  padding-left: 0;
`;
const AddressColumn = styled(Col)`
  margin-bottom: ${size.xl1}px;
  padding-left: 0;
`;

const ContactInformation = styled.div`
  position: relative;
  padding-bottom: ${size.sm}px;
`;
const ContactInformationColumn = styled(Col)`
  padding-left: 0;
  position: relative;
`;

const ContactInformationTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 174px;
    height: ${size.xl5}px;
    padding: ${size.xs1}px ${size.md}px;
  }
`;
const PickerRow = styled(Row)`
  margin: -${size.sm}px -${size.xs1}px 0;
  ${mediaQueries.forTabletHorizontalUp} {
    margin: -${size.sm}px -${size.sm}px 0;
  }
`;
const PickerCol = styled(Col)`
  padding-bottom: ${size.lg}px;
`;

interface ErrorProps {
  error: boolean | string;
}

const ErrorText = styled.p<ErrorProps>`
  ${typography.errorText};
  visibility: ${(props) => (props.error ? 'visible' : 'hidden')};
`;
const TooltipWrapper = styled.div`
  position: absolute;
  right: 2%;
  top: 33%;

  ${mediaQueries.forTabletHorizontalDown} {
    top: 34%;
    -webkit-touch-callout: none; /* iOS Safari */
    user-select: none;
    pointer-events: none;
  }

  ${mediaQueries.forPhoneOnly} {
    top: 33%;
  }
`;
const setButtonColors = (props: any) =>
  props.checked ? colors.green1 : colors.white;

const getBorderForProps = (props: any) => {
  if (props.disabled) {
    return colors.gray1;
  }
  if (props.error) {
    return colors.red3;
  }
  return colors.green1;
};

const getBoxShadowForProps = (props: any) => {
  if (props.disabled) {
    return 'inset 0 0 5px 0 rgb(0 0 0 / 8%)';
  }
  if (props.error) {
    return `${colors.red3} 0 0 0 1px inset`;
  }
  return null;
};

const BorderRadio = styled(BaseRadioButton as any)<ErrorProps>`
  background-color: ${(props) =>
    props.disabled ? colors.gray1 : setButtonColors(props)};
  box-shadow: ${(props) => getBoxShadowForProps(props)};
  border: solid 1px ${(props) => getBorderForProps(props)};
  text-transform: capitalize;
  height: 60px;
  padding: ${size.md}px;
  margin-bottom: ${size.md}px;
`;

const ErrorDropdown = styled(BaseDropdown as any)<ErrorProps>`
  box-shadow: ${(props) => getBoxShadowForProps(props)};
  border: solid 1px ${(props) => getBorderForProps(props)};
`;

const BiologicalSexTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 270px;
  }
`;
const RaceEthnicityTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 236px;
  }
`;

const Subheader = styled.p`
  ${typography.bodyTextXSmall}
  font-weight: ${typography.weight.book};
  color: ${colors.gray4};
  margin-bottom: ${size.md}px;
`;

const CheckBox = styled(Checkbox)`
  max-width: ${size.xl3}px;
  margin-left: -${size.sm}px;
  + label {
    text-align: left;
  }
`;

const CheckBoxCol = styled(Col)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${size.sm}px;
  label {
    ${typography.bodyTextSmall};
    font-weight: ${typography.weight.book};
  }
`;

const CustomTermsContainer = styled.div`
  a {
    font-weight: ${typography.weight.bold};
    color: ${colors.green5};
    text-decoration: none;
  }
`;

const Image = styled.img`
  width: ${size.lg}px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-left: -${size.sm}px;
`;

const Anchor = styled.a`
  font-weight: ${typography.weight.bold};
  color: ${colors.green5};
  text-decoration: none;
`;

const StateCol = styled(Col)`
  margin-top: -${size.xs2}px;
`;

const StyledInput = styled(BaseInput as any)`
  z-index: 9;
  height: ${size.xl6}px;
  ${(props) =>
    props.className === 'dateOfBirth'
      ? { 'margin-bottom': `${size.lg}px` }
      : {}};
  &&& {
    /* stylelint-disable function-url-quotes */
    @-moz-document url-prefix() {
      input[type='date'] {
        padding-right: 10px;
      }
    }
  }
`;
export {
  HeaderColumn,
  Header,
  DataPrivacyLink,
  PersonalInformationColumn,
  AddressColumn,
  Label,
  SectionHeader,
  BorderRadio,
  BiologicalSexTooltip,
  LabelContainer,
  Subheader,
  CheckBox,
  CheckBoxCol,
  SubmitButton,
  ContactInformation,
  ContactInformationColumn,
  ContactInformationTooltip,
  TooltipWrapper,
  Anchor,
  Image,
  PickerRow,
  PickerCol,
  RaceEthnicityTooltip,
  StateCol,
  StyledInput,
  ErrorText,
  ErrorDropdown,
  CustomTermsContainer,
};
